import styled from 'styled-components';

import { InputContainer, inputText } from '../styles';

export const FileInputContainer = styled(InputContainer)`
    display: flex;
    align-items: center;

    label {
        z-index: 2;
        cursor: pointer;
    }
`;

export const FileInputStyled = styled.input`
    width: 100%;
    height: 100%;
    position: absolute;
    display: none;
`;

export const FileNameWrapper = styled.div`
    ${({ theme }) => `
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;

        .icon { 
            margin-left: .25rem; 
            fill: ${theme.greyA0}; 
        }
    `}
`;

export const FileNameStyled = styled.div`
    white-space: nowrap;
    overflow: hidden;
    flex: 1;
    min-width: 0;
    display: flex;
    align-items: center;

    ${inputText}
`;
