import React, { Component, PureComponent } from 'react';
import { observer } from 'mobx-react';
import { FixedSizeList } from 'react-window';

import { compare } from '../../../util/functions';

import {
    TableWrapperWrapper,
    TableContainer,
    TableWrapper,
    TableHeader,
    TableCell,
    TableRow
} from './styles';

class Table extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sortDirection: 1,
            sortFunction: null,
            tableBodyHeight: 400
        };

        this.tableBodyRef = React.createRef();
    }

    componentDidMount() {
        const { sortFunction } = this.props;

        this.setState(() => ({ sortFunction }));
        if (this.tableBodyRef && this.tableBodyRef.current) {
            const height = this.tableBodyRef.current.getBoundingClientRect().height;
            this.setState({ tableBodyHeight: height });
        }
    }

    handleScroll = e => {
        const el = e.target;
        const isBottom = el.scrollHeight - el.scrollTop === el.clientHeight;
        if (isBottom) this.handleScrollToBottom(e);
    };

    handleScrollToBottom = e => {
        const { onScrollToBottom } = this.props;
        if (onScrollToBottom) onScrollToBottom(e);
    };

    toggleSort = attr => {
        const { sortDirection } = this.state;

        this.setState(() => ({
            sortDirection: -sortDirection,
            sortFunction: (lhs, rhs) => compare(lhs, rhs, attr, sortDirection)
        }));
    };

    render() {
        const { columns, data, height } = this.props;
        const { sortFunction } = this.state;

        let sortedData = data;

        if (sortFunction) {
            sortedData = data.slice().sort(sortFunction);
        }

        return (
            <TableWrapperWrapper onScroll={this.handleScroll} height={height}>
                <TableWrapper>
                    <TableContainer>
                        <TableHeader>
                            {columns.map((col, i) => (
                                <TableCell
                                    omitOnBreakpoint={col.omitOnBreakpoint}
                                    flex={col.flex}
                                    width={col.width}
                                    key={i}
                                    onClick={col.attr ? () => this.toggleSort(col.attr) : null}
                                    className="header-cell"
                                >
                                    {col.header}
                                </TableCell>
                            ))}
                        </TableHeader>
                        <div style={{ height: '100%' }} ref={this.tableBodyRef}>
                            <FixedSizeList
                                itemData={sortedData.map(d => ({ ...d, columns }))}
                                height={this.state.tableBodyHeight}
                                itemCount={sortedData.length}
                                itemSize={50}
                                asd="asd"
                            >
                                {WindowTableRow}
                            </FixedSizeList>
                        </div>

                        {/*sortedData.map((row, i) => (
                            <TableRow key={i}>
                                {columns.map((cell, j) => (
                                    <TableCell
                                        omitOnBreakpoint={cell.omitOnBreakpoint}
                                        flex={cell.flex}
                                        width={cell.width}
                                        bold={cell.bold}
                                        key={j}
                                    >
                                        {cell.value(row, i) || '-'}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))*/}
                    </TableContainer>
                </TableWrapper>
            </TableWrapperWrapper>
        );
    }
}

class WindowTableRow extends PureComponent {
    render() {
        const { index, style, data, asd } = this.props;

        const row = data[index];

        return (
            <TableRow style={style} key={index} className={index % 2 ? 'row-even' : 'row-odd'}>
                {row.columns.map((cell, j) => (
                    <TableCell
                        omitOnBreakpoint={cell.omitOnBreakpoint}
                        flex={cell.flex}
                        width={cell.width}
                        bold={cell.bold}
                        key={j}
                    >
                        {cell.value(row, index) || '-'}
                    </TableCell>
                ))}
            </TableRow>
        );
    }
}

export default observer(Table);
