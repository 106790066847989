import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { WarehouseItemFormContainer } from './styles';

import Input from '../styled/Input';
import InputGroup from '../styled/InputGroup';
import AddButton from '../styled/AddButton';

import PartnerForm from '../PartnerForm';
import UnitForm from '../UnitForm';
import WarehouseForm from '../WarehouseForm';

class WarehouseItemForm extends Component {
    render() {
        const { id, store, form } = this.props;

        const AddUnitButton = (
            <AddButton
                tooltip="Lisa uus ühik"
                type="button"
                value="+"
                size="2rem"
                onClick={() => store.ui.showModal(<UnitForm />)}
            />
        );

        const AddPartnerButton = (
            <AddButton
                tooltip="Lisa uus partner"
                type="button"
                value="+"
                size="2rem"
                onClick={() => store.ui.showModal(<PartnerForm />)}
            />
        );

        const AddWarehouseButton = (
            <AddButton
                tooltip="Lisa uus ladu"
                type="button"
                value="+"
                size="2rem"
                onClick={() => store.ui.showModal(<WarehouseForm />)}
            />
        );

        const vendors = store.partners.partners.filter(
            partner => partner.partnerType.code === 'VENDOR'
        );

        return (
            <WarehouseItemFormContainer id={id} onSubmit={form.submit}>
                <Input
                    required
                    label="Kauba kood"
                    name="code"
                    onChange={form.handleChange}
                    value={form.values.code}
                    errors={form.errors.code}
                />
                <Input
                    required
                    label="Kauba nimetus"
                    name="name"
                    onChange={form.handleChange}
                    value={form.values.name}
                    errors={form.errors.name}
                />
                <InputGroup actionComponent={AddPartnerButton}>
                    <Input
                        initiallyEmpty
                        type="select"
                        label="Tarnija"
                        name="partner"
                        parseValue={value => ({ label: value.name, value })}
                        value={form.values.partner}
                        onChange={form.handleChange}
                        options={vendors}
                        errors={form.errors.partner}
                    />
                </InputGroup>
                <InputGroup actionComponent={AddWarehouseButton}>
                    <Input
                        type="select"
                        label="Ladu"
                        name="warehouse"
                        isSearchable={false}
                        required
                        parseValue={value => ({ label: value.name, value })}
                        value={form.values.warehouse}
                        onChange={form.handleChange}
                        options={store.warehouse.warehouses}
                        errors={form.errors.warehouse}
                    />
                </InputGroup>
                <Input
                    label="Ostuhind"
                    type="number"
                    step="0.01"
                    name="purchasePrice"
                    onChange={form.handleChange}
                    value={form.values.purchasePrice}
                    errors={form.errors.purchasePrice}
                />
                <Input
                    label="Müügihind"
                    type="number"
                    step="0.01"
                    name="retailPrice"
                    onChange={form.handleChange}
                    value={form.values.retailPrice}
                    errors={form.errors.retailPrice}
                />
                <InputGroup flex={[2, 1]} actionComponent={AddUnitButton}>
                    <Input
                        required
                        label="Kogus"
                        type="number"
                        name="quantity"
                        onChange={form.handleChange}
                        value={form.values.quantity}
                        errors={form.errors.quantity}
                    />
                    <Input
                        required
                        label="Ühik"
                        parseValue={value => ({ label: value.abbr, value })}
                        isSearchable={false}
                        type="select"
                        name="unit"
                        value={form.values.unit}
                        onChange={form.handleChange}
                        options={store.common.units}
                        errors={form.errors.unit}
                    />
                </InputGroup>
                <Input
                    label="Märkused"
                    type="textarea"
                    name="description"
                    onChange={form.handleChange}
                    value={form.values.description}
                    errors={form.errors.description}
                />
            </WarehouseItemFormContainer>
        );
    }
}

export default inject('store')(observer(WarehouseItemForm));
