import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { parseCurrency } from '../../../util/functions';

import {
    ItemListContainer,
    ItemListWrapper,
    SumContainer,
    ItemListContainerWrapper
} from './styles';
import { FormHeader } from '../styles';

import InvoiceItem from '../../InvoiceItem';

class PurchaseItemList extends Component {
    render() {
        const { store } = this.props;

        const { newPurchaseForm } = store.purchases;

        const sum = `${parseCurrency(
            newPurchaseForm.values.items.reduce((total, item) => {
                return total + item.quantity * item.purchasePrice;
            }, 0)
        )}€`;

        return (
            <ItemListWrapper>
                <FormHeader>Kaubad</FormHeader>
                <ItemListContainerWrapper>
                    <ItemListContainer>
                        {newPurchaseForm.values.items.map((item, i) => (
                            <InvoiceItem
                                onDelete={() => store.purchases.removeItemFromInvoice(i)}
                                item={{ ...item, price: item.purchasePrice }}
                                editable
                                key={i}
                            />
                        ))}
                    </ItemListContainer>
                </ItemListContainerWrapper>
                <SumContainer>
                    Kokku:
                    <div className="sum">{sum}</div>
                </SumContainer>
            </ItemListWrapper>
        );
    }
}

export default inject('store')(observer(PurchaseItemList));
