import { observable, decorate, action, flow } from 'mobx';

import forms from '../util/forms';
import Store from './lib/Store';
import Form from './lib/Form';

class IncomeStore extends Store {
    incomes = [];

    fetchAllIncomes = flow(function*() {
        try {
            const incomes = yield this.api.incomes.getAll();

            if (incomes.request) this.incomes = incomes.data;
        } catch (err) {
            console.error(err);
        }
    });

    deleteIncome = flow(function*(incomeId) {
        try {
            yield this.api.incomes.delete(incomeId);

            const income = this.incomes.find(i => i.id === incomeId);

            const sale = this.stores.sales.sales.find(s => s.id === income.sale.id);

            if (sale) {
                sale.unpaidSum = sale.sum;
                sale.paidSum = 0;
                sale.isPaid = false;
            }

            const index = this.incomes.findIndex(i => i.id === incomeId);
            if (index != null) this.incomes.splice(index, 1);

            return true;
        } catch (err) {
            console.error(err);
            throw err;
        }
    });

    editIncome = flow(function*(editedIncome) {
        try {
            yield this.api.incomes.edit(editedIncome);

            const incomeIndex = this.incomes.findIndex(income => income.id === editedIncome.id);

            if (incomeIndex != null) {
                this.incomes[incomeIndex] = {
                    ...this.incomes[incomeIndex],
                    ...editedIncome
                };
            }

            this.stores.ui.closeModal();
        } catch (err) {
            console.error(err);
        }
    });

    editIncomeForm = new Form({
        fields: forms.incomes.edit,
        onSubmit: this.editIncome
    });
}

export default decorate(IncomeStore, {
    incomes: observable,
    fetchAllIncomes: action.bound,
    editIncome: action.bound,
    deleteIncome: action.bound
});
