import styled from 'styled-components';

import Input from '../styled/Input';

export const DateInputStyled = styled(Input).attrs({
    type: 'date'
})`
    ${({ theme }) => `
        width: 6.75rem;
        margin-top: 0;

        .date-input__input-container {
            border: none;
            width: auto;
            margin-top: 0;
        }

        .DayPickerInput input {
            font-weight: 400;
            color: ${theme.greyA0};
        }

        .input-label {
            display: none;
        }

        .input-error {
            display: none;
        }
    `}
`;

export const PeriodSelection = styled.div`
    ${({ theme }) => `
        display: flex;
        align-items: center;

        .period-label {
            margin-right: .5rem;
            color: ${theme.greyA0};
        }
    `}
`;

export const PeriodInputContainer = styled.div`
    ${({ theme }) => `
        color: ${theme.greyA0};
    `}

    display: flex;

    .period-hyphen {
        padding: 0.25rem 0.75rem 0 0.75rem;
    }
`;

export const PeriodTypeContainer = styled.div`
    ${({ theme }) => `
        margin-left: auto;
        color: ${theme.greyA0};
    `}
`;

export const PeriodType = styled.span`
    ${({ isActive, theme }) => `
        height: 2rem;
        cursor: pointer;

        :not(:last-child) {
            margin-right: 1rem;
        }

        ${isActive && `border-bottom: 2px solid ${theme.color1};`}
    `}
`;

export const ChartContainer = styled.div`
    flex-basis: 50%;
`;

export const ChartsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex: 1;
`;

export const OverallStatsContainer = styled.div`
    ${({ theme }) => `
        display: flex;
        margin: 0.5rem 0 1rem 0;
        border: 1px solid ${theme.greyF0};
        border-width: 1px 0 1px 0;
        padding: 1rem 0;

        .overall-stat {
            flex: 1;
            padding-left: 1rem;

            :not(:last-child) {
                border-right: 1px solid ${theme.greyF0};
            }
        }

        .overall-stat__title {
            color: ${theme.greyA0};
            text-transform: uppercase;
        }

        .overall-stat__figure {
            font-weight: 600;
            color: ${theme.color1};
            font-size: 2rem;
        }
    `}
`;
