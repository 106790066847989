import styled from 'styled-components';

export const ItemListWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

export const ItemListContainerWrapper = styled.div`
    ${({ theme }) => `
        flex: 1;
        min-height: 0;
        overflow: auto;
        border: 1px dashed ${theme.greyF0};
    `}
`;

export const ItemListContainer = styled.div`
    flex: 1;
`;

export const ItemItemContainer = styled.div`
    ${({ theme }) => `
        padding: 0.25rem;
        display: flex;
        align-items: center;

        :nth-child(odd) {
            background: ${theme.greyF5}; 
        }

        :not(:last-child) {
            border-bottom: 1px dashed ${theme.greyF0};
        }

        .del-btn {
            margin-left: .5rem;
            font-size: .85rem;
            font-weight: 600;
            color: ${theme.grey77};
            cursor: pointer;
            background: none;
            border: none;
        }

        .header {
            .name {
                font-weight: 600;
                color: ${theme.grey33};
            }

            .code, .quantity, .purchase-price {
                font-size: .85rem;
                color: ${theme.grey77};
                font-weight: 600;
            }

            .quantity:before { content: ' - '; }

            .purchase-price:before { content: ' x '; }
        }

        .overall-price {
            color: ${theme.color1};
            font-weight: 600;
            margin-left: auto;
        }
    `}
`;

export const SumContainer = styled.div`
    ${({ theme }) => `
        border: dashed ${theme.greyF0};
        border-width: 0 1px 1px 1px;
        padding: .75rem;
        font-weight: 600;
        display: flex;
        justify-content: flex-end;

        .sum {
            margin-left: .25rem;
            color: ${theme.color1};
        }
    `}
`;
