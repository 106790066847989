import React, { Component } from 'react';

import { ButtonContainer, ButtonText, LoaderContainer, LoaderCircle } from './styles';

class Button extends Component {
    render() {
        const { value, isLoading, ...restProps } = this.props;

        return (
            <ButtonContainer {...restProps}>
                <ButtonText isLoading={isLoading}>{value}</ButtonText>
                {isLoading && (
                    <LoaderContainer height="100%" width="100%">
                        <LoaderCircle r="10" cx="50%" cy="50%" />
                    </LoaderContainer>
                )}
            </ButtonContainer>
        );
    }
}

export default Button;
