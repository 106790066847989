import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { InputGroupContainer, InputWrapper, ActionComponentWrapper } from './styles';

class InputGroup extends Component {
    render() {
        const { children, flex, gap, actionComponent } = this.props;

        return (
            <InputGroupContainer>
                {React.Children.toArray(children).map((c, i) => (
                    <InputWrapper gap={gap || '.5rem'} flex={flex ? flex[i] : 1} key={i}>
                        {c}
                    </InputWrapper>
                ))}
                {actionComponent && (
                    <ActionComponentWrapper>{actionComponent}</ActionComponentWrapper>
                )}
            </InputGroupContainer>
        );
    }
}

export default observer(InputGroup);
