import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { parseCurrency, parseDate } from '../../util/functions';

import { ModalHeader, ModalBody, ModalFooter } from '../styled/Modal/styles';
import { PurchaseOverviewContainer, DeleteButton, ItemsContainer, SumContainer } from './styles';

import InvoiceItem from '../InvoiceItem';

import Input from '../styled/Input';
import InputGroup from '../styled/InputGroup';

class PurchaseOverview extends Component {
    componentDidMount() {
        const { store, purchase } = this.props;
        store.purchases.fetchItems(purchase.id);
    }

    render() {
        const { purchase, store } = this.props;

        return (
            <PurchaseOverviewContainer>
                <ModalHeader>Arve {purchase.invoiceNr}</ModalHeader>
                <ModalBody>
                    <Input type="static" value={purchase.partner.name} label="Tarnija" />
                    <InputGroup>
                        <Input type="static" value={purchase.invoiceNr} label="Arve nr" />
                        <Input
                            type="static"
                            value={purchase.invoiceFile && `${purchase.invoiceNr}.pdf`}
                            onClick={
                                purchase.invoiceFile &&
                                (() =>
                                    store.purchases.downloadPurchase(purchase.id, {
                                        preview: true
                                    }))
                            }
                            label="Arve fail (PDF)"
                        />
                    </InputGroup>
                    <InputGroup>
                        <Input
                            type="static"
                            value={parseDate(purchase.purchaseDate)}
                            label="Ostukuupäev"
                        />
                        <Input
                            type="static"
                            value={parseDate(purchase.dueDate)}
                            label="Maksetähtaeg"
                        />
                    </InputGroup>
                    <Input type="static" value={purchase.description} label="Märkused" />
                    <div>
                        <ItemsContainer>
                            {purchase.items &&
                                purchase.items.map((item, i) => (
                                    <InvoiceItem
                                        key={i}
                                        item={{ ...item, price: item.purchasePrice }}
                                    />
                                ))}
                        </ItemsContainer>
                        <SumContainer>
                            Kokku: <div className="sum">{parseCurrency(purchase.sum)}€</div>
                        </SumContainer>
                    </div>
                </ModalBody>
                <ModalFooter borderless>
                    <DeleteButton value="Kustuta arve" />
                </ModalFooter>
            </PurchaseOverviewContainer>
        );
    }
}

export default inject('store')(observer(PurchaseOverview));
