import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { parseDate } from '../../util/functions';

import { ModalHeader, ModalBody, ModalFooter } from '../styled/Modal/styles';
import { ReceiptFormModalContainer, ReceiptFormContainer } from './styles';

import Button from '../styled/Button';
import Input from '../styled/Input';
import { ErrorContainer } from '../styled/Error';

class ReceiptEditFormModal extends Component {
    componentDidMount() {
        const { store, receipt } = this.props;
        const { editReceiptForm } = store.receipts;
        editReceiptForm.setValues(receipt);
    }

    componentWillUnmount() {
        const { editReceiptForm } = this.props.store.receipts;
        editReceiptForm.reset();
    }

    render() {
        const { receipt } = this.props;
        const { editReceiptForm } = this.props.store.receipts;

        return (
            <ReceiptFormModalContainer>
                <ModalHeader>Arve {receipt.sale.invoiceNr} laekumine</ModalHeader>
                <ModalBody>
                    <ReceiptFormContainer id="editReceipt" onSubmit={editReceiptForm.submit}>
                        <Input
                            type="date"
                            required
                            label="Kuupäev"
                            onChange={editReceiptForm.handleChange}
                            value={editReceiptForm.values.date}
                            errors={editReceiptForm.errors.date}
                            name="date"
                        />
                        <Input
                            type="number"
                            required
                            label="Summa"
                            onChange={editReceiptForm.handleChange}
                            value={editReceiptForm.values.sum}
                            errors={editReceiptForm.errors.sum}
                            name="sum"
                            min="0"
                            step="0.01"
                        />
                        <Input
                            type="textarea"
                            label="Märkused"
                            onChange={editReceiptForm.handleChange}
                            value={editReceiptForm.values.description}
                            errors={editReceiptForm.errors.description}
                            name="description"
                        />
                    </ReceiptFormContainer>
                </ModalBody>
                <ModalFooter borderless>
                    {editReceiptForm.generalErrorMessage && (
                        <ErrorContainer margin=".75rem 0 0 0">
                            {editReceiptForm.generalErrorMessage}
                        </ErrorContainer>
                    )}
                    <Button
                        form="editReceipt"
                        value="Muuda laekumine"
                        isLoading={editReceiptForm.isLoading}
                    />
                </ModalFooter>
            </ReceiptFormModalContainer>
        );
    }
}

export default inject('store')(observer(ReceiptEditFormModal));
