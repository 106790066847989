import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { parseCurrency, parseDate, compare } from '../../util/functions';

import {
    MainViewContainer,
    MainViewHeader,
    HeaderText,
    SearchInputContainer,
    SearchInput,
    MainViewFooter
} from '../styled/MainView';

import { IsPaidStyled } from './styles';

import Icon from '../styled/Icon';
import Table from '../styled/Table';
import Button from '../styled/Button';
import Tooltip from '../styled/Tooltip';
import Confirmation from '../styled/Confirmation';

import PurchaseOverview from '../PurchaseOverview';
import PurchaseForm from '../PurchaseForm';
import ExpenseForm from '../ExpenseForm';

class PurchaseTable extends Component {
    state = {
        searchQuery: '',
        searchResults: []
    };

    componentDidMount() {
        const { ui, purchases } = this.props.store;
        purchases.fetchAllPurchases();
        ui.setActiveView(ui.routes.purchases);
    }

    searchPurchases = async searchQuery => {
        const { app } = this.props.store;

        const purchases = await app.api.purchases.search({
            invoiceNr: searchQuery,
            partnerName: searchQuery
        });

        this.setState(() => ({ searchResults: purchases.data, searchQuery }));
    };

    render() {
        const { store } = this.props;
        const { searchQuery, searchResults } = this.state;

        const DeleteConfirmation = ({ row }) => (
            <Confirmation
                message="Kas oled kindel, et soovid ostuarve jäädavalt kustutada?"
                description="Arve ja sellega seotud kulud kustutatakse ja arves olevad kaubad võetakse laost ära."
                onConfirm={() => store.purchases.deletePurchase(row.id)}
            />
        );

        const columns = [
            {
                width: '2rem',
                value: row => (
                    <Icon
                        onClick={() => store.ui.showModal(<PurchaseOverview purchase={row} />)}
                        tooltip="Vaata arvet"
                        type="eye"
                        size="1.25rem"
                    />
                ),
                header: ''
            },
            { width: '10rem', value: row => row.invoiceNr, header: 'Arve nr', attr: 'invoiceNr' },
            {
                width: '',
                value: row => row.partner.name,
                header: 'Tarnija',
                bold: true,
                attr: 'partner.name'
            },
            {
                width: '7.5rem',
                value: row => parseDate(row.purchaseDate),
                header: 'Ostukuupäev',
                omitOnBreakpoint: true,
                attr: 'purchaseDate'
            },
            {
                width: '7.5rem',
                value: row => parseDate(row.dueDate),
                header: 'Maksetähtaeg',
                attr: 'dueDate'
            },
            { width: '6rem', value: row => parseCurrency(row.sum), header: 'Summa', attr: 'sum' },
            {
                width: '6rem',
                value: row => (
                    <IsPaidStyled
                        onClick={() =>
                            !row.isPaid && store.ui.showModal(<ExpenseForm purchase={row} />)
                        }
                        isPaid={row.isPaid}
                    >
                        <Tooltip disabled={row.isPaid} content="Maksa arve">
                            {row.isPaid ? 'Jah' : 'Ei'}
                        </Tooltip>
                    </IsPaidStyled>
                ),
                header: 'Makstud',
                attr: 'isPaid'
            },
            {
                width: '2rem',
                value: row => (
                    <Icon
                        onClick={() => store.ui.showModal(<DeleteConfirmation row={row} />)}
                        tooltip="Kustuta arve"
                        type="trashcan"
                        size="1.25rem"
                    />
                ),
                header: ''
            }
        ];

        return (
            <MainViewContainer>
                <MainViewHeader>
                    <HeaderText>Ostuarved</HeaderText>
                    <SearchInputContainer>
                        <SearchInput
                            placeholder="Otsi arvet"
                            type="text"
                            value={searchQuery}
                            onChange={query => this.searchPurchases(query)}
                        />
                        <Icon type="search" size="1.5rem" />
                    </SearchInputContainer>
                </MainViewHeader>
                <Table
                    columns={columns}
                    data={searchQuery ? searchResults : store.purchases.purchases}
                    sortFunction={(lhs, rhs) => compare(lhs, rhs, 'dueDate', -1)}
                />
                <MainViewFooter>
                    <Button
                        value="+ Uus arve"
                        onClick={() => store.ui.showModal(<PurchaseForm />)}
                    />
                </MainViewFooter>
            </MainViewContainer>
        );
    }
}

export default inject('store')(observer(PurchaseTable));
