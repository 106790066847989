export default class Store {
    stores = {};

    api;

    constructor(stores, api) {
        this.stores = stores;
        this.api = api;
    }
}
