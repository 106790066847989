import styled from 'styled-components';

export const ChartContainerStyled = styled.div`
    ${({ theme }) => `
        height: 100%;
        width: 100%;

        tspan {
            fill: ${theme.greyA0};
        }

        .recharts-legend-item-text {
            color: ${theme.greyA0};
        }

        .recharts-cartesian-axis-tick-line {
            stroke: ${theme.greyF0};
        }
    `}
`;

export const ChartTitleStyled = styled.div`
    ${({ theme }) => `
        font-size: 1.25rem;
        color: ${theme.grey33};
        font-weight: 600;
        padding: 0 0 1rem 1rem;
    `}
`;
