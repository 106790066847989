import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { withTheme } from 'styled-components';
import {
    LineChart,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    Line,
    ResponsiveContainer
} from 'recharts';

class LineChartImpl extends Component {
    render() {
        const { height, width, data, dataKey, lines, theme, withLegend } = this.props;

        return (
            <ResponsiveContainer height={height || 250} width={width || '99%'}>
                <LineChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 50 }}>
                    <XAxis minTickGap={50} dataKey={dataKey} axisLine={false} dy={10} />
                    <YAxis width={40} axisLine={false} tickLine={false} />
                    <CartesianGrid strokeDasharray="3 3" stroke={theme.greyF0} vertical={false} />
                    <Tooltip />
                    {withLegend && <Legend />}
                    {lines &&
                        lines.map((line, i) => (
                            <Line key={i} stroke="#8884d8" dot={false} {...line} />
                        ))}
                </LineChart>
            </ResponsiveContainer>
        );
    }
}

export default withTheme(observer(LineChartImpl));
