import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { parseCurrency, compare, debounce } from '../../util/functions';

import {
    MainViewContainer,
    MainViewHeader,
    HeaderText,
    SearchInputContainer,
    SearchInput,
    MainViewFooter
} from '../styled/MainView';

import { SelectStyled, ManageWarehousesButton, QuantityContainer } from './styles';

import Icon from '../styled/Icon';
import Table from '../styled/Table';
import Button from '../styled/Button';
import Confirmation from '../styled/Confirmation';

import WarehouseItemEditForm from '../WarehouseItemForm/WarehouseItemEditForm';
import WarehouseItemForm from '../WarehouseItemForm';
import WarehouseForm from '../WarehouseForm';
import ItemOverview from '../ItemOverview';

class Warehouse extends Component {
    constructor(props) {
        super(props);
        this.debouncedSearch = debounce(props.store.warehouse.api.items.search, 400);
    }

    state = {
        searchQuery: '',
        searchResults: []
    };

    componentDidMount() {
        const { ui } = this.props.store;
        ui.setActiveView(ui.routes.warehouse);
    }

    searchItems = async searchQuery => {
        const { warehouse } = this.props.store;

        this.setState(() => ({ searchQuery }));

        const searchResults = await this.debouncedSearch({
            name: searchQuery,
            code: searchQuery,
            warehouse: warehouse.activeWarehouse
        });

        this.setState(() => ({ searchResults: searchResults.data }));
    };

    render() {
        const { store } = this.props;
        const { searchResults, searchQuery } = this.state;

        const DeleteConfirmation = ({ row }) => (
            <Confirmation
                message="Kas oled kindel, et soovid kauba jäädavalt kustutada?"
                description="Saad kauba kustutada ainult siis, kui see pole ühegi arvega seotud."
                onConfirm={() => store.warehouse.deleteItem(row.id, row.warehouse.id)}
            />
        );

        const columns = [
            {
                width: '2rem',
                value: row => (
                    <Icon
                        tooltip="Vaata kaupa"
                        type="eye"
                        size="1.25rem"
                        onClick={() => store.ui.showModal(<ItemOverview item={row} />)}
                    />
                ),
                header: ''
            },
            { width: '10rem', value: row => row.code, header: 'Kood', attr: 'code' },
            { width: '', value: row => row.name, header: 'Nimetus', bold: true, attr: 'name' },
            {
                width: '6rem',
                value: row => parseCurrency(row.retailPrice),
                header: 'Müügihind',
                attr: 'retailPrice'
            },
            {
                width: '6rem',
                value: row => (
                    <QuantityContainer>
                        {row.quantity}
                        <sup>{row.unit.abbr}</sup>
                    </QuantityContainer>
                ),
                header: 'Kogus',
                attr: 'quantity'
            },
            {
                width: '2rem',
                value: row => (
                    <Icon
                        tooltip="Kustuta kaup"
                        type="trashcan"
                        size="1.25rem"
                        onClick={() => store.ui.showModal(<DeleteConfirmation row={row} />)}
                    />
                ),
                header: ''
            },
            {
                width: '2rem',
                value: row => (
                    <Icon
                        tooltip="Muuda kaupa"
                        type="edit"
                        size="1.25rem"
                        onClick={() => store.ui.showModal(<WarehouseItemEditForm item={row} />)}
                    />
                ),
                header: ''
            }
        ];

        const activeWarehouse = {
            value: store.warehouse.activeWarehouse,
            label: store.warehouse.activeWarehouse.name
        };

        const warehouseOptions = store.warehouse.warehouses.map(warehouse => ({
            value: warehouse,
            label: warehouse.name
        }));

        return (
            <MainViewContainer>
                <MainViewHeader>
                    <HeaderText>Laoseis</HeaderText>
                    <SelectStyled
                        required
                        isSearchable={false}
                        value={activeWarehouse}
                        onChange={store.warehouse.setActiveWarehouse}
                        options={warehouseOptions}
                    />
                    <SearchInputContainer>
                        <SearchInput
                            placeholder="Otsi kaupa"
                            type="text"
                            value={searchQuery}
                            onChange={query => this.searchItems(query)}
                        />
                        <Icon type="search" size="1.5rem" />
                    </SearchInputContainer>
                </MainViewHeader>
                <Table
                    columns={columns}
                    data={searchQuery ? searchResults : store.warehouse.activeWarehouseItems}
                    sortFunction={(lhs, rhs) => compare(lhs, rhs, 'code', 1)}
                />
                <MainViewFooter>
                    <ManageWarehousesButton
                        onClick={() => store.ui.showModal(<WarehouseForm />)}
                        value="Halda ladusid"
                    />
                    <Button
                        onClick={() => store.ui.showModal(<WarehouseItemForm />)}
                        value="+ Uus kaup"
                    />
                </MainViewFooter>
            </MainViewContainer>
        );
    }
}

export default inject('store')(observer(Warehouse));
