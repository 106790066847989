import { observable, action, decorate, computed, flow } from 'mobx';

import forms from '../util/forms';

import Store from './lib/Store';
import Form from './lib/Form';

class WarehouseStore extends Store {
    items = [];

    warehouses = [];

    activeWarehouse = {};

    setActiveWarehouse = warehouse => {
        this.activeWarehouse = warehouse;
        this.fetchItems(warehouse);
    };

    fetchAll = flow(function*() {
        try {
            const warehouses = yield this.api.warehouses.getAll();

            this.warehouses = warehouses.data;
            if (warehouses.data.length > 0) this.setActiveWarehouse(warehouses.data[0]);
        } catch (err) {
            console.error(err);
        }
    });

    addWarehouse = flow(function*(newWarehouse) {
        try {
            const warehouse = yield this.api.warehouses.add(newWarehouse);
            this.warehouses.push(warehouse.data);

            this.setActiveWarehouse(warehouse.data);
            this.newWarehouseForm.reset();
            this.stores.ui.closeModal();
        } catch (err) {
            this.newWarehouseForm.setGeneralErrorMessage(err.response.data.message);
        }
    });

    deleteWarehouse = flow(function*(warehouseId) {
        try {
            yield this.api.warehouses.delete(warehouseId);

            const index = this.warehouses.findIndex(wh => wh.id === warehouseId);
            if (index != null) this.warehouses.splice(index, 1);

            return true;
        } catch (err) {
            this.newWarehouseForm.setGeneralErrorMessage(err.response.data.message);
            throw err;
        }
    });

    addItem = flow(function*(newItem) {
        try {
            const item = yield this.api.items.add(newItem);

            newItem.id = item.data.id;

            this.items.push(newItem);

            this.stores.ui.closeModal();
            this.newItemForm.reset();
        } catch (err) {
            this.newItemForm.setGeneralErrorMessage(err.response.data.message);
        }
    });

    fetchItems = flow(function*(warehouse) {
        try {
            const items = yield this.api.items.getAll(warehouse.id);

            if (items.request) {
                const filteredItems = this.items.filter(
                    item =>
                        (item.warehouse && item.warehouse.id !== warehouse.id) || !item.warehouse
                );

                this.items = [...filteredItems, ...items.data];
            }
        } catch (err) {
            console.error(err);
        }
    });

    deleteItem = flow(function*(itemId, warehouseId) {
        try {
            yield this.api.items.delete(itemId, warehouseId);

            const index = this.items.findIndex(
                item => item.id === itemId && item.warehouse.id === warehouseId
            );

            if (index != null) this.items.splice(index, 1);
            return true;
        } catch (err) {
            throw err;
        }
    });

    editItem = flow(function*(editedItem) {
        try {
            const index = this.items.findIndex(i => i.id === editedItem.id);
            editedItem.oldWarehouse = this.items[index].warehouse;

            yield this.api.items.edit(editedItem);

            if (index != null) this.items[index] = editedItem;

            this.stores.ui.closeModal();
        } catch (err) {
            this.editItemForm.setGeneralErrorMessage(err.response.data.message);
        }
    });

    get activeWarehouseItems() {
        return this.items.filter(
            item => item.warehouse && item.warehouse.id === this.activeWarehouse.id
        );
    }

    newItemForm = new Form({
        fields: forms.items.new,
        onSubmit: this.addItem
    });

    editItemForm = new Form({
        fields: forms.items.edit,
        onSubmit: this.editItem
    });

    newWarehouseForm = new Form({
        fields: forms.warehouse.new,
        onSubmit: this.addWarehouse
    });
}

export default decorate(WarehouseStore, {
    items: observable,
    warehouses: observable,
    activeWarehouse: observable,
    newItemForm: observable,
    editItemForm: observable,
    newWarehouseForm: observable,
    addItem: action.bound,
    deleteItem: action.bound,
    editItem: action.bound,
    addWarehouse: action.bound,
    fetchItems: action.bound,
    deleteWarehouse: action,
    setActiveWarehouse: action.bound,
    activeWarehouseItems: computed
});
