import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { SettingsContentContainer, SettingsTitle, SettingsFormContainer } from '../styles';

import Input from '../../styled/Input';
import Button from '../../styled/Button';

class ProfileSettings extends Component {
    componentDidMount() {
        const { ui, auth, common } = this.props.store;
        common.profileForm.setValues(auth.user);
        ui.setActiveView(ui.routes.profileSettings);
    }

    render() {
        const { profileForm } = this.props.store.common;

        return (
            <SettingsContentContainer>
                <SettingsTitle>Profiil</SettingsTitle>
                <SettingsFormContainer onSubmit={profileForm.submit}>
                    <Input
                        required
                        name="name"
                        errors={profileForm.errors.name}
                        onChange={profileForm.handleChange}
                        value={profileForm.values.name}
                        label="Ettevõtte nimi"
                    />
                    <Input
                        name="registryNr"
                        onChange={profileForm.handleChange}
                        value={profileForm.values.registryNr}
                        label="Registrikood"
                    />
                    <Input
                        name="email"
                        onChange={profileForm.handleChange}
                        value={profileForm.values.email}
                        label="E-post"
                    />
                    <Input
                        name="phoneNr"
                        onChange={profileForm.handleChange}
                        value={profileForm.values.phoneNr}
                        label="Telefon"
                    />
                    <Input
                        name="country"
                        onChange={profileForm.handleChange}
                        value={profileForm.values.country}
                        label="Riik"
                    />
                    <Input
                        name="county"
                        onChange={profileForm.handleChange}
                        value={profileForm.values.county}
                        label="Maakond"
                    />
                    <Input
                        name="postalCode"
                        onChange={profileForm.handleChange}
                        value={profileForm.values.postalCode}
                        label="Postikood"
                    />
                    <Input
                        name="address"
                        onChange={profileForm.handleChange}
                        value={profileForm.values.address}
                        label="Aadress"
                    />
                    <Button value="Salvesta" />
                </SettingsFormContainer>
            </SettingsContentContainer>
        );
    }
}

export default inject('store')(observer(ProfileSettings));
