import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { ModalHeader, ModalBody, ModalFooter } from '../styled/Modal/styles';
import { ExpenseFormContainer } from './styles';

import Button from '../styled/Button';
import Input from '../styled/Input';

class ExpenseEditFormModal extends Component {
    componentDidMount() {
        const { store, expense } = this.props;
        const { editExpenseForm } = store.expenses;

        editExpenseForm.setValues(expense);
    }

    componentWillUnmount() {
        const { editExpenseForm } = this.props.store.expenses;
        editExpenseForm.reset();
    }

    render() {
        const { editExpenseForm } = this.props.store.expenses;

        return (
            <ExpenseFormContainer>
                <ModalHeader>Muuda kulu</ModalHeader>
                <ModalBody>
                    <form id="editExpense" onSubmit={editExpenseForm.submit}>
                        <Input
                            required
                            type="date"
                            name="date"
                            label="Kuupäev"
                            value={editExpenseForm.values.date}
                            errors={editExpenseForm.errors.date}
                            onChange={editExpenseForm.handleChange}
                        />
                        <Input
                            required
                            type="textarea"
                            name="description"
                            label="Märkused"
                            value={editExpenseForm.values.description}
                            errors={editExpenseForm.errors.description}
                            onChange={editExpenseForm.handleChange}
                        />
                    </form>
                </ModalBody>
                <ModalFooter borderless>
                    <Button
                        form="editExpense"
                        value="Muuda kulu"
                        isLoading={editExpenseForm.isLoading}
                    />
                </ModalFooter>
            </ExpenseFormContainer>
        );
    }
}

export default inject('store')(observer(ExpenseEditFormModal));
