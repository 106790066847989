import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { parseCurrency, parseDate } from '../../util/functions';

import { ModalHeader, ModalBody, ModalFooter } from '../styled/Modal/styles';
import { SaleOverviewContainer, DeleteButton, ItemsContainer, SumContainer } from './styles';

import InvoiceItem from '../InvoiceItem';

import Input from '../styled/Input';
import InputGroup from '../styled/InputGroup';

class SaleOverview extends Component {
    componentDidMount() {
        const { store, sale } = this.props;
        store.sales.fetchItems(sale.id);
    }

    render() {
        const { sale, store } = this.props;

        const sum = `${parseCurrency(sale.sum)}€`;

        return (
            <SaleOverviewContainer>
                <ModalHeader>Arve {sale.invoiceNr}</ModalHeader>
                <ModalBody>
                    <Input type="static" value={sale.partner.name} label="Klient" />
                    <InputGroup>
                        <Input type="static" value={sale.invoiceNr} label="Arve nr" />
                        <Input
                            type="static"
                            value={`${sale.invoiceNr}.pdf`}
                            label="Arve fail (PDF)"
                            onClick={() => store.sales.downloadSale(sale.id, { preview: true })}
                        />
                    </InputGroup>
                    <InputGroup>
                        <Input
                            type="static"
                            value={parseDate(sale.saleDate)}
                            label="Müügikuupäev"
                        />
                        <Input type="static" value={parseDate(sale.dueDate)} label="Maksetähtaeg" />
                    </InputGroup>
                    <Input type="static" value={sale.description} label="Märkused" />
                    <div>
                        <ItemsContainer>
                            {sale.items &&
                                sale.items.map((item, i) => (
                                    <InvoiceItem
                                        key={i}
                                        item={{ ...item, price: item.retailPrice }}
                                    />
                                ))}
                        </ItemsContainer>
                        <SumContainer>
                            Kokku:
                            <span className="sum">{sum}</span>
                        </SumContainer>
                    </div>
                </ModalBody>
                <ModalFooter borderless>
                    <DeleteButton value="Kustuta arve" />
                </ModalFooter>
            </SaleOverviewContainer>
        );
    }
}

export default inject('store')(observer(SaleOverview));
