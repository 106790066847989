import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { parseCurrency } from '../../util/functions';

import { ItemContainer } from './styles';

class InvoiceItem extends Component {
    render() {
        const { item, editable, onDelete } = this.props;

        const price = parseCurrency(Number(item.price));
        const overallPrice = parseCurrency(Number(item.price) * item.quantity);

        return (
            <ItemContainer>
                <div className="col-1">
                    <div className="col-1__row-1">
                        <div className="name">
                            {item.name}
                            <span className="quantity">
                                - {item.quantity}
                                {item.unit && item.unit.abbr} x {price}€
                            </span>
                        </div>
                    </div>
                    <div className="col-1__row-2">
                        <div className="code">{item.code}</div>
                    </div>
                </div>
                <div className="col-2">
                    <div className="overall-price">
                        {overallPrice}€
                        {editable && (
                            <button onClick={onDelete} type="button" className="del-btn">
                                x
                            </button>
                        )}
                    </div>
                </div>
            </ItemContainer>
        );
    }
}

export default observer(InvoiceItem);
