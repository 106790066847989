import React, { Component } from 'react';

import { FileInputContainer, FileInputStyled, FileNameStyled, FileNameWrapper } from './styles';

import Label from '../Label';
import Icon from '../../Icon';

class FileInput extends Component {
    handleChange = e => {
        const { name, onChange } = this.props;
        const file = e.target.files[0];
        if (onChange) onChange(file, name);
    };

    render() {
        const { label, required, disabled, value } = this.props;

        return (
            <FileInputContainer disabled={disabled}>
                <Label
                    value={label}
                    required={required}
                    lifted={value != null}
                    htmlFor="fileName"
                />
                <FileNameWrapper>
                    <FileNameStyled>{value && value.name}</FileNameStyled>
                    <Icon type="file" size="1.25rem" />
                </FileNameWrapper>
                <FileInputStyled onChange={this.handleChange} id="fileName" type="file" />
            </FileInputContainer>
        );
    }
}

export default FileInput;
