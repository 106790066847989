import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withTheme } from 'styled-components';

import { parseCurrency } from '../../util/functions';

import {
    MainViewContainer,
    MainViewHeader,
    HeaderText,
    MainViewFooter,
    MainViewBody
} from '../styled/MainView';

import {
    DateInputStyled,
    PeriodSelection,
    PeriodInputContainer,
    PeriodTypeContainer,
    ChartContainer,
    OverallStatsContainer,
    PeriodType,
    ChartsContainer
} from './styles';

import Chart from '../styled/Chart';

class Statistics extends Component {
    componentDidMount() {
        const { ui, statistics } = this.props.store;

        ui.setActiveView(ui.routes.statistics);
        statistics.fetchStatistics();
    }

    render() {
        const { data, options, changeOption } = this.props.store.statistics;
        const { theme } = this.props;

        return (
            <MainViewContainer>
                <MainViewHeader>
                    <HeaderText>Statistika</HeaderText>
                </MainViewHeader>
                <MainViewBody>
                    <PeriodSelection>
                        <div className="period-label">Periood:</div>
                        <PeriodInputContainer>
                            <DateInputStyled
                                name="startDate"
                                onChange={changeOption}
                                value={options.startDate}
                            />
                            <span className="period-hyphen">-</span>
                            <DateInputStyled
                                name="endDate"
                                onChange={changeOption}
                                value={options.endDate}
                            />
                        </PeriodInputContainer>
                        <PeriodTypeContainer>
                            <PeriodType
                                isActive={options.groupBy === 'day'}
                                onClick={() => changeOption('day', 'groupBy')}
                            >
                                Päevad
                            </PeriodType>
                            <PeriodType
                                isActive={options.groupBy === 'month'}
                                onClick={() => changeOption('month', 'groupBy')}
                            >
                                Kuud
                            </PeriodType>
                        </PeriodTypeContainer>
                    </PeriodSelection>
                    <OverallStatsContainer>
                        <div className="overall-stat">
                            <div className="overall-stat__title">Tulud kokku</div>
                            <div className="overall-stat__figure">
                                {parseCurrency(data.incomes)}€
                            </div>
                        </div>
                        <div className="overall-stat">
                            <div className="overall-stat__title">Kulud kokku</div>
                            <div className="overall-stat__figure">
                                {parseCurrency(data.expenses)}€
                            </div>
                        </div>
                        <div className="overall-stat">
                            <div className="overall-stat__title">Kasum kokku</div>
                            <div className="overall-stat__figure">
                                {parseCurrency(data.profit)}€
                            </div>
                        </div>
                    </OverallStatsContainer>
                    <ChartsContainer>
                        <ChartContainer>
                            <Chart
                                title="Tulud"
                                type="line"
                                dataKey="date"
                                data={data.charts.revenue}
                                lines={[
                                    {
                                        name: 'Tulud',
                                        dataKey: 'incomes',
                                        stroke: theme.color1,
                                        strokeWidth: 1.5
                                    }
                                ]}
                            />
                        </ChartContainer>
                        <ChartContainer>
                            <Chart
                                title="Kulud"
                                type="line"
                                dataKey="date"
                                data={data.charts.revenue}
                                lines={[
                                    {
                                        name: 'Kulud',
                                        dataKey: 'expenses',
                                        stroke: theme.greyA0,
                                        strokeWidth: 1.5
                                    }
                                ]}
                            />
                        </ChartContainer>
                        <ChartContainer>
                            <Chart
                                currencyValue
                                title="Müügiartiklite jaotus"
                                type="pie"
                                dataKey="sum"
                                data={data.charts.itemTypeSums}
                                labelKey="itemTypeName"
                            />
                        </ChartContainer>
                    </ChartsContainer>
                </MainViewBody>
                <MainViewFooter />
            </MainViewContainer>
        );
    }
}

export default inject('store')(withTheme(observer(Statistics)));
