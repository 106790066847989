import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Autosuggest from 'react-autosuggest';

import { AutoSuggestContainer, AutoSuggestWrapper, SuggestionItemStyled } from './styles';

import Label from '../Label';

class AutoSuggest extends Component {
    state = {
        suggestions: [],
        searchQuery: '',
        timeout: null,
        isFocused: false,
        highlightedSuggestion: null
    };

    onChange = (e, { newValue }) => {
        const { name, onChange, onlySelectable } = this.props;
        if (onlySelectable) {
            this.setState({ searchQuery: newValue });
        } else {
            onChange(newValue, name);
        }
    };

    onSuggestionsFetchRequested = async ({ value }) => {
        const { filterSuggestions, fetchSuggestions } = this.props;
        const { timeout } = this.state;

        if (timeout) {
            clearTimeout(timeout);
            this.setState(() => ({ timeout: null }));
        }

        const newTimeout = setTimeout(async () => {
            const suggestions = await fetchSuggestions(value);

            if (filterSuggestions) {
                suggestions.data = suggestions.data.filter(filterSuggestions);
            }

            this.setState(() => ({ suggestions: suggestions.data, timeout: null }));
        }, 500);

        this.setState(() => ({ timeout: newTimeout }));
    };

    onSuggestionsClearRequested = () => {
        this.setState(() => ({ suggestions: [] }));
    };

    onSuggestionHighlighted = ({ suggestion }) => {
        this.setState(() => ({ highlightedSuggestion: suggestion }));
    };

    onSuggestionSelected = (e, vals) => {
        const { onlySelectable, onSuggestionSelected } = this.props;
        if (onlySelectable) {
            this.setState({ searchQuery: '' });
        }
        onSuggestionSelected(e, vals);
    };

    preventSubmitOnEnter = e => {
        if (e.key === 'Enter' && this.state.highlightedSuggestion) {
            e.preventDefault();
        }
    };

    onBlur = () => {
        const { onlySelectable } = this.props;
        if (onlySelectable) {
            this.setState({ searchQuery: '' });
        }
        this.setState({ isFocused: false });
    };

    render() {
        const { value, required, label, getSuggestionValue, disabled } = this.props;

        const { suggestions, isFocused, searchQuery } = this.state;

        const inputProps = {
            value: searchQuery || value,
            onChange: this.onChange,
            disabled,
            onFocus: () => this.setState({ isFocused: true }),
            onBlur: this.onBlur,
            onKeyDown: this.preventSubmitOnEnter
        };

        const renderSuggestion = suggestion => (
            <SuggestionItemStyled>{getSuggestionValue(suggestion)}</SuggestionItemStyled>
        );

        return (
            <AutoSuggestContainer disabled={disabled}>
                <AutoSuggestWrapper isFocused={isFocused}>
                    <Autosuggest
                        suggestions={suggestions}
                        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                        getSuggestionValue={getSuggestionValue}
                        onSuggestionSelected={this.onSuggestionSelected}
                        onSuggestionHighlighted={this.onSuggestionHighlighted}
                        renderSuggestion={renderSuggestion}
                        inputProps={inputProps}
                    />
                </AutoSuggestWrapper>
                <Label value={label} required={required} lifted={isFocused || value.length > 0} />
            </AutoSuggestContainer>
        );
    }
}

export default observer(AutoSuggest);
