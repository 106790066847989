import axios from 'axios';
import cachios from 'cachios';
import constants from '../util/constants';

import { withAuthHeaders } from './index';

export default {
    add: async newExpense => {
        return axios.post(`${constants.API_URL}/expenses`, newExpense, withAuthHeaders());
    },
    edit: async editedExpense => {
        return axios.put(`${constants.API_URL}/expenses`, editedExpense, withAuthHeaders());
    },
    search: async opts => {
        return cachios.get(
            `${constants.API_URL}/expenses/search`,
            withAuthHeaders({
                params: opts
            })
        );
    },
    getAll: async () => {
        return cachios.get(`${constants.API_URL}/expenses`, withAuthHeaders());
    },
    delete: async expenseId => {
        return axios.delete(
            `${constants.API_URL}/expenses`,
            withAuthHeaders({
                params: {
                    expenseId
                }
            })
        );
    }
};
