import axios from 'axios';
import cachios from 'cachios';
import constants from '../util/constants';

import { withAuthHeaders } from './index';

export default {
    add: async partner => {
        return axios.post(`${constants.API_URL}/partners`, partner, withAuthHeaders());
    },
    edit: async editedPartner => {
        return axios.put(`${constants.API_URL}/partners`, editedPartner, withAuthHeaders());
    },
    search: async opts => {
        return cachios.get(
            `${constants.API_URL}/partners/search`,
            withAuthHeaders({
                params: opts
            })
        );
    },
    getAll: async ({ offset } = {}) => {
        return axios.get(`${constants.API_URL}/partners`, withAuthHeaders());
    },
    delete: async partnerId => {
        return axios.delete(
            `${constants.API_URL}/partners`,
            withAuthHeaders({
                params: {
                    partnerId
                }
            })
        );
    }
};
