import styled from 'styled-components';

import Button from '../Button';

export const AddButtonStyled = styled(Button)`
    ${({ theme, padding, margin }) => `
        background: transparent;
        color: ${theme.color1};
        border: 1px dashed ${theme.color1};
        box-shadow: none;
        padding: ${padding || 0};
        margin: ${margin || '.25rem 0 0 0'};

        :hover, :focus {
            background: ${theme.greyF5};
        }
    `}
`;
