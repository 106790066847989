import React, { Component } from 'react';

import { LoadingContainer, LoaderCircle } from './styles';

class LoadingScreen extends Component {
    render() {
        return (
            <LoadingContainer>
                <svg>
                    <LoaderCircle r="40" cy="50%" cx="50%" />
                </svg>
            </LoadingContainer>
        );
    }
}

export default LoadingScreen;
