import * as yup from 'yup';

const newReceipt = {
    sale: {
        value: null,
        schema: yup
            .object()
            .typeError('Palun vali müügiarve.')
            .shape({ id: yup.number() })
            .required('Palun vali müügiarve.')
    },
    date: {
        value: new Date(),
        schema: yup.date().required('Palun sisesta laekumise kuupäev.')
    },
    sum: {
        value: '',
        schema: yup
            .number()
            .typeError('Palun sisesta laekumise summa.')
            .min(0, 'Summa peab olema 0 või suurem')
            .required('Palun sisesta laekumise summa.')
    },
    description: {
        value: '',
        schema: yup.string()
    }
};

const editReceipt = {
    ...newReceipt,
    id: {
        value: null,
        schema: yup.number().required()
    }
};

export default {
    new: newReceipt,
    edit: editReceipt
};
