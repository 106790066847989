import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { ModalHeader, ModalBody, ModalFooter } from '../styled/Modal/styles';
import { ItemOverviewContainer } from './styles';

import Button from '../styled/Button';
import Input from '../styled/Input';
import InputGroup from '../styled/InputGroup';
import { parseCurrency } from '../../util/functions';

class ItemOverview extends Component {
    render() {
        const { item } = this.props;

        const purchasePrice = parseCurrency(item.purchasePrice);
        const retailPrice = parseCurrency(item.retailPrice);

        return (
            <ItemOverviewContainer>
                <ModalHeader>Kaup {item.code}</ModalHeader>
                <ModalBody>
                    <Input type="static" label="Kauba kood" value={item.code} />
                    <Input type="static" label="Kauba nimetus" value={item.name} />
                    <Input
                        type="static"
                        label="Tarnija"
                        parseValue={value => ({ label: value.name, value })}
                        value={item.partner}
                    />
                    <Input
                        type="static"
                        label="Ladu"
                        parseValue={value => ({ label: value.name, value })}
                        value={item.warehouse}
                    />
                    <Input type="static" label="Ostuhind" value={purchasePrice} />
                    <Input type="static" label="Müügihind" value={retailPrice} />
                    <InputGroup flex={[1, 1]}>
                        <Input type="static" label="Kogus" value={item.quantity} />
                        <Input
                            type="static"
                            label="Ühik"
                            parseValue={value => ({ label: value.abbr, value })}
                            value={item.unit}
                        />
                    </InputGroup>
                    <Input type="static" label="Märkused" value={item.description} />
                </ModalBody>
                <ModalFooter borderless />
            </ItemOverviewContainer>
        );
    }
}

export default inject('store')(observer(ItemOverview));
