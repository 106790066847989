import { decorate, observable, action, flow } from 'mobx';
import * as yup from 'yup';

class Form {
    values = {};

    fields = {};

    defaultValues = {};

    errors = {};

    generalErrorMessage;

    isLoading = false;

    schema;

    onSubmit;

    constructor(opts) {
        this.fields = opts.fields;
        this.onSubmit = opts.onSubmit;

        this._initialize();
    }

    handleChange = (value, name) => {
        this.values[name] = value;
        this.errors[name] = [];
        this.generalErrorMessage = null;
    };

    reset = () => {
        this.values = this.defaultValues;
    };

    setValues = values => {
        Object.keys(values).forEach(key => {
            if (key in this.values) {
                this.errors[key] = [];
                this.values[key] = values[key];
            }
        });
    };

    setGeneralErrorMessage = message => {
        Object.keys(this.fields).forEach(field => {
            this.errors[field] = [];
        });
        this.generalErrorMessage = message;
    };

    submit = flow(function*(e) {
        e.preventDefault();
        if (!this.isLoading) {
            this.isLoading = true;
            if (yield this._validateValues()) yield this.onSubmit(this.values);
            this.isLoading = false;
        }
    });

    _validateValues = flow(function*() {
        try {
            yield this.schema.validate(this.values, { abortEarly: false });
            return true;
        } catch (err) {
            if (err.inner) {
                err.inner.forEach((error, i) => {
                    console.log(error);
                    this.errors[error.path].push(err.errors[i]);
                });
            } else {
                console.error(err);
            }
            return false;
        }
    });

    _initialize() {
        if (this.fields) {
            Object.keys(this.fields).forEach(field => {
                this.errors[field] = [];
            });

            Object.keys(this.fields).forEach(field => {
                const { value } = this.fields[field];
                if (Array.isArray(value)) {
                    this.values[field] = value.slice();
                    this.defaultValues[field] = value.slice();
                } else if (value instanceof Date) {
                    this.values[field] = value;
                    this.defaultValues[field] = value;
                } else if (value instanceof Object) {
                    this.values[field] = Object.assign({}, value);
                    this.defaultValues[field] = Object.assign({}, value);
                } else {
                    this.values[field] = value;
                    this.defaultValues[field] = value;
                }
            });

            const schema = {};

            Object.keys(this.fields).forEach(field => {
                schema[field] = this.fields[field].schema;
            });

            this.schema = yup.object().shape(schema);
        }
    }

    _resetErrors() {
        Object.keys(this.errors).forEach(key => {
            this.errors[key] = [];
        });
    }
}

export default decorate(Form, {
    values: observable,
    onSubmit: observable,
    errors: observable,
    fields: observable,
    schema: observable,
    isLoading: observable,
    setGeneralErrorMessage: action,
    generalErrorMessage: observable,
    customHandlers: observable,
    handleChange: action,
    setValues: action,
    _validateValues: action,
    _initialize: action,
    _resetErrors: action,
    submit: action.bound,
    reset: action
});
