import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import Button from '../Button';
import {
    ConfirmationHeader,
    DescriptionMessage,
    ConfirmationButtons,
    ConfirmationContainer,
    ConfirmationFooter
} from './styles';

import { ErrorContainer } from '../Error';

class Confirmation extends Component {
    state = {
        isLoadingConfirm: false,
        isLoadingCancel: false,
        errorMsg: null
    };

    onCancel = async () => {
        const { store, onCancel } = this.props;
        this.setState(() => ({ isLoadingCancel: true }));
        try {
            if (onCancel) await onCancel();
            store.ui.closeModal();
        } catch (err) {
            this.setState(() => ({ errorMsg: err.response.data.message }));
        }
        this.setState(() => ({ isLoadingCancel: false }));
    };

    onConfirm = async () => {
        const { store, onConfirm } = this.props;
        this.setState(() => ({ isLoadingConfirm: true }));
        try {
            if (onConfirm) await onConfirm();
            store.ui.closeModal();
        } catch (err) {
            this.setState(() => ({ errorMsg: err.response ? err.response.data.message : null }));
        }
        this.setState(() => ({ isLoadingConfirm: false }));
    };

    render() {
        const { message, description } = this.props;
        const { isLoadingConfirm, isLoadingCancel, errorMsg } = this.state;

        return (
            <ConfirmationContainer>
                <ConfirmationHeader>{message}</ConfirmationHeader>
                <DescriptionMessage>{description}</DescriptionMessage>
                <ConfirmationFooter borderless>
                    {errorMsg && <ErrorContainer>{errorMsg}</ErrorContainer>}
                    <ConfirmationButtons>
                        <Button
                            className="no-btn"
                            value="Ei"
                            isLoading={isLoadingCancel}
                            onClick={this.onCancel}
                        />
                        <Button
                            className="yes-btn"
                            value="Jah"
                            isLoading={isLoadingConfirm}
                            onClick={this.onConfirm}
                        />
                    </ConfirmationButtons>
                </ConfirmationFooter>
            </ConfirmationContainer>
        );
    }
}

export default inject('store')(observer(Confirmation));
