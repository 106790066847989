import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { ModalHeader, ModalBody, ModalFooter } from '../styled/Modal/styles';
import { ExpenseOverviewContainer } from './styles';

import Input from '../styled/Input';
import { parseCurrency, parseDate } from '../../util/functions';

class ExpenseOverview extends Component {
    render() {
        const { expense } = this.props;

        const sum = parseCurrency(expense.sum);
        const date = parseDate(expense.date);

        return (
            <ExpenseOverviewContainer>
                <ModalHeader>Kulu ülevaade</ModalHeader>
                <ModalBody>
                    <Input type="static" label="Ostuarve nr" value={expense.purchase.invoiceNr} />
                    <Input type="static" label="Tarnija" value={expense.purchase.partner.name} />
                    <Input type="static" label="Kuupäev" value={date} />
                    <Input type="static" label="Summa" value={sum} />
                    <Input type="static" label="Märkused" value={expense.description} />
                </ModalBody>
                <ModalFooter borderless />
            </ExpenseOverviewContainer>
        );
    }
}

export default inject('store')(observer(ExpenseOverview));
