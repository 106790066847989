import axios from 'axios';
import cachios from 'cachios';
import constants from '../util/constants';

import { withAuthHeaders } from './index';

export default {
    add: async purchase => {
        return axios.post(
            `${constants.API_URL}/purchases`,
            purchase,
            withAuthHeaders({
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
        );
    },
    delete: async purchaseId => {
        return axios.delete(
            `${constants.API_URL}/purchases`,
            withAuthHeaders({
                params: {
                    purchaseId
                }
            })
        );
    },
    getAll: async (opts = {}) => {
        const httpClient = opts.cache === false ? axios : cachios;

        return httpClient.get(`${constants.API_URL}/purchases`, withAuthHeaders());
    },
    search: async (opts = {}) => {
        return cachios.get(
            `${constants.API_URL}/purchases/search`,
            withAuthHeaders({
                params: opts
            })
        );
    },
    getItems: async purchaseId => {
        return cachios.get(
            `${constants.API_URL}/purchases/items`,
            withAuthHeaders({
                params: {
                    purchaseId
                }
            })
        );
    },
    downloadPurchase: async purchaseId => {
        return cachios.get(
            `${constants.API_URL}/purchases/file`,
            withAuthHeaders({
                params: {
                    purchaseId
                },
                responseType: 'arraybuffer'
            })
        );
    }
};
