import * as yup from 'yup';

yup.addMethod(yup.object, 'notEmpty', function(errorMessage) {
    return this.test('notEmpty', errorMessage, value => Object.keys(value).length > 0);
});

yup.addMethod(yup.mixed, 'fileSize', function(fileSize, errorMessage) {
    return this.test('fileSize', errorMessage, file => (file ? file.size <= fileSize : true));
});

yup.addMethod(yup.mixed, 'fileType', function(fileType, errorMessage) {
    return this.test('fileSize', errorMessage, file => (file ? file.type === fileType : true));
});

yup.addMethod(yup.number, 'allow', function(allowValue) {
    return this.test('allow', null, value => value === allowValue);
});

yup.addMethod(yup.number, 'minEqual', function(minValue, errorMessage) {
    return this.test('minEqual', errorMessage, value => value >= minValue);
});

// get nested object property by string separated with '.': object['level1.level2']
Object.byString = function(o, s) {
    if (o instanceof Object && typeof s === 'string') {
        s = s.replace(/\[(\w+)\]/g, '.$1');
        s = s.replace(/^\./, '');
        const a = s.split('.');
        for (let i = 0, n = a.length; i < n; ++i) {
            const k = a[i];
            if (k in o) {
                o = o[k];
            } else {
                return null;
            }
        }
        return o;
    }
    return null;
};
