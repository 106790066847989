import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { ModalHeader, ModalBody, ModalFooter } from '../styled/Modal/styles';
import { ErrorContainer } from '../styled/Error';
import { PurchaseFormModalContainer, ItemsContainer } from './styles';

import Button from '../styled/Button';
import PurchaseForm from './PurchaseForm';
import PurchaseItemForm from './PurchaseItemForm';
import PurchaseItemList from './PurchaseItemList';

class PurchaseFormModal extends Component {
    render() {
        const { store } = this.props;

        const { generalErrorMessage } = store.purchases.newPurchaseForm;

        return (
            <PurchaseFormModalContainer>
                <ModalHeader>Lisa uus ostuarve</ModalHeader>
                <ModalBody>
                    <PurchaseForm id="newPurchase" />
                    <ItemsContainer>
                        <PurchaseItemForm />
                        <PurchaseItemList />
                    </ItemsContainer>
                </ModalBody>
                <ModalFooter borderless>
                    {generalErrorMessage && (
                        <ErrorContainer margin=".75rem 0 0 0">{generalErrorMessage}</ErrorContainer>
                    )}
                    <Button
                        form="newPurchase"
                        value="Lisa arve"
                        isLoading={store.purchases.newPurchaseForm.isLoading}
                    />
                </ModalFooter>
            </PurchaseFormModalContainer>
        );
    }
}

export default inject('store')(observer(PurchaseFormModal));
