import { observable, action, decorate, computed, flow } from 'mobx';
import { createTransformer } from 'mobx-utils';

import Store from './lib/Store';
import Form from './lib/Form';

import forms from '../util/forms';

class PartnerStore extends Store {
    partners = [];

    addPartner = flow(function*(newPartner) {
        try {
            const partner = yield this.api.partners.add(newPartner);

            newPartner.id = partner.data.id;

            this.partners.push(newPartner);
            this.stores.ui.closeModal();
            this.newPartnerForm.reset();
        } catch (err) {
            console.error(err);
        }
    });

    fetchPartners = flow(function*({ offset } = {}) {
        try {
            const partners = yield this.api.partners.getAll({
                offset: offset || this.partners.length
            });

            this.partners = this.partners.concat(partners.data);
            console.log(this.partners);
        } catch (err) {
            console.error(err);
        }
    });

    fetchAllPartners = flow(function*() {
        try {
            const partners = yield this.api.partners.getAll({
                offset: this.partners.length
            });

            this.partners = Object.assign([], partners.data);
        } catch (err) {
            console.error(err);
        }
    });

    deletePartner = flow(function*(partnerId) {
        try {
            yield this.api.partners.delete(partnerId);

            const index = this.partners.findIndex(partner => partner.id === partnerId);
            if (index != null) this.partners.splice(index, 1);

            return true;
        } catch (err) {
            throw err;
        }
    });

    editPartner = flow(function*(editedPartner) {
        try {
            yield this.api.partners.edit(editedPartner);

            const index = this.partners.findIndex(partner => editedPartner.id === partner.id);
            if (index != null) this.partners[index] = editedPartner;

            this.stores.ui.closeModal();
        } catch (err) {
            console.error(err);
        }
    });

    get getPartnerById() {
        return createTransformer(id => this.partners.find(p => p.id === id));
    }

    newPartnerForm = new Form({
        fields: forms.partners.new,
        onSubmit: this.addPartner
    });

    editPartnerForm = new Form({
        fields: forms.partners.edit,
        onSubmit: this.editPartner
    });
}

export default decorate(PartnerStore, {
    partners: observable,
    newPartnerForm: observable,
    editPartnerForm: observable,
    addPartner: action.bound,
    editPartner: action.bound,
    deletePartner: action.bound,
    fetchAllPartners: action.bound,
    getPartnerById: computed
});
