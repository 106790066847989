import * as yup from 'yup';

export default {
    new: {
        partner: {
            value: null,
            schema: yup
                .object()
                .typeError('Palun vali tehingupartner.')
                .shape({ id: yup.number() })
                .notEmpty('Palun vali tehingupartner.')
                .required('Palun vali tehingupartner.')
        },
        invoiceNr: {
            value: '',
            schema: yup
                .string()
                .trim()
                .required('Palun sisesta arve number.')
        },
        invoiceFile: {
            value: null,
            schema: yup
                .mixed()
                .fileSize(500000, 'Fail peab olema väiksem kui 500kb.')
                .fileType('application/pdf', 'Fail peab olema PDF formaadis.')
                .nullable()
        },
        dueDate: {
            value: new Date(),
            schema: yup.date().required('Palun sisesta maksetähtaeg.')
        },
        purchaseDate: {
            value: new Date(),
            schema: yup.date().required('Palun sisesta ostukuupäev.')
        },
        items: {
            value: [],
            schema: yup.array().required()
        },
        description: {
            value: '',
            schema: yup.string()
        }
    },
    goods: {
        id: {
            value: null,
            schema: yup.number().nullable()
        },
        itemType: {
            value: {
                id: 1,
                code: 'INVENTORY'
            }
        },
        name: {
            value: '',
            schema: yup
                .string()
                .trim()
                .required('Palun sisesta kauba nimetus.')
        },
        code: {
            value: '',
            schema: yup
                .string()
                .trim()
                .required('Palun sisesta kauba kood.')
        },
        quantity: {
            value: '',
            schema: yup
                .number()
                .typeError('Palun sisesta kauba kogus.')
                .min(0, 'Kogus peab olema 0 või suurem.')
                .required()
        },
        unit: {
            value: null,
            schema: yup
                .object()
                .typeError('Palun sisesta kauba ühik.')
                .shape({ id: yup.number() })
                .notEmpty('Palun sisesta kauba ühik.')
                .required('Palun sisesta kauba ühik.')
        },
        warehouse: {
            value: null,
            schema: yup
                .object()
                .typeError('Palun vali kauba ladu.')
                .shape({ id: yup.number() })
                .notEmpty('Palun vali kauba ladu.')
                .required('Palun vali kauba ladu.')
        },
        purchasePrice: {
            value: '',
            schema: yup
                .number()
                .typeError('Palun sisesta kauba ostuhind.')
                .min(0, 'Hind peab olema 0 või suurem.')
                .required()
        },
        retailPrice: {
            value: '',
            schema: yup
                .number()
                .typeError('Müügihind peab olema number.')
                .min(0, 'Hind peab olema 0 või suurem.')
                .nullable()
                .transform((cv, ov) => {
                    return ov === '' ? null : cv;
                })
        }
    },
    service: {
        id: {
            value: null,
            schema: yup.number().nullable()
        },
        itemType: {
            value: {
                id: 2,
                code: 'SERVICE'
            }
        },
        name: {
            value: '',
            schema: yup
                .string()
                .trim()
                .required('Palun sisesta teenuse nimetus.')
        },
        quantity: {
            value: '',
            schema: yup
                .number()
                .typeError('Palun sisesta teenuse kogus.')
                .min(0, 'Kogus peab olema 0 või suurem.')
                .required()
        },
        unit: {
            value: null,
            schema: yup
                .object()
                .shape({ id: yup.number() })
                .nullable()
        },
        purchasePrice: {
            value: '',
            schema: yup
                .number()
                .typeError('Palun sisesta teenuse ostuhind.')
                .min(0, 'Hind peab olema 0 või suurem.')
                .required()
        }
    },
    expense: {
        id: {
            value: null,
            schema: yup.number().nullable()
        },
        itemType: {
            value: {
                id: 3,
                code: 'EXPENSE'
            }
        },
        name: {
            value: '',
            schema: yup
                .string()
                .trim()
                .required('Palun sisesta teenuse nimetus.')
        },
        quantity: {
            value: '',
            schema: yup
                .number()
                .typeError('Palun sisesta teenuse kogus.')
                .min(0, 'Kogus peab olema 0 või suurem.')
                .required()
        },
        unit: {
            value: null,
            schema: yup
                .object()
                .shape({ id: yup.number() })
                .nullable()
        },
        purchasePrice: {
            value: '',
            schema: yup
                .number()
                .typeError('Palun sisesta teenuse ostuhind.')
                .min(0, 'Hind peab olema 0 või suurem.')
                .required()
        }
    }
};
