import axios from 'axios';
import cachios from 'cachios';
import constants from '../util/constants';

import { withAuthHeaders } from './index';

export default {
    add: async warehouse => {
        return axios.post(`${constants.API_URL}/warehouse`, warehouse, withAuthHeaders());
    },
    getAll: async () => {
        return cachios.get(`${constants.API_URL}/warehouse`, withAuthHeaders());
    },
    edit: async newValues => {
        return axios.put(`${constants.API_URL}/warehouse`, newValues, withAuthHeaders());
    },
    delete: async warehouseId => {
        return axios.delete(
            `${constants.API_URL}/warehouse`,
            withAuthHeaders({
                params: {
                    warehouseId
                }
            })
        );
    }
};
