import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { SettingsMenuContainer, MenuItem } from './styles';

class SettingsMenu extends Component {
    render() {
        const { ui, auth } = this.props.store;

        return (
            <SettingsMenuContainer>
                <MenuItem
                    to={ui.routes.generalSettings}
                    isActive={ui.isActiveView(ui.routes.generalSettings)}
                >
                    Üldine
                </MenuItem>
                <MenuItem
                    to={ui.routes.profileSettings}
                    isActive={ui.isActiveView(ui.routes.profileSettings)}
                >
                    Profiil
                </MenuItem>
                <MenuItem
                    to={ui.routes.bankSettings}
                    isActive={ui.isActiveView(ui.routes.bankSettings)}
                >
                    Pangakontod
                </MenuItem>
                <MenuItem to={ui.routes.login} onClick={auth.logout}>
                    Logi välja
                </MenuItem>
            </SettingsMenuContainer>
        );
    }
}

export default inject('store')(observer(SettingsMenu));
