import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { ItemFormContainer } from './styles';

import InputGroup from '../../styled/InputGroup';
import Input from '../../styled/Input';
import Button from '../../styled/Button';
import AddButton from '../../styled/AddButton';

import UnitForm from '../../UnitForm';

class ServiceForm extends Component {
    handleSuggestionSelect = (e, { suggestion }) => {
        const { purchases } = this.props.store;
        purchases.serviceForm.setValues(suggestion);
    };

    render() {
        const { store } = this.props;

        const { serviceForm } = store.purchases;

        const unitOptions = Object.assign([], store.common.units);

        const AddUnitButton = (
            <AddButton
                tooltip="Lisa uus ühik"
                type="button"
                value="+"
                size="2rem"
                onClick={() => store.ui.showModal(<UnitForm />)}
            />
        );

        return (
            <ItemFormContainer onSubmit={serviceForm.submit}>
                <Input
                    required
                    type="autosuggest"
                    getSuggestionValue={item => item.name}
                    fetchSuggestions={value =>
                        store.app.api.items.search({
                            name: value,
                            itemType: { id: 2 }
                        })
                    }
                    onSuggestionSelected={this.handleSuggestionSelect}
                    value={serviceForm.values.name}
                    errors={serviceForm.errors.name}
                    name="name"
                    onChange={serviceForm.handleChange}
                    label="Nimetus"
                />
                <InputGroup flex={[2, 1]} actionComponent={AddUnitButton}>
                    <Input
                        required
                        value={serviceForm.values.quantity}
                        errors={serviceForm.errors.quantity}
                        type="number"
                        name="quantity"
                        onChange={serviceForm.handleChange}
                        label="Kogus"
                    />
                    <Input
                        options={unitOptions}
                        value={serviceForm.values.unit}
                        parseValue={value => ({ label: value.abbr, value })}
                        errors={serviceForm.errors.unit}
                        type="select"
                        name="unit"
                        onChange={serviceForm.handleChange}
                        label="Ühik"
                    />
                </InputGroup>
                <Input
                    required
                    value={serviceForm.values.purchasePrice}
                    errors={serviceForm.errors.purchasePrice}
                    type="number"
                    step="0.01"
                    name="purchasePrice"
                    onChange={serviceForm.handleChange}
                    label="Ostuhind"
                />
                <Button value="Lisa teenus" />
            </ItemFormContainer>
        );
    }
}

export default inject('store')(observer(ServiceForm));
