import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const SettingsMenuContainer = styled.div`
    ${({ theme }) => `
        flex: 1;
        border: 1px dashed ${theme.greyF0};
        display: flex;
        flex-direction: column;
    `}
`;

export const MenuItem = styled(({ isActive, ...rest }) => <Link {...rest} />)`
    ${({ theme, isActive }) => `
        color: ${theme.grey33};
        padding: 0.75rem 1rem;
        box-sizing: border-box;

        ${
            isActive
                ? `
            background: ${theme.color2};
            color: ${theme.white(1)};
        `
                : `
            :hover {
                background: ${theme.greyF5};
            }
        `
        } 
    `}
`;
