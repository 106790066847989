import axios from 'axios';
import constants from '../util/constants';

import { withAuthHeaders } from './index';

export default {
    loginWithFacebook: async accessToken => {
        return axios.get(`${constants.API_URL}/auth/facebook`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        });
    },
    loginWithGoogle: async accessToken => {
        return axios.get(`${constants.API_URL}/auth/google`, {
            params: {
                access_token: accessToken
            }
        });
    },
    verifyToken: async () => {
        return axios.get(`${constants.API_URL}/auth/verify`, withAuthHeaders());
    }
};
