import React, { Component } from 'react';
import { observer } from 'mobx-react';

import LineChart from './LineChart';
import PieChart from './PieChart';

import { ChartContainerStyled, ChartTitleStyled } from './styles';

class Chart extends Component {
    render() {
        const { type, title, ...restProps } = this.props;

        const charts = {
            line: <LineChart {...restProps} />,
            pie: <PieChart {...restProps} />
        };

        return (
            <ChartContainerStyled>
                <ChartTitleStyled>{title}</ChartTitleStyled>
                {charts[type]}
            </ChartContainerStyled>
        );
    }
}

export default observer(Chart);
