import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { ModalHeader, ModalBody, ModalFooter } from '../styled/Modal/styles';
import { UnitFormModalContainer } from './styles';

import Button from '../styled/Button';
import UnitForm from './UnitForm';

class UnitFormModal extends Component {
    render() {
        const { store } = this.props;

        return (
            <UnitFormModalContainer>
                <ModalHeader>Lisa uus ühik</ModalHeader>
                <ModalBody>
                    <UnitForm id="newUnit" />
                </ModalBody>
                <ModalFooter borderless>
                    <Button
                        form="newUnit"
                        value="Lisa ühik"
                        isLoading={store.common.newUnitForm.isLoading}
                    />
                </ModalFooter>
            </UnitFormModalContainer>
        );
    }
}

export default inject('store')(observer(UnitFormModal));
