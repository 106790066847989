import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { TooltipStyled } from './styles';

class Tooltip extends Component {
    render() {
        const { children, disabled, ...restProps } = this.props;

        return !disabled ? (
            <TooltipStyled arrow={false} {...restProps}>
                {children}
            </TooltipStyled>
        ) : (
            children
        );
    }
}

export default observer(Tooltip);
