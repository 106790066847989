import AuthStore from './AuthStore';
import UIStore from './UIStore';
import AppStore from './AppStore';
import WarehouseStore from './WarehouseStore';
import ReceiptStore from './ReceiptStore';
import PurchaseStore from './PurchaseStore';
import PartnerStore from './PartnerStore';
import SalesStore from './SalesStore';
import CommonStore from './CommonStore';
import ExpenseStore from './ExpenseStore';
import IncomeStore from './IncomeStore';
import StatisticsStore from './StatisticsStore';

import api from '../api';

const stores = {};

Object.assign(stores, {
    auth: new AuthStore(stores, api),
    ui: new UIStore(stores, api),
    app: new AppStore(stores, api),
    warehouse: new WarehouseStore(stores, api),
    receipts: new ReceiptStore(stores, api),
    purchases: new PurchaseStore(stores, api),
    partners: new PartnerStore(stores, api),
    sales: new SalesStore(stores, api),
    common: new CommonStore(stores, api),
    expenses: new ExpenseStore(stores, api),
    incomes: new IncomeStore(stores, api),
    statistics: new StatisticsStore(stores, api)
});

export default stores;
