import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { PurchaseItemFormContainer, ItemFormWrapper, ItemFormWrapperWrapper } from './styles';
import { FormHeader } from '../styles';

import Input from '../../styled/Input';

import GoodsForm from './GoodsForm';
import ServiceForm from './ServiceForm';

class PurchaseItemForm extends Component {
    state = {
        itemType: {}
    };

    handleTypeChange = itemType => {
        this.setState({ itemType });
    };

    render() {
        const { store } = this.props;

        const { itemType } = this.state;

        return (
            <PurchaseItemFormContainer>
                <FormHeader>Lisa kaup</FormHeader>
                <ItemFormWrapperWrapper>
                    <ItemFormWrapper>
                        <Input
                            required
                            onChange={this.handleTypeChange}
                            name="itemType"
                            value={itemType}
                            parseValue={value => ({ label: value.name, value })}
                            options={store.common.itemTypes}
                            type="select"
                            label="Kauba tüüp"
                        />
                        {itemType.code === 'INVENTORY' ? (
                            <GoodsForm />
                        ) : (
                            <ServiceForm itemType={itemType} />
                        )}
                    </ItemFormWrapper>
                </ItemFormWrapperWrapper>
            </PurchaseItemFormContainer>
        );
    }
}

export default inject('store')(observer(PurchaseItemForm));
