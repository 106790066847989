import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { withTheme } from 'styled-components';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

import { parseCurrency } from '../../../util/functions';

class PieChartImpl extends Component {
    render() {
        const { height, width, data, dataKey, theme, labelKey, currencyValue } = this.props;

        data.forEach(entry => {
            entry[dataKey] = Number(entry[dataKey]);
        });

        const COLORS = [theme.color1, theme.greyA0, theme.color2, theme.greyD0];

        return (
            <ResponsiveContainer height={height || 250} width={width || '99%'}>
                <PieChart>
                    <Pie
                        isAnimationActive={false}
                        data={data}
                        cx="50%"
                        cy="50%"
                        innerRadius={60}
                        outerRadius={80}
                        fill="#8884d8"
                        paddingAngle={5}
                        dataKey={dataKey}
                        label={({ cx, cy, midAngle, innerRadius, outerRadius, value, index }) => {
                            const RADIAN = Math.PI / 180;
                            const radius = 25 + innerRadius + (outerRadius - innerRadius);
                            let x = cx + radius * Math.cos(-midAngle * RADIAN);
                            let y = cy + radius * Math.sin(-midAngle * RADIAN);

                            const margin = 5;
                            x += x > cx ? margin : -margin;
                            y += y > cy ? margin : -margin;

                            return (
                                <text
                                    x={x}
                                    y={y}
                                    fill={COLORS[index]}
                                    textAnchor={x > cx ? 'start' : 'end'}
                                    dominantBaseline="central"
                                >
                                    {data[index][labelKey]} -{' '}
                                    {currencyValue ? `${parseCurrency(value)}€` : value}
                                </text>
                            );
                        }}
                    >
                        {data.map((entry, i) => (
                            <Cell name={entry[labelKey]} key={i} fill={COLORS[i]} />
                        ))}
                    </Pie>
                    {/* <Legend
                        iconType="circle"
                        align="right"
                        verticalAlign="middle"
                        layout="vertical"
                        iconSize={10}
                    /> */}
                </PieChart>
            </ResponsiveContainer>
        );
    }
}

export default withTheme(observer(PieChartImpl));
