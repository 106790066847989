import React, { Component } from 'react';

import { CheckboxContainer, LabelStyled, CheckboxStyled } from './styles';

class Checkbox extends Component {
    toggle = () => {
        const { name, onChange, value } = this.props;
        onChange(!value, name);
    };

    render() {
        const { label, value } = this.props;

        return (
            <CheckboxContainer>
                <CheckboxStyled checked={value} type="button" onClick={this.toggle} />
                <LabelStyled>{label}</LabelStyled>
            </CheckboxContainer>
        );
    }
}

export default Checkbox;
