import styled from 'styled-components';
import { InputContainer, inputText } from '../styles';

export const LabelStyled = styled.label`
    position: absolute;
    top: -1.25rem;
    font-size: 0.875rem;
`;

export const TextareaContainer = styled(InputContainer)`
    min-height: 2rem;
    height: auto;
`;

export const TextareaStyled = styled.textarea`
    height: 100%;
    width: 100%;
    border: none;
    background: transparent;
    z-index: 1;
    outline: none;
    resize: vertical;
    ${inputText}
`;
