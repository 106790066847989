import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { SidebarContainer, SidebarHeader, Divider, SidebarWrapper } from './styles';

import SidebarItem from './SidebarItem';

class Sidebar extends Component {
    render() {
        const { ui } = this.props.store;

        return (
            <SidebarWrapper>
                <SidebarContainer>
                    <SidebarHeader>W A R E H O P</SidebarHeader>
                    <Divider />
                    <SidebarItem icon="trolley" value="Ladu" target={ui.routes.warehouse} />
                    <SidebarItem icon="hand" value="Ost" target={ui.routes.purchases} />
                    <SidebarItem icon="currency" value="Müük" target={ui.routes.sales} />
                    <SidebarItem icon="cashbox" value="Laekumised" target={ui.routes.receipts} />
                    <Divider />
                    <SidebarItem icon="money" value="Kulud" target={ui.routes.expenses} />
                    <SidebarItem icon="piggyBank" value="Tulud" target={ui.routes.incomes} />
                    <SidebarItem icon="courier" value="Partnerid" target={ui.routes.partners} />
                    <SidebarItem
                        icon="statistics"
                        value="Statistika"
                        target={ui.routes.statistics}
                    />
                    <Divider />
                    <SidebarItem icon="settings" value="Sätted" target={ui.routes.settings} />
                </SidebarContainer>
            </SidebarWrapper>
        );
    }
}

export default inject('store')(observer(Sidebar));
