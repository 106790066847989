import styled from 'styled-components';

export const WarehouseItemFormModalContainer = styled.div`
    width: 25rem;
`;

export const WarehouseItemFormContainer = styled.form`
    width: 100%;
    height: 100%;
`;
