import * as yup from 'yup';

export default {
    new: {
        purchase: {
            value: null,
            schema: yup
                .object()
                .shape({
                    id: yup.number().required()
                })
                .required('Palun vali ostuarve.')
        },
        date: {
            value: new Date(),
            schema: yup.date().required('Palun sisesta kulu tekkimise kuupäev.')
        },
        description: {
            value: '',
            schema: yup.string().nullable()
        }
    },
    edit: {
        id: {
            value: null,
            schema: yup.number().required()
        },
        date: {
            value: new Date(),
            schema: yup.date().required('Palun sisesta kulu tekkimise kuupäev.')
        },
        description: {
            value: '',
            schema: yup.string().nullable()
        }
    }
};
