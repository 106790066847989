import React from 'react';
import { observable, action, decorate, flow } from 'mobx';

import forms from '../util/forms';

import Store from './lib/Store';
import Form from './lib/Form';

import PDFView from '../components/PDFView';

class PurchaseStore extends Store {
    purchases = [];

    addItemToInvoice(newItem) {
        const item = Object.assign({}, newItem);

        this.newPurchaseForm.values.items.push(item);

        switch (item.itemType.code) {
            case 'INVENTORY':
                this.goodsForm.reset();
                break;
            case 'SERVICE':
                this.serviceForm.reset();
                break;
            case 'EXPENSE':
                this.expenseForm.reset();
                break;
            default:
                break;
        }
    }

    removeItemFromInvoice(index) {
        this.newPurchaseForm.values.items.splice(index, 1);
    }

    fetchAllPurchases = flow(function*() {
        try {
            const purchases = yield this.api.purchases.getAll();

            if (purchases.request) this.purchases = purchases.data;
        } catch (err) {
            console.error(err);
        }
    });

    deletePurchase = flow(function*(purchaseId) {
        try {
            const deletedPurchase = yield this.api.purchases.delete(purchaseId);

            deletedPurchase.data.items.forEach(item => {
                const warehouseItem = this.stores.warehouse.items.find(i => i.id === item.itemId);
                warehouseItem.quantity -= item.quantity;
            });

            this.stores.expenses.expenses = this.stores.expenses.expenses.filter(
                expense => expense.purchase.id !== purchaseId
            );

            const index = this.purchases.findIndex(purchase => purchase.id === purchaseId);
            if (index != null) this.purchases.splice(index, 1);

            return true;
        } catch (err) {
            throw err;
        }
    });

    addPurchase = flow(function*(newPurchase) {
        try {
            const { invoiceFile, ...rest } = newPurchase;

            const formData = new FormData();
            formData.append('invoiceFile', invoiceFile);
            formData.append('jsonBody', JSON.stringify(rest));

            const purchase = yield this.api.purchases.add(formData);

            newPurchase = {
                ...newPurchase,
                ...purchase.data
            };

            this.purchases.push(newPurchase);

            newPurchase.items.forEach(item => {
                if (item.warehouse) {
                    const warehouseItem = this.stores.warehouse.items.find(
                        i =>
                            i.itemType.code === 'INVENTORY' &&
                            i.id === item.id &&
                            i.warehouse.id === item.warehouse.id
                    );

                    if (warehouseItem) {
                        warehouseItem.quantity =
                            Number(warehouseItem.quantity) + Number(item.quantity);
                    } else {
                        this.stores.warehouse.items.push(item);
                    }
                }
            });

            this.newPurchaseForm.reset();
            this.serviceForm.reset();
            this.goodsForm.reset();
            this.stores.ui.closeModal();
        } catch (err) {
            this.newPurchaseForm.setGeneralErrorMessage(err.response.data.message);
        }
    });

    downloadPurchase = flow(function*(purchaseId, opts = {}) {
        try {
            const purchase = this.purchases.find(p => p.id === purchaseId);

            if (!purchase.pdf) {
                const file = yield this.api.purchases.downloadPurchase(purchaseId);
                purchase.pdf = new Blob([file.data], { type: 'application/pdf' });
            }

            if (opts.preview) {
                this.stores.ui.showModal(
                    <PDFView file={purchase.pdf} filename={`Arve ${purchase.invoiceNr}.pdf`} />
                );
            }

            return purchase.pdf;
        } catch (err) {
            console.error(err);
            return null;
        }
    });

    fetchItems = flow(function*(purchaseId) {
        try {
            const items = yield this.api.purchases.getItems(purchaseId);

            const purchase = this.purchases.find(p => p.id === purchaseId);
            purchase.items = items.data;
        } catch (err) {
            console.error(err);
        }
    });

    newPurchaseForm = new Form({
        fields: forms.purchases.new,
        onSubmit: this.addPurchase
    });

    goodsForm = new Form({
        fields: forms.purchases.goods,
        onSubmit: this.addItemToInvoice
    });

    serviceForm = new Form({
        fields: forms.purchases.service,
        onSubmit: this.addItemToInvoice
    });

    expenseForm = new Form({
        fields: forms.purchases.expense,
        onSubmit: this.addItemToInvoice
    });
}

export default decorate(PurchaseStore, {
    purchases: observable,
    addPurchase: action.bound,
    deletePurchase: action.bound,
    addItemToInvoice: action.bound,
    removeItemFromInvoice: action.bound,
    fetchAllPurchases: action.bound,
    fetchItems: action.bound,
    resetItemForm: action,
    newPurchaseForm: observable,
    itemForm: observable,
    goodsForm: observable,
    serviceForm: observable
});
