import styled from 'styled-components';

export const InputGroupContainer = styled.div`
    display: flex;
    align-items: flex-end;
`;

export const InputWrapper = styled.div`
    ${({ flex, gap }) => `
        flex: ${flex};
        flex-shrink: 0;
        
        :not(:first-child) {
            padding-left: calc(${gap} / 2);
        }

        :not(:last-child) {
            padding-right: calc(${gap} / 2);
        }
    `}
`;

export const ActionComponentWrapper = styled.div`
    margin-left: 0.25rem;
    margin-bottom: 0.75rem;
`;
