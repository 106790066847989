import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Route, withRouter } from 'react-router-dom';

import { MainContainer, ContentContainer, TableContainer } from './styles';

import Sidebar from '../Sidebar';
import WarehouseTable from '../WarehouseTable';
import PurchaseTable from '../PurchaseTable';
import SaleTable from '../SaleTable';
import ReceiptTable from '../ReceiptTable';
import LoadingScreen from '../LoadingScreen';
import PartnerTable from '../PartnerTable';
import ExpenseTable from '../ExpenseTable';
import IncomeTable from '../IncomeTable';
import Statistics from '../Statistics';

import Modal from '../styled/Modal';

import Settings from '../Settings';

class Main extends Component {
    async componentDidMount() {
        const { app, ui, auth } = this.props.store;
        await app.initialize();

        if (auth.user.isFirstLogin) {
            ui.setActiveView(ui.routes.generalSettings);
        }
    }

    render() {
        const { store } = this.props;
        const { routes } = store.ui;

        return store.app.isInitialized ? (
            <MainContainer>
                <Sidebar />
                <ContentContainer>
                    <TableContainer>
                        <Route path={routes.warehouse} component={WarehouseTable} />
                        <Route path={routes.purchases} component={PurchaseTable} />
                        <Route path={routes.sales} component={SaleTable} />
                        <Route path={routes.receipts} component={ReceiptTable} />
                        <Route path={routes.settings} component={Settings} />
                        <Route path={routes.partners} component={PartnerTable} />
                        <Route path={routes.expenses} component={ExpenseTable} />
                        <Route path={routes.incomes} component={IncomeTable} />
                        <Route path={routes.statistics} component={Statistics} />
                    </TableContainer>
                </ContentContainer>
                <Modal
                    body={store.ui.modal.body}
                    isOpen={store.ui.modal.isOpen}
                    onClose={store.ui.closeModal}
                />
            </MainContainer>
        ) : (
            <LoadingScreen />
        );
    }
}

export default withRouter(inject('store')(observer(Main)));
