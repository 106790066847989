import styled from 'styled-components';
import Input from './Input';

export const MainViewContainer = styled.div`
    padding: 0 1.5625rem;
    display: flex;
    height: 100%;
    flex-direction: column;
`;

export const MainViewHeader = styled.div`
    ${({ theme }) => `
        padding: .75rem 0;
        /*border-bottom: 1px solid ${theme.greyF0};*/
        display: flex;
        align-items: flex-end;
    `}
`;

export const MainViewBody = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
`;

export const HeaderText = styled.h1`
    ${({ theme }) => `
        font-size: 1.625rem;
        font-weight: 600;
        margin: 0;
        padding: 0;
        line-height: 1.75rem;
        color: ${theme.color1};
    `}
`;

export const SearchInputContainer = styled.div`
    ${({ theme }) => `
        display: flex;
        align-items: center;
        margin-left: auto;
        justify-content: flex-end;
        padding-left: 2rem;
        flex: 1;

        svg {
            fill: ${theme.grey77};
        }
    `}
`;

export const SearchInput = styled(Input).attrs({ errorless: true })`
    ${({ theme }) => `
        margin-right: 0.25rem;
        flex: 1;

        .input-container,
        & {
            border: none;
            margin-top: 0;
        }

        .input-container input {
            font-weight: 400;
            color: ${theme.grey77};
            text-align: right;
        }
    `}
`;

/* export const SearchInput = styled.input`
    ${({ theme }) => `
        font-family: 'Open Sans', sans-serif;
        font-size: 1rem;
        border: none;
        text-align: right;
        margin-right: .5rem;
        outline: none;
        color: ${theme.grey77};
    `}
`; */

export const MainViewFooter = styled.div`
    ${({ theme }) => `
        /*border-top: 1px solid ${theme.greyF0};*/
        padding-bottom: 1.625rem;
        text-align: right;
    `}
`;
