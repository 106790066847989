import * as yup from 'yup';

export default {
    edit: {
        id: {
            value: null,
            schema: yup.number().required()
        },
        date: {
            value: new Date(),
            schema: yup.date().required('Palun sisesta tulu tekkimise kuupäev.')
        },
        description: {
            value: '',
            schema: yup.string().nullable()
        }
    }
};
