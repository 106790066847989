import React, { Component } from 'react';

import { LabelStyled, LabelContent } from './styles';

class Label extends Component {
    state = {
        isMounted: false,
        timeout: null
    };

    componentDidMount() {
        this.setState(() => ({
            timeout: setTimeout(() => {
                this.setState(() => ({ isMounted: true }));
            }, 100)
        }));
    }

    componentWillUnmount() {
        clearTimeout(this.state.timeout);
    }

    render() {
        const { value, lifted, required, ...restProps } = this.props;
        const { isMounted } = this.state;

        return (
            <LabelStyled {...restProps} lifted={lifted}>
                <LabelContent lifted={lifted} isMounted={isMounted}>
                    {value}
                    {required && <span>*</span>}
                </LabelContent>
            </LabelStyled>
        );
    }
}

export default Label;
