import React, { Component } from 'react';
import { observer } from 'mobx-react';
import * as yup from 'yup';

import { SelectContainer, SelectStyled } from './styles';

import Icon from '../../Icon';
import Label from '../Label';

class Select extends Component {
    state = {
        isFocused: false,
        schema: yup
            .object()
            .shape({
                value: yup
                    .mixed()
                    .nullable()
                    .required(),
                label: yup.string().required()
            })
            .notEmpty()
            .required()
    };

    componentDidMount() {
        const { value, options, initiallyEmpty } = this.props;
        const { schema } = this.state;

        if (!initiallyEmpty && !schema.isValidSync(this.formatOption(value)))
            this.select(this.formatOption(options[0]));
    }

    select = value => {
        const { onChange, name } = this.props;
        if (onChange && value) onChange(value.value, name);
    };

    hasValue = () => {
        const { value } = this.props;
        const { schema } = this.state;
        return schema.isValidSync(this.formatOption(value));
    };

    formatOption = option => {
        const { parseValue } = this.props;
        const { schema } = this.state;

        if (!parseValue || schema.isValidSync(option) || !(option instanceof Object)) {
            return option;
        }

        return parseValue(option);
    };

    formatOptions() {
        const { options, required } = this.props;

        const formattedOptions = options.map(option => this.formatOption(option));

        if (!required) formattedOptions.unshift({ value: null, label: '-' });

        return formattedOptions;
    }

    render() {
        const {
            label,
            required,
            className,
            onChange,
            value,
            disabled,
            placeholder,
            options,
            noOptionsMessage,
            ...restProps
        } = this.props;

        const { isFocused } = this.state;

        const formattedOptions = this.formatOptions();
        const formattedValue = this.formatOption(value);

        return (
            <SelectContainer isFocused={isFocused} disabled={disabled} className={className}>
                <SelectStyled
                    value={formattedValue}
                    onChange={this.select}
                    isDisabled={disabled}
                    placeholder={placeholder || ''}
                    options={formattedOptions}
                    onFocus={() => this.setState({ isFocused: true })}
                    onBlur={() => this.setState({ isFocused: false })}
                    noOptionsMessage={() => noOptionsMessage || 'Valikud puuduvad'}
                    {...restProps}
                />
                <Icon type="chevronDown" size=".75rem" />
                {label && <Label required={required} value={label} lifted={this.hasValue()} />}
            </SelectContainer>
        );
    }
}

export default observer(Select);
