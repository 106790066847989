import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import ResponsiveModal from 'react-responsive-modal';

class Modal extends Component {
    render() {
        const { isOpen, onClose, body, width } = this.props;

        const styles = {
            modal: {
                boxShadow: '4px 6px 10px rgba(0, 0, 0, .1)',
                padding: '0 1.625rem',
                maxWidth: width || null
            },
            closeButton: {
                top: '0',
                right: '0',
                padding: '.5rem',
                cursor: 'pointer'
            },
            closeIcon: {
                width: '1rem',
                height: '1rem'
            }
        };

        return (
            <ResponsiveModal styles={styles} open={isOpen} onClose={onClose}>
                {body}
            </ResponsiveModal>
        );
    }
}

export default inject('store')(observer(Modal));
