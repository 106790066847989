import { observable, action, decorate, computed } from 'mobx';
import { createTransformer } from 'mobx-utils';

import history from '../util/history';
import constants from '../util/constants';
import Store from './lib/Store';

class UIStore extends Store {
    activeView;

    routes = constants.routes;

    theme = constants.theme;

    modal = {
        queue: [],
        isOpen: false,
        body: null
    };

    constructor(...args) {
        super(...args);

        this._handleURLRoute(history.location.pathname);
    }

    showModal = body => {
        this.modal.isOpen = true;
        this.modal.queue.push(body);
        this.modal.body = body;
    };

    closeModal = () => {
        const { modal } = this;
        modal.queue.pop();
        const len = modal.queue.length;
        if (len === 0) modal.isOpen = false;
        else modal.body = modal.queue[len - 1];
    };

    setActiveView = route => {
        history.push(route);
        this.activeView = route;
    };

    get isActiveView() {
        return createTransformer(route => {
            if (route.length > this.activeView.length) {
                return route.startsWith(this.activeView);
            }
            return this.activeView.startsWith(route);
        });
    }

    _handleURLRoute = route => {
        const isValidRoute =
            Object.values(this.routes).filter(r => r.startsWith(route) && route.length >= r.length)
                .length > 0 && route !== this.routes.main;

        const _route = isValidRoute ? route : this.routes.warehouse;

        this.setActiveView(_route);
    };
}

export default decorate(UIStore, {
    activeView: observable,
    setActiveView: action,
    isActiveView: computed,
    modal: observable,
    showModal: action,
    closeModal: action
});
