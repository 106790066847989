import { observable, decorate, action, flow } from 'mobx';

import forms from '../util/forms';
import currency from '../util/currency';

import Store from './lib/Store';
import Form from './lib/Form';

class ReceiptStore extends Store {
    receipts = [];

    addReceipt = flow(function*(newReceipt) {
        try {
            const receipt = yield this.api.receipts.add(newReceipt);
            const { income, sum, id } = receipt.data;

            newReceipt.id = id;

            const sale = this.stores.sales.sales.find(s => s.id === newReceipt.sale.id);
            if (sale) {
                sale.paidSum = currency(sale.paidSum).add(sum);
                sale.unpaidSum = currency(sale.unpaidSum).subtract(sum);

                if (income) {
                    sale.isPaid = true;
                    income.sale = sale;
                }
            }

            if (income) this.stores.incomes.incomes.push(income);

            this.receipts.push(newReceipt);
            this.newReceiptForm.reset();
            this.stores.ui.closeModal();
        } catch (err) {
            console.error(err);
        }
    });

    fetchAllReceipts = flow(function*() {
        try {
            const receipts = yield this.api.receipts.getAll();

            if (receipts.request) this.receipts = receipts.data;
        } catch (err) {
            console.error(err);
        }
    });

    deleteReceipt = flow(function*(receiptId) {
        try {
            const { data } = yield this.api.receipts.delete(receiptId);
            const { receipt } = data;

            if (receipt.saleId) {
                const sale = this.stores.sales.sales.find(s => s.id === receipt.saleId);

                if (sale) {
                    sale.paidSum = currency(sale.paidSum).subtract(receipt.sum);
                    sale.unpaidSum = currency(sale.unpaidSum).add(receipt.sum);
                    sale.isPaid = false;
                }

                const incomeIndex = this.stores.incomes.incomes.findIndex(
                    income => income.sale && income.sale.id === sale.id
                );

                this.stores.incomes.incomes.splice(incomeIndex, 1);
            }

            const index = this.receipts.findIndex(r => r.id === receiptId);
            if (index != null) this.receipts.splice(index, 1);
        } catch (err) {
            console.error(err);
        }
    });

    editReceipt = flow(function*(editedReceipt) {
        try {
            const { data } = yield this.api.receipts.edit(editedReceipt);
            editedReceipt.date = data.date;

            const sale = this.stores.sales.sales.find(s => s.id === data.sale.id);
            if (sale) {
                sale.paidSum = data.sale.paidSum;
                sale.unpaidSum = data.sale.unpaidSum;
            }

            const index = this.receipts.findIndex(receipt => receipt.id === editedReceipt.id);
            if (index != null) this.receipts[index] = editedReceipt;

            this.stores.ui.closeModal();
        } catch (err) {
            this.editReceiptForm.setGeneralErrorMessage(err.response.data.message);
            console.error(err);
        }
    });

    newReceiptForm = new Form({
        fields: forms.receipts.new,
        onSubmit: this.addReceipt
    });

    editReceiptForm = new Form({
        fields: forms.receipts.edit,
        onSubmit: this.editReceipt
    });
}

export default decorate(ReceiptStore, {
    receipts: observable,
    newReceiptForm: observable,
    addReceipt: action.bound,
    editReceipt: action.bound,
    fetchAllReceipts: action.bound,
    deleteReceipt: action.bound
});
