import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { ModalHeader, ModalBody, ModalFooter } from '../styled/Modal/styles';
import { ErrorContainer } from '../styled/Error';
import { WarehouseItemFormModalContainer } from './styles';

import Button from '../styled/Button';

import WarehouseItemForm from './WarehouseItemForm';

class WarehouseItemFormModal extends Component {
    render() {
        const { store } = this.props;
        const { newItemForm } = store.warehouse;
        const { generalErrorMessage } = newItemForm;

        return (
            <WarehouseItemFormModalContainer>
                <ModalHeader>Lisa uus kaup</ModalHeader>
                <ModalBody>
                    <WarehouseItemForm id="newItem" form={newItemForm} />
                </ModalBody>
                <ModalFooter borderless>
                    {generalErrorMessage && <ErrorContainer>{generalErrorMessage}</ErrorContainer>}
                    <Button
                        form="newItem"
                        value="Lisa kaup"
                        isLoading={store.warehouse.newItemForm.isLoading}
                    />
                </ModalFooter>
            </WarehouseItemFormModalContainer>
        );
    }
}

export default inject('store')(observer(WarehouseItemFormModal));
