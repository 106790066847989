import axios from 'axios';
import cachios from 'cachios';
import constants from '../util/constants';

import { withAuthHeaders } from './index';

export default {
    add: async sale => {
        return axios.post(`${constants.API_URL}/sales`, sale, withAuthHeaders());
    },
    delete: async saleId => {
        return axios.delete(
            `${constants.API_URL}/sales`,
            withAuthHeaders({
                params: {
                    saleId
                }
            })
        );
    },
    search: async (opts = {}) => {
        return cachios.get(
            `${constants.API_URL}/sales/search`,
            withAuthHeaders({
                params: opts
            })
        );
    },
    getAll: async (opts = {}) => {
        const { cache } = opts;
        const httpClient = cache === false ? axios : cachios;

        return httpClient.get(`${constants.API_URL}/sales`, withAuthHeaders());
    },
    getItems: async saleId => {
        return cachios.get(
            `${constants.API_URL}/sales/items`,
            withAuthHeaders({
                params: {
                    saleId
                }
            })
        );
    },
    downloadSale: async saleId => {
        return axios.get(
            `${constants.API_URL}/sales/file`,
            withAuthHeaders({
                params: {
                    saleId
                },
                responseType: 'arraybuffer'
            })
        );
    }
};
