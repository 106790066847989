import React, { Component } from 'react';
import { observer } from 'mobx-react';

import { ErrorContainer, InputWrapper } from './styles';

import DateInput from './DateInput';
import FileInput from './FileInput';
import Select from './Select';
import Checkbox from './Checkbox';
import TextInput from './TextInput';
import AutoSuggest from './AutoSuggest';
import Textarea from './Textarea';
import StaticInput from './StaticInput';

class Input extends Component {
    componentDidUpdate(prevProps) {
        const { disabled, disabledValue, name, onChange } = this.props;
        if (prevProps.disabled !== disabled) {
            onChange(disabledValue, name);
        }
    }

    render() {
        const { type, errors, disabled, disabledValue, className, errorless } = this.props;

        const props = Object.assign({}, this.props);

        if (disabled) {
            props.value = disabledValue;
        }

        props.key = disabled;

        const inputTypes = {
            file: <FileInput {...props} />,
            date: <DateInput {...props} />,
            select: <Select {...props} />,
            checkbox: <Checkbox {...props} />,
            autosuggest: <AutoSuggest {...props} />,
            textarea: <Textarea {...props} />,
            static: <StaticInput {...props} />
        };

        return (
            <InputWrapper className={className}>
                {inputTypes[type] || <TextInput {...props} />}
                {!errorless && (
                    <ErrorContainer>{errors && errors.length > 0 && errors[0]}</ErrorContainer>
                )}
            </InputWrapper>
        );
    }
}

export default observer(Input);
