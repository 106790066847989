import React, { Component } from 'react';

import { InputContainer, InputStyled } from '../styles';

import Label from '../Label';

class TextInput extends Component {
    handleChange = e => {
        const { onChange, name } = this.props;
        const { value } = e.target;
        if (onChange) onChange(value, name);
    };

    hasValue = () => {
        const { value } = this.props;
        if (typeof value === 'string') {
            return value.length > 0;
        }
        if (value instanceof Object) {
            return Object.keys(value).length > 0;
        }
        return value != null;
    };

    render() {
        const { label, value, type, onChange, name, required, disabled, ...restProps } = this.props;

        return (
            <InputContainer disabled={disabled}>
                <InputStyled
                    value={value != null ? value : ''}
                    autoComplete="off"
                    type={type || 'text'}
                    onChange={this.handleChange}
                    disabled={disabled}
                    {...restProps}
                />
                <Label value={label} required={required} lifted={this.hasValue()} />
            </InputContainer>
        );
    }
}

export default TextInput;
