import styled from 'styled-components';

export const MainContainer = styled.div`
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`;

export const ContentContainer = styled.div`
    ${({ theme }) => `
        flex: 1;
        display: flex;
        background: ${theme.greyF0};
        padding: 3.5rem;
    `}
`;

export const TableContainer = styled.div`
    ${({ theme }) => `
        background: ${theme.white(1)};
        width: 100%;
        height: 100%;
        box-shadow: 4px 6px 10px ${theme.black(0.1)};
    `}
`;
