import axios from 'axios';
import cachios from 'cachios';
import constants from '../util/constants';

import { withAuthHeaders } from './index';

export default {
    getAll: async () => {
        return cachios.get(`${constants.API_URL}/incomes`, withAuthHeaders());
    },
    edit: async editedIncome => {
        return axios.put(`${constants.API_URL}/incomes`, editedIncome, withAuthHeaders());
    },
    search: async opts => {
        return cachios.get(
            `${constants.API_URL}/incomes/search`,
            withAuthHeaders({
                params: opts
            })
        );
    },
    delete: async incomeId => {
        return axios.delete(
            `${constants.API_URL}/incomes`,
            withAuthHeaders({
                params: {
                    incomeId
                }
            })
        );
    }
};
