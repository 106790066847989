import moment from 'moment';

export const parseDate = date => {
    const parsedDate = moment(date, 'DD.MM.YYYY');
    return parsedDate.isValid()
        ? moment(parsedDate).format('DD.MM.YYYY')
        : moment(date).format('DD.MM.YYYY');
};

export const parseCurrency = value => {
    const result = parseFloat(value).toFixed(2);
    return Number.isNaN(Number(result)) ? '' : result;
};

export const compare = (lhs, rhs, property, direction) => {
    const lhsVal = Object.byString(lhs, property);
    const rhsVal = Object.byString(rhs, property);

    if (direction > 0) {
        if (lhsVal < rhsVal) return -1;
        if (lhsVal > rhsVal) return 1;
    } else {
        if (lhsVal > rhsVal) return -1;
        if (lhsVal < rhsVal) return 1;
    }

    return 0;
};

export const filterDuplicates = (lhsArray, rhsArray, properties) => {
    const duplicates = [];
    const uniques = [];

    lhsArray.forEach(lhs => {
        const duplicate = rhsArray.some(rhs => {
            return properties.every(
                property => Object.byString(rhs, property) === Object.byString(lhs, property)
            );
        });
        if (duplicate) duplicates.push(lhs);
        else uniques.push(lhs);
    });
    return { duplicates, uniques };
};

/* export const debounce = async (fn, delay) => {
    let timer = null;
    return function(...args) {
        const context = this;
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            fn.apply(context, args);
        }, delay);
    };
}; */

export const debounce = (fn, delay) => {
    let timer;
    return function(...args) {
        const promise = new Promise((resolve, reject) => {
            if (timer) clearTimeout(timer);
            timer = setTimeout(async () => {
                const result = await fn(...args);
                console.log(result);
                timer = null;
                resolve(result);
            }, delay);
        });
        return promise;
    };
};

export class Debouncer {
    timer;

    fn;

    delay;

    constructor(fn, delay) {
        this.fn = fn;
        this.delay = delay;
    }

    call = (...args) => {
        clearTimeout(this.timer);

        if (this.timer === null) {
            this.timer = setTimeout(this.fn(args), this.delay);
        }
    };
}
