import { observable, decorate, action, flow } from 'mobx';

import forms from '../util/forms';
import Store from './lib/Store';
import Form from './lib/Form';

class ExpenseStore extends Store {
    expenses = [];

    addExpense = flow(function*(newExpense) {
        try {
            const expense = yield this.api.expenses.add(newExpense);

            if (expense.data.purchaseId) {
                const purchase = this.stores.purchases.purchases.find(
                    p => p.id === expense.data.purchaseId
                );

                if (purchase) purchase.isPaid = true;
            }

            newExpense.sum = expense.data.sum;
            newExpense.id = expense.data.id;

            this.expenses.push(newExpense);

            this.newExpenseForm.reset();
            this.stores.ui.closeModal();
        } catch (err) {
            console.error(err);
        }
    });

    fetchAllExpenses = flow(function*() {
        try {
            const expenses = yield this.api.expenses.getAll();

            if (expenses.request) this.expenses = expenses.data;
        } catch (err) {
            console.error(err);
        }
    });

    deleteExpense = flow(function*(expenseId) {
        try {
            yield this.api.expenses.delete(expenseId);

            const expense = this.expenses.find(e => e.id === expenseId);

            if (expense.purchase) {
                const purchase = this.stores.purchases.purchases.find(
                    p => p.id === expense.purchase.id
                );

                if (purchase) purchase.isPaid = false;
            }

            const index = this.expenses.findIndex(e => e.id === expenseId);
            if (index != null) this.expenses.splice(index, 1);

            return true;
        } catch (err) {
            throw err;
        }
    });

    editExpense = flow(function*(editedExpense) {
        try {
            yield this.api.expenses.edit(editedExpense);

            const expenseIndex = this.expenses.findIndex(
                expense => expense.id === editedExpense.id
            );

            if (expenseIndex != null) {
                this.expenses[expenseIndex] = {
                    ...this.expenses[expenseIndex],
                    ...editedExpense
                };
            }

            this.stores.ui.closeModal();
        } catch (err) {
            console.error(err);
        }
    });

    newExpenseForm = new Form({
        fields: forms.expenses.new,
        onSubmit: this.addExpense
    });

    editExpenseForm = new Form({
        fields: forms.expenses.edit,
        onSubmit: this.editExpense
    });
}

export default decorate(ExpenseStore, {
    newExpenseForm: observable,
    editExpenseForm: observable,
    expenses: observable,
    editExpense: action.bound,
    addExpense: action.bound,
    fetchAllExpenses: action.bound,
    deleteExpense: action.bound
});
