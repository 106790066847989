import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { WarehouseFormFormContainer } from './styles';

import { ErrorContainer } from '../styled/Error';

import Input from '../styled/Input';

class WarehouseFormForm extends Component {
    onSubmit = e => {
        e.preventDefault();
        const { store } = this.props;
        store.warehouse.addWarehouse();
    };

    render() {
        const { id, store } = this.props;

        const { newWarehouseForm } = store.warehouse;

        return (
            <WarehouseFormFormContainer id={id} onSubmit={newWarehouseForm.submit}>
                <Input
                    required
                    label="Lao nimetus"
                    name="name"
                    onChange={newWarehouseForm.handleChange}
                    value={newWarehouseForm.values.name}
                    errors={newWarehouseForm.errors.name}
                />
                {newWarehouseForm.generalErrorMessage && (
                    <ErrorContainer>{newWarehouseForm.generalErrorMessage}</ErrorContainer>
                )}
            </WarehouseFormFormContainer>
        );
    }
}

export default inject('store')(observer(WarehouseFormForm));
