import * as yup from 'yup';

export default {
    new: {
        name: {
            value: '',
            schema: yup
                .string()
                .trim()
                .required('Palun sisesta lao nimetus.')
        }
    }
};
