import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { ModalHeader, ModalBody, ModalFooter } from '../styled/Modal/styles';
import { PartnerFormContainer } from './styles';

import Button from '../styled/Button';
import PartnerForm from './PartnerForm';

class PartnerFormModal extends Component {
    render() {
        const { newPartnerForm } = this.props.store.partners;

        return (
            <PartnerFormContainer>
                <ModalHeader>Lisa uus partner</ModalHeader>
                <ModalBody>
                    <PartnerForm id="newPartner" form={newPartnerForm} />
                </ModalBody>
                <ModalFooter borderless>
                    <Button
                        form="newPartner"
                        value="Lisa partner"
                        isLoading={newPartnerForm.isLoading}
                    />
                </ModalFooter>
            </PartnerFormContainer>
        );
    }
}

export default inject('store')(observer(PartnerFormModal));
