import * as yup from 'yup';

export default {
    edit: {
        name: {
            value: '',
            schema: yup.string()
        },
        phoneNr: {
            value: '',
            schema: yup.string()
        },
        email: {
            value: '',
            schema: yup.string()
        },
        registryNr: {
            value: '',
            schema: yup.string()
        },
        postalCode: {
            value: '',
            schema: yup.string()
        },
        county: {
            value: '',
            schema: yup.string()
        },
        country: {
            value: '',
            schema: yup.string()
        }
    }
};
