import styled from 'styled-components';

export const LoginContainer = styled.div`
    ${({ theme }) => `
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        background: ${theme.greyF0};
    `}
`;

export const LoginForm = styled.form`
    ${({ theme }) => `
        width: 20rem;
        background: ${theme.white(1)};
        padding: 2rem 1rem;
        box-shadow: 4px 6px 10px ${theme.black(0.1)};
    `}
`;

export const FormTitle = styled.h1`
    margin: 0 0 1rem 0;
`;
