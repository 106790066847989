import styled from 'styled-components';

import { ModalHeader, ModalFooter } from '../Modal/styles';

export const ConfirmationContainer = styled.div`
    width: 25rem;
`;

export const ConfirmationFooter = styled(ModalFooter)`
    text-align: left;
`;

export const ConfirmationHeader = styled(ModalHeader)`
    font-size: 1.25rem;
`;

export const DescriptionMessage = styled.div`
    ${({ theme }) => `
        padding-top: .5rem;
        line-height: 1.5rem;
        color: ${theme.grey33};
    `}
`;

export const ConfirmationButtons = styled.div`
    ${({ theme }) => `
        justify-content: space-between;
        display: flex;
        width: 100%;

        button {
            flex-basis: 48%;
        }

        .no-btn {
            background: transparent;
            box-shadow: none;
            border: 1px solid ${theme.color1};
            color ${theme.color1};

            :hover, :focus {
                background: ${theme.greyF5};
            }
        }
    `}
`;
