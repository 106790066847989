import styled, { css } from 'styled-components';

export const ErrorContainer = styled.div`
    ${({ theme, margin }) => `
        color: ${theme.danger};
        text-align: left;
        border-radius: 2px;
        font-size: .8rem;
        border: 1px solid ${theme.danger};
        padding: .5rem;
        margin: ${margin || '.75rem 0 0 0'};
    `}
`;
