import { observable, action, decorate, flow } from 'mobx';

import Store from './lib/Store';

class StatisticsStore extends Store {
    data = {
        profit: 0,
        expenses: 0,
        incomes: 0,
        charts: {
            revenue: [],
            profit: [],
            itemTypeSums: []
        }
    };

    options = {
        startDate: new Date('2019-01-01'),
        endDate: new Date(),
        groupBy: 'month'
    };

    fetchStatistics = flow(function*() {
        try {
            const statistics = yield this.api.statistics.get(this.options);

            this.data = statistics.data;
        } catch (err) {
            console.error(err);
        }
    });

    changeOption = (value, name) => {
        this.options[name] = value;
        this.fetchStatistics();
    };
}

export default decorate(StatisticsStore, {
    data: observable,
    options: observable,
    fetchStatistics: action.bound,
    changeOption: action
});
