import React, { Component } from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';

import { DateInputContainer, DateInputWrapper } from './styles';

import Label from '../Label';
import Icon from '../../Icon';

class DateInput extends Component {
    state = {
        isFocused: false
    };

    toggleFocus = () => {
        this.setState(({ isFocused }) => ({ isFocused: !isFocused }));
    };

    render() {
        const { label, value, onChange, name, required, disabled } = this.props;

        const { isFocused } = this.state;

        const inputProps = {
            onFocus: () => this.toggleFocus(),
            onBlur: () => this.toggleFocus()
        };

        const WEEKDAYS_SHORT = {
            et: ['P', 'E', 'T', 'K', 'N', 'R', 'L']
        };

        const MONTHS = {
            et: [
                'Jaanuar',
                'Veebruar',
                'Märts',
                'Aprill',
                'Mai',
                'Juuni',
                'Juuli',
                'August',
                'September',
                'Oktoober',
                'November',
                'Detsember'
            ]
        };

        const FIRST_DAY = {
            et: 1
        };

        const localeUtils = {
            formatDay() {},
            formatMonthTitle(d, locale) {
                return `${MONTHS[locale][d.getMonth()]} ${d.getFullYear()}`;
            },
            formatWeekdayShort(i, locale) {
                return WEEKDAYS_SHORT[locale][i];
            },
            formatWeekdayLong(i, locale) {
                return WEEKDAYS_SHORT[locale][i];
            },
            getFirstDayOfWeek(locale) {
                return FIRST_DAY[locale];
            }
        };

        return (
            <DateInputContainer disabled={disabled} isFocused={isFocused}>
                <Label value={label} required={required} lifted />
                <Icon type="calendar" size="1.25rem" />
                <DateInputWrapper isFocused={isFocused}>
                    <DayPickerInput
                        formatDate={formatDate}
                        parseDate={parseDate}
                        format="DD.MM.YYYY"
                        onDayChange={day => onChange(day, name)}
                        value={value ? new Date(value) : new Date()}
                        inputProps={inputProps}
                        dayPickerProps={{
                            locale: 'et',
                            localeUtils
                        }}
                    />
                </DateInputWrapper>
            </DateInputContainer>
        );
    }
}

export default DateInput;
