import auth from './auth';
import items from './items';
import partners from './partners';
import purchases from './purchases';
import receipts from './receipts';
import sales from './sales';
import warehouses from './warehouses';
import common from './common';
import expenses from './expenses';
import incomes from './incomes';
import statistics from './statistics';

export const withAuthHeaders = data => {
    const token = localStorage.getItem('access_token');

    if (data && data.headers) {
        return {
            ...data,
            headers: {
                ...data.headers,
                Authorization: `Bearer ${token}`
            }
        };
    }

    return {
        ...data,
        headers: {
            Authorization: `Bearer ${token}`
        }
    };
};

export default {
    auth,
    items,
    partners,
    purchases,
    receipts,
    sales,
    warehouses,
    common,
    expenses,
    incomes,
    statistics
};
