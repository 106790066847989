import axios from 'axios';
import cachios from 'cachios';
import constants from '../util/constants';

import { withAuthHeaders } from './index';

export default {
    get: async () => {
        return cachios.get(`${constants.API_URL}/common`, withAuthHeaders());
    },
    editGeneralSettings: async newSettings => {
        return axios.put(`${constants.API_URL}/common/settings`, newSettings, withAuthHeaders());
    },
    editProfile: async newProfile => {
        return axios.put(`${constants.API_URL}/common/profiles`, newProfile, withAuthHeaders());
    },
    addBankAccount: async newBankAccount => {
        return axios.post(
            `${constants.API_URL}/common/bankAccounts`,
            newBankAccount,
            withAuthHeaders()
        );
    },
    deleteBankAccount: async bankAccToDelete => {
        return axios.delete(
            `${constants.API_URL}/common/bankAccounts`,
            withAuthHeaders({
                params: bankAccToDelete
            })
        );
    },
    addUnit: async unit => {
        return axios.post(`${constants.API_URL}/common/units`, unit, withAuthHeaders());
    }
};
