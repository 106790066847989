import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { SaleFormContainer } from './styles';

import InputGroup from '../styled/InputGroup';
import Input from '../styled/Input';
import AddButton from '../styled/AddButton';

import PartnerForm from '../PartnerForm';

class SaleForm extends Component {
    render() {
        const { id, store } = this.props;

        const { newSaleForm } = store.sales;

        const AddPartnerButton = (
            <AddButton
                tooltip="Lisa uus partner"
                type="button"
                value="+"
                size="2rem"
                onClick={() => store.ui.showModal(<PartnerForm />)}
            />
        );

        const clients = store.partners.partners.filter(partner => partner.partnerType.id === 1);

        return (
            <SaleFormContainer id={id} onSubmit={newSaleForm.submit}>
                <InputGroup actionComponent={AddPartnerButton}>
                    <Input
                        required
                        initiallyEmpty
                        type="select"
                        label="Klient"
                        name="partner"
                        errors={newSaleForm.errors.partner}
                        value={newSaleForm.values.partner}
                        parseValue={value => ({
                            label: `${value.name}${value.phoneNr &&
                                `, Tel ${value.phoneNr}`}${value.email && `, ${value.email}`}`,
                            value
                        })}
                        onChange={newSaleForm.handleChange}
                        options={clients}
                    />
                </InputGroup>
                <InputGroup>
                    <Input
                        required
                        name="invoiceNr"
                        errors={newSaleForm.errors.invoiceNr}
                        onChange={newSaleForm.handleChange}
                        value={newSaleForm.values.invoiceNr}
                        label="Arve nr"
                    />
                </InputGroup>
                <InputGroup>
                    <Input
                        type="date"
                        required
                        label="Müügikuupäev"
                        errors={newSaleForm.errors.saleDate}
                        onChange={newSaleForm.handleChange}
                        value={newSaleForm.values.saleDate}
                        name="saleDate"
                    />
                    <Input
                        required
                        type="date"
                        label="Maksetähtaeg"
                        errors={newSaleForm.errors.dueDate}
                        onChange={newSaleForm.handleChange}
                        value={newSaleForm.values.dueDate}
                        name="dueDate"
                    />
                </InputGroup>
                <Input
                    type="textarea"
                    label="Märkused"
                    errors={newSaleForm.errors.description}
                    onChange={newSaleForm.handleChange}
                    value={newSaleForm.values.description}
                    name="description"
                />
            </SaleFormContainer>
        );
    }
}

export default inject('store')(observer(SaleForm));
