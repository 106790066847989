import styled from 'styled-components';

export const WarehouseFormContainer = styled.div`
    width: 20rem;
`;

export const WarehouseFormFormContainer = styled.form`
    width: 100%;
    height: 100%;
`;

export const WarehouseListContainer = styled.div`
    height: 20rem;
    overflow: auto;
`;

export const WarehouseItem = styled.div`
    ${({ theme }) => `
        display: flex;
        padding: .25rem .5rem;
        color: ${theme.grey33};

        :nth-child(odd) {
            background: ${theme.greyF5};
        }

        .icon {
            cursor: pointer;
            fill: ${theme.grey77};
            margin-left: auto;
        }
    `}
`;
