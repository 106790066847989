import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { parseDate, parseCurrency } from '../../util/functions';

import { ModalHeader, ModalBody, ModalFooter } from '../styled/Modal/styles';
import { IncomeOverviewContainer } from './styles';

import Input from '../styled/Input';

class IncomeOverview extends Component {
    render() {
        const { income } = this.props;

        return (
            <IncomeOverviewContainer>
                <ModalHeader>Tulu ülevaade</ModalHeader>
                <ModalBody>
                    <Input type="static" label="Müügiarve nr" value={income.sale.invoiceNr} />
                    <Input type="static" label="Klient" value={income.sale.partner.name} />
                    <Input type="static" label="Kuupäev" value={parseDate(income.date)} />
                    <Input type="static" label="Summa" value={parseCurrency(income.sum)} />
                    <Input type="static" label="Märkused" value={income.description} />
                </ModalBody>
                <ModalFooter borderless />
            </IncomeOverviewContainer>
        );
    }
}

export default inject('store')(observer(IncomeOverview));
