import styled from 'styled-components';

export const ItemFormWrapper = styled.div`
    padding-right: 1rem;
    flex: 1;
`;

export const ItemFormContainer = styled.form``;

export const SaleItemFormContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-right: 0.5rem;
`;

export const ItemFormWrapperWrapper = styled.div`
    min-height: 0;
    flex: 1;
    display: flex;
    overflow: auto;
`;
