import styled from 'styled-components';
import ReactSelect from 'react-select';

import { inputText } from '../styles';

export const SelectContainer = styled.div`
    ${({ theme, disabled, isFocused }) => `  
        display: flex;
        width: 100%;
        border-bottom: 1px solid ${disabled ? theme.greyD0 : theme.greyA0};
        position: relative;
        margin-top: 1.5rem;

        z-index: ${isFocused ? 999 : 0};

        .icon {
            fill: ${theme.grey33};
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            position: absolute;
        }

        label {
            transition: none !important;
        }

        ${disabled &&
            `
            * {
                color: ${theme.greyA0} !important;
                cursor: not-allowed !important;
                fill: ${theme.greyD0};
            }
        `}
    `}
`;

export const SelectStyled = styled(ReactSelect).attrs({
    classNamePrefix: 'react-select'
})`
    ${({ theme }) => `
        flex: 1;
        height: 2rem;
        z-index: 2;
        pointer-events: inherit !important;

        .react-select__control {
            border: none;
            cursor: pointer;
        }

        .react-select__indicator-separator {
            display: none;
        }

        .react-select__value-container {
            padding: 0;
            overflow: visible;
        }

        .react-select__dropdown-indicator {
            padding: 0;
        }

        .react-select__control {
            min-height: 0;
            background: transparent;
            height: 100%;
        }

        .react-select__single-value {
            margin: 0;
            ${inputText}
        }

        .react-select__option--is-selected {
            background: ${theme.color2};
        }

        .react-select__menu {
            border-radius: 0;
            margin: 2px 0;
            box-shadow: 4px 6px 10px rgba(0, 0, 0, .1);
        }

        .react-select__menu-list {
            padding: 0;
        }

        .react-select__control--is-focused {
            box-shadow: none;
        }

        .react-select__indicators {
            display: none;
        }
    `}
`;
