import axios from 'axios';
import cachios from 'cachios';
import constants from '../util/constants';

import { withAuthHeaders } from './index';

export default {
    add: async receipt => {
        return axios.post(`${constants.API_URL}/receipts`, receipt, withAuthHeaders());
    },
    edit: async editedReceipt => {
        return axios.put(`${constants.API_URL}/receipts`, editedReceipt, withAuthHeaders());
    },
    search: async opts => {
        return cachios.get(
            `${constants.API_URL}/receipts/search`,
            withAuthHeaders({
                params: opts
            })
        );
    },
    getAll: async () => {
        return cachios.get(`${constants.API_URL}/receipts`, withAuthHeaders());
    },
    delete: async receiptId => {
        return axios.delete(
            `${constants.API_URL}/receipts`,
            withAuthHeaders({
                params: {
                    receiptId
                }
            })
        );
    }
};
