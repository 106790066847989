import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { ModalHeader, ModalBody, ModalFooter } from '../styled/Modal/styles';
import { WarehouseFormContainer, WarehouseListContainer, WarehouseItem } from './styles';

import Button from '../styled/Button';
import Icon from '../styled/Icon';

import WarehouseForm from './WarehouseForm';

class WarehouseFormModal extends Component {
    render() {
        const { store } = this.props;

        return (
            <WarehouseFormContainer>
                <ModalHeader>Halda ladusid</ModalHeader>
                <ModalBody>
                    <WarehouseListContainer>
                        {store.warehouse.warehouses.map((wh, i) => {
                            return (
                                <WarehouseItem key={i}>
                                    <div>{wh.name}</div>
                                    <Icon
                                        onClick={() => store.warehouse.deleteWarehouse(wh.id)}
                                        tooltip="Kustuta ladu"
                                        type="trashcan"
                                        size="1.25rem"
                                    />
                                </WarehouseItem>
                            );
                        })}
                    </WarehouseListContainer>
                    <WarehouseForm id="newWarehouse" />
                </ModalBody>
                <ModalFooter borderless>
                    <Button
                        form="newWarehouse"
                        value="Lisa ladu"
                        isLoading={store.warehouse.newWarehouseForm.isLoading}
                    />
                </ModalFooter>
            </WarehouseFormContainer>
        );
    }
}

export default inject('store')(observer(WarehouseFormModal));
