import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { parseDate, parseCurrency, compare } from '../../util/functions';

import {
    MainViewContainer,
    MainViewHeader,
    HeaderText,
    SearchInputContainer,
    SearchInput,
    MainViewFooter
} from '../styled/MainView';

import Icon from '../styled/Icon';
import Table from '../styled/Table';
import Button from '../styled/Button';
import Confirmation from '../styled/Confirmation';

import ExpenseForm from '../ExpenseForm';
import ExpenseOverview from '../ExpenseOverview';
import ExpenseEditForm from '../ExpenseForm/ExpenseEditForm';

class Expenses extends Component {
    state = {
        searchQuery: '',
        searchResults: []
    };

    componentDidMount() {
        const { ui, expenses } = this.props.store;
        expenses.fetchAllExpenses();
        ui.setActiveView(ui.routes.expenses);
    }

    searchExpenses = async searchQuery => {
        const { app } = this.props.store;

        const expenses = await app.api.expenses.search({
            invoiceNr: searchQuery,
            partnerName: searchQuery
        });

        this.setState(() => ({ searchResults: expenses.data, searchQuery }));
    };

    render() {
        const { store } = this.props;
        const { searchQuery, searchResults } = this.state;

        const DeleteConfirmation = ({ row }) => (
            <Confirmation
                message="Kas oled kindel, et soovid kulu kustutada?"
                description='Kui kuluga on seotud ostuarve, siis läheb arve "Maksmata" seisundisse tagasi.'
                onConfirm={() => store.expenses.deleteExpense(row.id)}
            />
        );

        const columns = [
            {
                width: '2rem',
                value: row => (
                    <Icon
                        onClick={() => store.ui.showModal(<ExpenseOverview expense={row} />)}
                        tooltip="Vaata"
                        type="eye"
                        size="1.25rem"
                    />
                ),
                header: ''
            },
            {
                width: '10rem',
                value: row => (row.purchase ? row.purchase.invoiceNr : '-'),
                header: 'Ostuarve nr',
                attr: 'purchase.invoiceNr'
            },
            {
                width: '',
                value: row => (row.purchase ? row.purchase.partner.name : '-'),
                header: 'Tarnija',
                attr: 'purchase.partner.name',
                bold: true
            },
            {
                width: '7.5rem',
                value: row => parseDate(row.date),
                header: 'Kuupäev',
                attr: 'date'
            },
            {
                width: '6rem',
                value: row => parseCurrency(row.sum),
                header: 'Summa',
                attr: 'sum'
            },
            {
                width: '2rem',
                value: row => (
                    <Icon
                        onClick={() => store.ui.showModal(<DeleteConfirmation row={row} />)}
                        tooltip="Kustuta"
                        type="trashcan"
                        size="1.25rem"
                    />
                ),
                header: ''
            },
            {
                width: '2rem',
                value: row => (
                    <Icon
                        onClick={() => store.ui.showModal(<ExpenseEditForm expense={row} />)}
                        tooltip="Muuda"
                        type="edit"
                        size="1.25rem"
                    />
                ),
                header: ''
            }
        ];

        return (
            <MainViewContainer>
                <MainViewHeader>
                    <HeaderText>Kulud</HeaderText>
                    <SearchInputContainer>
                        <SearchInput
                            placeholder="Otsi arvet"
                            type="text"
                            value={searchQuery}
                            onChange={query => this.searchExpenses(query)}
                        />
                        <Icon type="search" size="1.5rem" />
                    </SearchInputContainer>
                </MainViewHeader>
                <Table
                    columns={columns}
                    data={searchQuery ? searchResults : store.expenses.expenses}
                    sortFunction={(lhs, rhs) => compare(lhs, rhs, 'date', -1)}
                />
                <MainViewFooter>
                    <Button
                        value="+ Uus kulu"
                        onClick={() => store.ui.showModal(<ExpenseForm />)}
                    />
                </MainViewFooter>
            </MainViewContainer>
        );
    }
}

export default inject('store')(observer(Expenses));
