import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { PurchaseFormContainer } from './styles';

import InputGroup from '../styled/InputGroup';
import Input from '../styled/Input';
import AddButton from '../styled/AddButton';

import PartnerForm from '../PartnerForm';

class PurchaseForm extends Component {
    render() {
        const { id, store } = this.props;

        const { newPurchaseForm } = store.purchases;

        const AddPartnerButton = (
            <AddButton
                tooltip="Lisa uus partner"
                type="button"
                value="+"
                size="2rem"
                onClick={() => store.ui.showModal(<PartnerForm />)}
            />
        );

        const vendors = store.partners.partners.filter(partner => partner.partnerType.id === 2);

        return (
            <PurchaseFormContainer id={id} onSubmit={newPurchaseForm.submit}>
                <InputGroup actionComponent={AddPartnerButton}>
                    <Input
                        required
                        initiallyEmpty
                        type="select"
                        label="Tarnija"
                        name="partner"
                        errors={newPurchaseForm.errors.partner}
                        parseValue={value => ({
                            label: value.name,
                            value
                        })}
                        value={newPurchaseForm.values.partner}
                        onChange={newPurchaseForm.handleChange}
                        options={vendors}
                    />
                </InputGroup>
                <InputGroup>
                    <Input
                        required
                        name="invoiceNr"
                        errors={newPurchaseForm.errors.invoiceNr}
                        onChange={newPurchaseForm.handleChange}
                        value={newPurchaseForm.values.invoiceNr}
                        label="Arve nr"
                    />
                    <Input
                        type="file"
                        label="Arve (PDF)"
                        onChange={newPurchaseForm.handleChange}
                        value={newPurchaseForm.values.invoiceFile}
                        errors={newPurchaseForm.errors.invoiceFile}
                        name="invoiceFile"
                    />
                </InputGroup>
                <InputGroup>
                    <Input
                        type="date"
                        required
                        label="Ostukuupäev"
                        errors={newPurchaseForm.errors.purchaseDate}
                        onChange={newPurchaseForm.handleChange}
                        value={newPurchaseForm.values.purchaseDate}
                        name="purchaseDate"
                    />
                    <Input
                        required
                        type="date"
                        label="Maksetähtaeg"
                        errors={newPurchaseForm.errors.dueDate}
                        onChange={newPurchaseForm.handleChange}
                        value={newPurchaseForm.values.dueDate}
                        name="dueDate"
                    />
                </InputGroup>
                <Input
                    type="textarea"
                    label="Märkused"
                    errors={newPurchaseForm.errors.description}
                    onChange={newPurchaseForm.handleChange}
                    value={newPurchaseForm.values.description}
                    name="description"
                />
            </PurchaseFormContainer>
        );
    }
}

export default inject('store')(observer(PurchaseForm));
