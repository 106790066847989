import styled from 'styled-components';

export const IconContainer = styled.div`
    ${({ size }) => `
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: ${size};
            height: ${size};
        }
    `}
`;
