import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import api from '../../api';

import Input from '../styled/Input';

class ExpenseForm extends Component {
    state = {
        unpaidPurchases: []
    };

    async componentDidMount() {
        const { purchase } = this.props;

        const unpaidPurchases = await api.purchases.search({
            isPaid: false
        });

        this.setState(() => ({ unpaidPurchases: unpaidPurchases.data }));

        if (purchase) this.handlePurchaseSelect(purchase);
    }

    handlePurchaseSelect = purchase => {
        const { newExpenseForm } = this.props.store.expenses;
        newExpenseForm.setValues({ purchase, sum: purchase ? purchase.sum : '' });
    };

    render() {
        const { id, store } = this.props;
        const { unpaidPurchases } = this.state;

        const { newExpenseForm } = store.expenses;

        return (
            <form id={id} onSubmit={newExpenseForm.submit}>
                <Input
                    required
                    type="select"
                    name="purchase"
                    options={unpaidPurchases}
                    label="Ostuarve"
                    parseValue={purchase => ({ value: purchase, label: purchase.invoiceNr })}
                    onChange={this.handlePurchaseSelect}
                    value={newExpenseForm.values.purchase}
                    errors={newExpenseForm.errors.purchase}
                />
                <Input
                    required
                    type="date"
                    name="date"
                    label="Kuupäev"
                    value={newExpenseForm.values.date}
                    errors={newExpenseForm.errors.date}
                    onChange={newExpenseForm.handleChange}
                />
                <Input
                    required
                    type="textarea"
                    name="description"
                    label="Märkused"
                    value={newExpenseForm.values.description}
                    errors={newExpenseForm.errors.description}
                    onChange={newExpenseForm.handleChange}
                />
            </form>
        );
    }
}

export default inject('store')(observer(ExpenseForm));
