import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { ItemFormContainer } from './styles';

import InputGroup from '../../styled/InputGroup';
import Input from '../../styled/Input';
import Button from '../../styled/Button';
import AddButton from '../../styled/AddButton';

import UnitForm from '../../UnitForm';
import WarehouseForm from '../../WarehouseForm';

class GoodsForm extends Component {
    handleSuggestionSelect = (e, { suggestion }) => {
        const { purchases } = this.props.store;
        purchases.goodsForm.setValues(suggestion);
    };

    render() {
        const { store } = this.props;

        const { goodsForm } = store.purchases;

        const AddUnitButton = (
            <AddButton
                tooltip="Lisa uus ühik"
                type="button"
                value="+"
                size="2rem"
                onClick={() => store.ui.showModal(<UnitForm />)}
            />
        );

        const AddWarehouseButton = (
            <AddButton
                tooltip="Lisa uus ladu"
                type="button"
                value="+"
                size="2rem"
                onClick={() => store.ui.showModal(<WarehouseForm />)}
            />
        );

        return (
            <ItemFormContainer onSubmit={goodsForm.submit}>
                <Input
                    required
                    type="autosuggest"
                    getSuggestionValue={item => item.name}
                    fetchSuggestions={value =>
                        store.app.api.items.search({
                            name: value,
                            itemType: { id: 1 }
                        })
                    }
                    onSuggestionSelected={this.handleSuggestionSelect}
                    value={goodsForm.values.name}
                    errors={goodsForm.errors.name}
                    name="name"
                    onChange={goodsForm.handleChange}
                    label="Nimetus"
                />
                <Input
                    required
                    type="autosuggest"
                    getSuggestionValue={item => `${item.code} - (${item.name})`}
                    fetchSuggestions={value =>
                        store.app.api.items.search({
                            code: value,
                            itemType: { id: 1 }
                        })
                    }
                    onSuggestionSelected={this.handleSuggestionSelect}
                    errors={goodsForm.errors.code}
                    value={goodsForm.values.code}
                    name="code"
                    onChange={goodsForm.handleChange}
                    label="Kood"
                />
                <InputGroup flex={[2, 1]} actionComponent={AddUnitButton}>
                    <Input
                        required
                        value={goodsForm.values.quantity}
                        errors={goodsForm.errors.quantity}
                        type="number"
                        name="quantity"
                        onChange={goodsForm.handleChange}
                        label="Kogus"
                    />
                    <Input
                        required
                        options={store.common.units}
                        value={goodsForm.values.unit}
                        parseValue={value => ({ label: value.abbr, value })}
                        errors={goodsForm.errors.unit}
                        type="select"
                        name="unit"
                        onChange={goodsForm.handleChange}
                        label="Ühik"
                    />
                </InputGroup>
                <InputGroup actionComponent={AddWarehouseButton}>
                    <Input
                        required
                        options={store.warehouse.warehouses}
                        type="select"
                        name="warehouse"
                        errors={goodsForm.errors.warehouse}
                        value={goodsForm.values.warehouse}
                        parseValue={value => ({ label: value.name, value })}
                        onChange={goodsForm.handleChange}
                        label="Ladu"
                    />
                </InputGroup>
                <Input
                    required
                    value={goodsForm.values.purchasePrice}
                    errors={goodsForm.errors.purchasePrice}
                    type="number"
                    step="0.01"
                    name="purchasePrice"
                    onChange={goodsForm.handleChange}
                    label="Ostuhind"
                />
                <Input
                    value={goodsForm.values.retailPrice}
                    errors={goodsForm.errors.retailPrice}
                    type="number"
                    step="0.01"
                    name="retailPrice"
                    onChange={goodsForm.handleChange}
                    label="Müügihind"
                />
                <Button value="Lisa kaup" />
            </ItemFormContainer>
        );
    }
}

export default inject('store')(observer(GoodsForm));
