import * as yup from 'yup';

export default {
    unit: {
        new: {
            name: {
                value: '',
                schema: yup
                    .string()
                    .trim()
                    .required('Palun sisesta ühiku nimetus.')
            },
            abbr: {
                value: '',
                schema: yup
                    .string()
                    .trim()
                    .required('Palun sisesta ühiku lühend.')
            }
        }
    },
    editGeneralSettings: {
        vatPercentage: {
            value: 0,
            schema: yup
                .number()
                .typeError('Käibemaksumäär peab olema number.')
                .min(0, 'Käibemaksumäär peab olema 0 või suurem.')
                .required()
        },
        paymentPeriod: {
            value: 0,
            schema: yup
                .number()
                .typeError('Makseperiood peab olema number.')
                .min(0, 'Maksuperiood peab olema 0 või suurem.')
                .required()
        },
        overdueCharge: {
            value: 0,
            schema: yup
                .number()
                .typeError('Viivis peab olema number.')
                .min(0, 'Viivis peab olema 0 või suurem.')
                .required()
        }
    },
    editProfile: {
        name: {
            value: '',
            schema: yup
                .string()
                .trim()
                .required('Palun sisesta oma ettevõtte nimi.')
        },
        registryNr: {
            value: '',
            schema: yup.string().nullable()
        },
        phoneNr: {
            value: '',
            schema: yup.string().nullable()
        },
        email: {
            value: '',
            schema: yup.string().nullable()
        },
        address: {
            value: '',
            schema: yup.string().nullable()
        },
        postalCode: {
            value: '',
            schema: yup.string().nullable()
        },
        country: {
            value: '',
            schema: yup.string().nullable()
        },
        county: {
            value: '',
            schema: yup.string().nullable()
        }
    },
    addBankAccount: {
        bankName: {
            value: '',
            schema: yup.string().required('Palun sisesta panga nimetus.')
        },
        accountNumber: {
            value: '',
            schema: yup.string().required('Palun sisesta konto number.')
        }
    }
};
