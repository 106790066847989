import * as yup from 'yup';

const newPartner = {
    name: {
        value: '',
        schema: yup
            .string()
            .trim()
            .required('Palun sisesta partneri nimi.')
    },
    partnerType: {
        value: null,
        schema: yup
            .object()
            .shape({
                id: yup.number().required()
            })
            .required('Palun vali partneri tüüp.')
    },
    registryNr: {
        value: '',
        schema: yup.string()
    },
    vatId: {
        value: '',
        schema: yup.string()
    },
    phoneNr: {
        value: '',
        schema: yup.string()
    },
    email: {
        value: '',
        schema: yup.string()
    },
    address: {
        value: '',
        schema: yup.string()
    },
    postalCode: {
        value: '',
        schema: yup.string()
    },
    country: {
        value: '',
        schema: yup.string()
    },
    county: {
        value: '',
        schema: yup.string()
    }
};

const editPartner = {
    ...newPartner,
    id: {
        value: null,
        schema: yup.number().required()
    }
};

export default {
    new: newPartner,
    edit: editPartner
};
