import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import GoogleLogin from 'react-google-login';

import { LoginContainer } from './styles';

class Login extends Component {
    facebookCallback = res => {
        const { store } = this.props;
        store.auth.loginWithFacebook(res.accessToken);
    };

    googleCallback = res => {
        const { store } = this.props;
        store.auth.loginWithGoogle(res.accessToken);
    };

    render() {
        return (
            <LoginContainer>
                {/* <FacebookLogin
                    appId="546424282432692"
                    callback={this.facebookCallback}
                    cssClass="my-facebook-button-class"
                    icon="fa-facebook"
                /> */}
                <GoogleLogin
                    clientId="381862758463-qunrqhar3sft3a0n96kda054hl41tqbc.apps.googleusercontent.com"
                    buttonText="Logi sisse Google'i kaudu"
                    onSuccess={this.googleCallback}
                />
            </LoginContainer>
        );
    }
}

export default inject('store')(observer(Login));
