import { decorate, flow, action, observable } from 'mobx';
import Store from './lib/Store';

class AppStore extends Store {
    isInitialized = false;

    initialize = flow(function*() {
        try {
            yield Promise.all([
                this.stores.warehouse.fetchAll(),
                this.stores.common.fetchCommon(),
                this.stores.partners.fetchAllPartners()
            ]);
            this.isInitialized = true;
        } catch (err) {
            console.error(err);
        }
    });
}

export default decorate(AppStore, {
    initialize: action.bound,
    isInitialized: observable
});
