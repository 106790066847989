import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { SettingsContentContainer, SettingsTitle, SettingsFormContainer } from '../styles';

import Input from '../../styled/Input';
import Button from '../../styled/Button';

class GeneralSettings extends Component {
    componentDidMount() {
        const { ui, common } = this.props.store;
        common.generalSettingsForm.setValues(common.generalSettings);
        ui.setActiveView(ui.routes.generalSettings);
    }

    render() {
        const { generalSettingsForm } = this.props.store.common;

        return (
            <SettingsContentContainer>
                <SettingsTitle>Üldine</SettingsTitle>
                <SettingsFormContainer onSubmit={generalSettingsForm.submit}>
                    <Input
                        required
                        name="vatPercentage"
                        type="number"
                        onChange={generalSettingsForm.handleChange}
                        value={generalSettingsForm.values.vatPercentage}
                        label="Käibemaksumäär (%)"
                    />
                    <Input
                        required
                        name="paymentPeriod"
                        value={generalSettingsForm.values.paymentPeriod}
                        onChange={generalSettingsForm.handleChange}
                        type="number"
                        label="Müügiarve makseperiood (päevades)"
                    />
                    <Input
                        required
                        type="number"
                        name="overdueCharge"
                        value={generalSettingsForm.values.overdueCharge}
                        onChange={generalSettingsForm.handleChange}
                        label="Müügiarve viivis (% päevas)"
                    />
                    <Button value="Salvesta" isLoading={generalSettingsForm.isLoading} />
                </SettingsFormContainer>
            </SettingsContentContainer>
        );
    }
}

export default inject('store')(observer(GeneralSettings));
