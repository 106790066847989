import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Route } from 'react-router-dom';

import { MainViewContainer, MainViewHeader, HeaderText } from '../styled/MainView';
import { SettingsContainer, SettingsWrapper } from './styles';

import SettingsMenu from './SettingsMenu';
import GeneralSettings from './GeneralSettings';
import ProfileSettings from './ProfileSettings';
import BankSettings from './BankSettings';

class Settings extends Component {
    componentDidMount() {
        const { ui } = this.props.store;
        ui.setActiveView(ui.routes.generalSettings);
    }

    render() {
        const { store } = this.props;

        return (
            <MainViewContainer>
                <MainViewHeader>
                    <HeaderText>Sätted</HeaderText>
                </MainViewHeader>
                <SettingsWrapper>
                    <SettingsMenu />
                    <SettingsContainer>
                        <div className="settings-wrapper">
                            <Route
                                path={store.ui.routes.generalSettings}
                                component={GeneralSettings}
                            />
                            <Route
                                path={store.ui.routes.profileSettings}
                                component={ProfileSettings}
                            />
                            <Route path={store.ui.routes.bankSettings} component={BankSettings} />
                        </div>
                    </SettingsContainer>
                </SettingsWrapper>
            </MainViewContainer>
        );
    }
}

export default inject('store')(observer(Settings));
