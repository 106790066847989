import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { parseCurrency, parseDate, compare } from '../../util/functions';

import {
    MainViewContainer,
    MainViewHeader,
    HeaderText,
    SearchInputContainer,
    SearchInput,
    MainViewFooter
} from '../styled/MainView';

import Icon from '../styled/Icon';
import Table from '../styled/Table';
import Button from '../styled/Button';
import Confirmation from '../styled/Confirmation';

import ReceiptForm from '../ReceiptForm';
import ReceiptEditForm from '../ReceiptForm/ReceiptEditForm';
import ReceiptOverview from '../ReceiptOverview';

class ReceiptTable extends Component {
    state = {
        searchQuery: '',
        searchResults: []
    };

    componentDidMount() {
        const { ui, receipts } = this.props.store;
        receipts.fetchAllReceipts();
        ui.setActiveView(ui.routes.receipts);
    }

    searchReceipts = async searchQuery => {
        const { app } = this.props.store;

        const receipts = await app.api.receipts.search({
            invoiceNr: searchQuery,
            partnerName: searchQuery
        });

        this.setState(() => ({ searchResults: receipts.data, searchQuery }));
    };

    render() {
        const { store } = this.props;
        const { searchQuery, searchResults } = this.state;

        const DeleteConfirmation = ({ row }) => (
            <Confirmation
                message="Kas oled kindel, et soovid laekumise kustutada?"
                description="Laekumise summa lisatakse müügiarve maksmata summale tagasi."
                onConfirm={() => store.receipts.deleteReceipt(row.id)}
            />
        );

        const columns = [
            {
                width: '2rem',
                value: row => (
                    <Icon
                        type="eye"
                        size="1.25rem"
                        tooltip="Vaata"
                        onClick={() => store.ui.showModal(<ReceiptOverview receipt={row} />)}
                    />
                )
            },
            {
                width: '10rem',
                value: row => row.sale.invoiceNr,
                header: 'Arve nr',
                attr: 'sale.invoiceNr'
            },
            {
                width: '',
                value: row => row.sale.partner.name,
                header: 'Klient',
                bold: true,
                attr: 'partner.name'
            },
            {
                width: '7.5rem',
                value: row => parseDate(row.date),
                header: 'Kuupäev',
                attr: 'date'
            },
            { width: '6rem', value: row => parseCurrency(row.sum), header: 'Summa', attr: 'sum' },
            {
                width: '2rem',
                value: row => (
                    <Icon
                        type="trashcan"
                        size="1.25rem"
                        tooltip="Kustuta"
                        onClick={() => store.ui.showModal(<DeleteConfirmation row={row} />)}
                    />
                )
            },
            {
                width: '2rem',
                value: row => (
                    <Icon
                        type="edit"
                        size="1.25rem"
                        tooltip="Muuda"
                        onClick={() => store.ui.showModal(<ReceiptEditForm receipt={row} />)}
                    />
                )
            }
        ];

        return (
            <MainViewContainer>
                <MainViewHeader>
                    <HeaderText>Laekumised</HeaderText>
                    <SearchInputContainer>
                        <SearchInput
                            placeholder="Otsi laekumist"
                            type="text"
                            value={searchQuery}
                            onChange={query => this.searchReceipts(query)}
                        />
                        <Icon type="search" size="1.5rem" />
                    </SearchInputContainer>
                </MainViewHeader>
                <Table
                    columns={columns}
                    data={searchQuery ? searchResults : store.receipts.receipts}
                    sortFunction={(lhs, rhs) => compare(lhs, rhs, 'date', -1)}
                />
                <MainViewFooter>
                    <Button
                        value="+ Uus laekumine"
                        onClick={() => store.ui.showModal(<ReceiptForm />)}
                    />
                </MainViewFooter>
            </MainViewContainer>
        );
    }
}

export default inject('store')(observer(ReceiptTable));
