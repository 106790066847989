import styled from 'styled-components';

export const SettingsFormContainer = styled.form`
    padding-top: 0.5rem;
`;

export const SettingsWrapper = styled.div`
    display: flex;
    flex: 1;
    margin-bottom: 3rem;
`;

export const SettingsContainer = styled.div`
    ${({ theme }) => `
        flex: 3;
        overflow: auto;
        border: 1px dashed ${theme.greyF0};
        border-width: 1px 1px 1px 0;

        .settings-wrapper {
            overflow: auto;
            height: 100%;
        }
    `}
`;

export const SettingsTitle = styled.div`
    ${({ theme }) => `
        font-size: 1.25rem;
        font-weight: 600;
        border-bottom: 1px solid ${theme.greyF0};
        color: ${theme.grey33};
        padding-bottom: .25rem;
        margin-top: 2rem;
    `}
`;

export const SettingsContentContainer = styled.div`
    padding: 0 4rem;
`;
