import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { SaleItemFormContainer, ItemFormWrapper, ItemFormWrapperWrapper } from './styles';
import { FormHeader } from '../styles';

import Input from '../../styled/Input';
import GoodsForm from './GoodsForm';
import ServiceForm from './ServiceForm';
import ExpenseForm from './ExpenseForm';

class ItemForm extends Component {
    state = {
        itemType: {}
    };

    handleTypeChange = itemType => {
        this.setState({ itemType });
    };

    render() {
        const { store } = this.props;

        const { itemType } = this.state;

        return (
            <SaleItemFormContainer>
                <FormHeader>Lisa kaup</FormHeader>
                <ItemFormWrapperWrapper>
                    <ItemFormWrapper>
                        <Input
                            required
                            onChange={this.handleTypeChange}
                            name="itemType"
                            options={store.common.itemTypes}
                            value={itemType}
                            parseValue={value => ({ label: value.name, value })}
                            type="select"
                            label="Kauba tüüp"
                        />
                        {itemType.code === 'INVENTORY' && <GoodsForm />}
                        {itemType.code === 'SERVICE' && <ServiceForm />}
                        {itemType.code === 'EXPENSE' && <ExpenseForm />}
                    </ItemFormWrapper>
                </ItemFormWrapperWrapper>
            </SaleItemFormContainer>
        );
    }
}

export default inject('store')(observer(ItemForm));
