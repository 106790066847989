import styled from 'styled-components';
import Button from '../styled/Button';

export const PurchaseFormModalContainer = styled.div`
    width: 50rem;
`;

export const PurchaseFormContainer = styled.form`
    width: 100%;
    height: 100%;
`;

export const Divider = styled.div`
    ${({ theme }) => `
        background: ${theme.greyF0};
        height: 1px;
        margin-top: 1rem;
        width: 100%;
    `}
`;

export const FormHeader = styled.div`
    ${({ theme }) => `
        font-weight: 600;
        font-size: 1.25rem;
        color: ${theme.color1};
        padding-bottom: .5rem;
    `}
`;

export const AddButtonStyled = styled(Button)`
    ${({ theme }) => `
        background: transparent;
        color: ${theme.color1};
        border: 1px dashed ${theme.color1};
        box-shadow: none;

        :hover, :focus {
            background: ${theme.greyF5};
        }
    `}
`;

export const ItemTableFormContainer = styled.div``;

export const ItemSumContainer = styled.div`
    text-align: right;
    margin-top: 0.5rem;
`;

export const ItemsContainer = styled.div`
    margin-top: 1rem;
    display: flex;
    height: 22rem;
`;

export const ItemSection = styled.div`
    flex: 1;
`;

export const ItemListContainer = styled.div`
    flex: 1;
`;
