import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { ModalHeader, ModalBody, ModalFooter } from '../styled/Modal/styles';
import { ErrorContainer } from '../styled/Error';
import { WarehouseItemFormModalContainer } from './styles';

import Button from '../styled/Button';

import WarehouseItemForm from './WarehouseItemForm';

class WarehouseItemEditFormModal extends Component {
    componentDidMount() {
        const { item, store } = this.props;
        const { editItemForm } = store.warehouse;
        editItemForm.setValues(item);
    }

    componentWillUnmount() {
        const { editItemForm } = this.props.store.warehouse;
        editItemForm.reset();
    }

    render() {
        const { store } = this.props;
        const { editItemForm } = store.warehouse;
        const { generalErrorMessage } = editItemForm;

        return (
            <WarehouseItemFormModalContainer>
                <ModalHeader>Muuda kaupa</ModalHeader>
                <ModalBody>
                    <WarehouseItemForm id="editItem" form={editItemForm} />
                </ModalBody>
                <ModalFooter borderless>
                    {generalErrorMessage && <ErrorContainer>{generalErrorMessage}</ErrorContainer>}
                    <Button form="editItem" value="Muuda kaup" isLoading={editItemForm.isLoading} />
                </ModalFooter>
            </WarehouseItemFormModalContainer>
        );
    }
}

export default inject('store')(observer(WarehouseItemEditFormModal));
