import styled from 'styled-components';
import 'react-day-picker/lib/style.css';

import { InputContainer } from '../styles';

export const CheckboxContainer = styled(InputContainer)`
    margin: 0;
    border-bottom: none;
`;

export const LabelStyled = styled.label``;

export const CheckboxStyled = styled.button`
    ${({ theme, checked }) => `
        transition: all .2s;
        margin-right: .5rem;
        background: ${checked ? theme.color1 : theme.greyD0};
        box-shadow: inset 0 0 10px ${theme.black(0.25)};
        border: none;
        border-radius: 2px;
        height: 1rem;
        width: 1rem;
        cursor: pointer;
        outline: none;
    `}
`;
