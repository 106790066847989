import React, { Component } from 'react';
import { Switch, Router } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { ThemeProvider } from 'styled-components';

import history from '../../util/history';

import { AppContainer } from './styles';

import ProtectedRoute from '../ProtectedRoute';
import Login from '../Login';
import Main from '../Main';
import LoadingScreen from '../LoadingScreen';

class App extends Component {
    componentDidMount() {
        this.props.store.auth.verifyToken();
    }

    render() {
        const { store } = this.props;

        return (
            <ThemeProvider theme={store.ui.theme}>
                <Router history={history}>
                    <AppContainer>
                        {!store.auth.isVerifying ? (
                            <Switch>
                                <ProtectedRoute
                                    show={!store.auth.user}
                                    redirect={store.ui.routes.warehouse}
                                    path={store.ui.routes.login}
                                    component={Login}
                                />
                                <ProtectedRoute
                                    show={store.auth.user}
                                    redirect={store.ui.routes.login}
                                    path={store.ui.routes.main}
                                    component={Main}
                                />
                            </Switch>
                        ) : (
                            <LoadingScreen />
                        )}
                    </AppContainer>
                </Router>
            </ThemeProvider>
        );
    }
}

export default inject('store')(observer(App));
