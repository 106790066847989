import { decorate, action, observable, flow } from 'mobx';

import Store from './lib/Store';
import Form from './lib/Form';

import forms from '../util/forms';

class CommonStore extends Store {
    units = [];

    itemTypes = [];

    partnerTypes = [];

    generalSettings = {};

    bankAccounts = [];

    fetchCommon = flow(function*() {
        try {
            const common = yield this.api.common.get();

            this.units = common.data.units;
            this.itemTypes = common.data.itemTypes;
            this.generalSettings = common.data.settings;
            this.bankAccounts = common.data.bankAccounts;
            this.partnerTypes = common.data.partnerTypes;
        } catch (err) {
            console.error(err);
        }
    });

    addUnit = flow(function*(newUnit) {
        try {
            const unit = yield this.api.common.addUnit(newUnit);

            this.units.push(unit.data);
            this.stores.ui.closeModal();
            this.newUnitForm.reset();
        } catch (err) {
            console.error(err);
        }
    });

    editGeneralSettings = flow(function*(newSettings) {
        try {
            const settings = yield this.api.common.editGeneralSettings(newSettings);

            this.generalSettings = settings.data;
        } catch (err) {
            console.error(err);
        }
    });

    editProfile = flow(function*(newProfile) {
        try {
            const profile = yield this.api.common.editProfile(newProfile);

            this.stores.auth.user = { ...this.stores.auth.user, ...profile.data };
        } catch (err) {
            console.error(err);
        }
    });

    addBankAccount = flow(function*(newBankAccount) {
        try {
            const bankAccount = yield this.api.common.addBankAccount(newBankAccount);

            this.bankAccounts.push(bankAccount.data);
            this.bankAccountForm.reset();
        } catch (err) {
            console.error(err);
        }
    });

    deleteBankAccount = flow(function*(bankAccToDelete, index) {
        try {
            yield this.api.common.deleteBankAccount(bankAccToDelete);

            this.bankAccounts.splice(index, 1);
            return true;
        } catch (err) {
            console.error(err);
            throw err;
        }
    });

    newUnitForm = new Form({
        fields: forms.common.unit.new,
        onSubmit: this.addUnit
    });

    generalSettingsForm = new Form({
        fields: forms.common.editGeneralSettings,
        onSubmit: this.editGeneralSettings
    });

    profileForm = new Form({
        fields: forms.common.editProfile,
        onSubmit: this.editProfile
    });

    bankAccountForm = new Form({
        fields: forms.common.addBankAccount,
        onSubmit: this.addBankAccount
    });
}

export default decorate(CommonStore, {
    units: observable,
    newUnitForm: observable,
    itemTypes: observable,
    bankAccounts: observable,
    fetchCommon: action.bound,
    addUnit: action.bound,
    editGeneralSettings: action.bound,
    editProfile: action.bound,
    deleteBankAccount: action.bound,
    addBankAccount: action.bound,
    generalSettingsForm: observable,
    profileForm: observable,
    bankAccountForm: observable
});
