import React, { Component } from 'react';

import { TextareaStyled, TextareaContainer, LabelStyled } from './styles';

class Textarea extends Component {
    handleChange = e => {
        const { onChange, name } = this.props;
        const { value } = e.target;
        if (onChange) onChange(value, name);
    };

    render() {
        const { label, value, type, onChange, name, required, disabled, ...restProps } = this.props;

        return (
            <TextareaContainer disabled={disabled}>
                <TextareaStyled
                    value={value != null ? value : ''}
                    autoComplete="off"
                    type={type || 'text'}
                    onChange={this.handleChange}
                    disabled={disabled}
                    {...restProps}
                />
                <LabelStyled>{label}</LabelStyled>
            </TextareaContainer>
        );
    }
}

export default Textarea;
