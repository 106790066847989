import styled from 'styled-components';
import Tooltip from 'react-tooltip-lite';

export const TooltipStyled = styled(Tooltip)`
    ${({ theme, margin }) => `
        margin: ${margin || 0};
        
        display: flex;
        align-items: center;

        .react-tooltip-lite {
            font-size: .9rem;
            background: ${theme.grey33};
            color: ${theme.white(1)};
            border-radius: 2px;
            padding: .25rem .5rem !important;
        }

        .react-tooltip-lite-arrow {
            border-color: ${theme.grey33};
        }
    `}
`;
