import styled, { css } from 'styled-components';
import { inputText, InputContainer } from '../styles';

export const StaticInputContainer = styled(InputContainer)`
    color: red; /* Need this for some reason or next styles don't apply */
    min-height: 2rem;
    height: auto !important;
`;

export const StaticInputStyled = styled.div`
    ${({ onClick }) => `
        height: 100%;
        width: 100%;
        border: none;
        z-index: 1;
        padding: 0.25rem 0;
        background: transparent;
        outline: none;
        display: flex;
        align-items: center;
        white-space: pre-wrap;
        ${inputText}
        ${onClick &&
            css`
                cursor: pointer;
            `}
    `}
`;
