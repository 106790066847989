import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { parseDate, parseCurrency, compare } from '../../util/functions';

import {
    MainViewContainer,
    MainViewHeader,
    HeaderText,
    SearchInputContainer,
    SearchInput,
    MainViewFooter
} from '../styled/MainView';

import IncomeOverview from '../IncomeOverview';

import Icon from '../styled/Icon';
import Table from '../styled/Table';
import Confirmation from '../styled/Confirmation';
import IncomeEditForm from '../IncomeForm/IncomeEditForm';

class IncomeTable extends Component {
    state = {
        searchQuery: '',
        searchResults: []
    };

    componentDidMount() {
        const { ui, incomes } = this.props.store;
        incomes.fetchAllIncomes();
        ui.setActiveView(ui.routes.incomes);
    }

    searchIncomes = async searchQuery => {
        const { app } = this.props.store;

        const incomes = await app.api.incomes.search({
            invoiceNr: searchQuery,
            partnerName: searchQuery
        });

        this.setState(() => ({ searchResults: incomes.data, searchQuery }));
    };

    render() {
        const { store } = this.props;
        const { searchQuery, searchResults } = this.state;

        const DeleteConfirmation = ({ row }) => (
            <Confirmation
                message="Kas oled kindel, et soovid tulu kustutada?"
                description={`Kui tuluga on seotud müügiarve, 
                    siis kustutatakse kõik arvega seotud laekumised 
                    ja arve läheb "Maksmata" seisundisse tagasi.
                `}
                onConfirm={() => store.incomes.deleteIncome(row.id)}
            />
        );

        const columns = [
            {
                width: '2rem',
                value: row => (
                    <Icon
                        onClick={() => store.ui.showModal(<IncomeOverview income={row} />)}
                        tooltip="Vaata"
                        type="eye"
                        size="1.25rem"
                    />
                ),
                header: ''
            },
            {
                width: '10rem',
                value: row => row.sale.invoiceNr,
                header: 'Müügiarve nr',
                attr: 'sale.invoiceNr'
            },
            {
                width: '',
                value: row => row.sale.partner.name,
                header: 'Klient',
                attr: 'sale.partner.name',
                bold: true
            },
            {
                width: '7.5rem',
                value: row => parseDate(row.date),
                header: 'Kuupäev',
                attr: 'date'
            },
            {
                width: '6rem',
                value: row => parseCurrency(row.sum),
                header: 'Summa',
                attr: 'sum'
            },
            {
                width: '2rem',
                value: row => (
                    <Icon
                        onClick={() => store.ui.showModal(<DeleteConfirmation row={row} />)}
                        tooltip="Kustuta"
                        type="trashcan"
                        size="1.25rem"
                    />
                ),
                header: ''
            },
            {
                width: '2rem',
                value: row => (
                    <Icon
                        onClick={() => store.ui.showModal(<IncomeEditForm income={row} />)}
                        tooltip="Muuda"
                        type="edit"
                        size="1.25rem"
                    />
                ),
                header: ''
            }
        ];

        return (
            <MainViewContainer>
                <MainViewHeader>
                    <HeaderText>Tulud</HeaderText>
                    <SearchInputContainer>
                        <SearchInput
                            placeholder="Otsi arvet"
                            type="text"
                            value={searchQuery}
                            onChange={query => this.searchIncomes(query)}
                        />
                        <Icon type="search" size="1.5rem" />
                    </SearchInputContainer>
                </MainViewHeader>
                <Table
                    columns={columns}
                    data={searchQuery ? searchResults : store.incomes.incomes}
                    sortFunction={(lhs, rhs) => compare(lhs, rhs, 'date', -1)}
                />
                <MainViewFooter />
            </MainViewContainer>
        );
    }
}

export default inject('store')(observer(IncomeTable));
