import styled from 'styled-components';

export const IsPaidStyled = styled.a`
    ${({ isPaid, theme }) => `
        &, :hover {
            cursor: ${isPaid ? 'default' : 'pointer'};
            text-decoration: ${isPaid ? 'none' : 'underline'};
        }

        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 2px;
        margin: 0 .25rem;
        width: 100%;
        height: 100%;
        background: ${isPaid ? theme.success : theme.pending};
    `}
`;
