import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { ModalHeader, ModalBody, ModalFooter } from '../styled/Modal/styles';
import { ExpenseFormContainer } from './styles';

import Button from '../styled/Button';
import Input from '../styled/Input';

class IncomeEditFormModal extends Component {
    componentDidMount() {
        const { store, income } = this.props;
        const { editIncomeForm } = store.incomes;

        editIncomeForm.setValues(income);
    }

    componentWillUnmount() {
        const { editIncomeForm } = this.props.store.incomes;
        editIncomeForm.reset();
    }

    render() {
        const { editIncomeForm } = this.props.store.incomes;

        return (
            <ExpenseFormContainer>
                <ModalHeader>Muuda tulu</ModalHeader>
                <ModalBody>
                    <form id="editIncome" onSubmit={editIncomeForm.submit}>
                        <Input
                            required
                            type="date"
                            name="date"
                            label="Kuupäev"
                            value={editIncomeForm.values.date}
                            errors={editIncomeForm.errors.date}
                            onChange={editIncomeForm.handleChange}
                        />
                        <Input
                            required
                            type="textarea"
                            name="description"
                            label="Märkused"
                            value={editIncomeForm.values.description}
                            errors={editIncomeForm.errors.description}
                            onChange={editIncomeForm.handleChange}
                        />
                    </form>
                </ModalBody>
                <ModalFooter borderless>
                    <Button
                        form="editIncome"
                        value="Muuda tulu"
                        isLoading={editIncomeForm.isLoading}
                    />
                </ModalFooter>
            </ExpenseFormContainer>
        );
    }
}

export default inject('store')(observer(IncomeEditFormModal));
