import styled from 'styled-components';
import { liftedLabel } from '../styles';

export const LabelStyled = styled.label.attrs({
    className: 'input-label'
})`
    ${({ theme }) => `
        z-index: 0;
        position: absolute;
        height: 2rem;
        bottom: 0;
        right: 0;
        left: 0;
        height: 100%;
        color: ${theme.grey33};
    `}
`;

export const LabelContent = styled.div`
    ${({ theme, lifted, isMounted }) => `
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        transition: ${isMounted ? 'all .2s' : 'none'};

        span { color: ${theme.danger}; }

        ${lifted && liftedLabel}
    `}
`;
