import styled, { keyframes } from 'styled-components';

const spin = keyframes`
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
`;

export const LoadingContainer = styled.div`
    ${({ theme }) => `
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        width: 100%;
        background: ${theme.greyF0};

        svg {
            overflow: visible;
        }
    `}
`;

export const LoaderCircle = styled.circle`
    animation: ${spin} 1s linear infinite;
    ${({ theme }) => `
        fill: none;
        stroke: ${theme.color1};
        stroke-width: 2px;
        stroke-dasharray: 10px 15px;
        transform-origin: 50%;
    `}
`;
