import axios from 'axios';
import cachios from 'cachios';
import constants from '../util/constants';

import { withAuthHeaders } from './index';

export default {
    add: async item => {
        return axios.post(`${constants.API_URL}/items`, item, withAuthHeaders());
    },
    edit: async editedItem => {
        return axios.put(`${constants.API_URL}/items`, editedItem, withAuthHeaders());
    },
    getAll: async warehouseId => {
        return cachios.get(
            `${constants.API_URL}/items`,
            withAuthHeaders({
                params: {
                    warehouseId
                }
            })
        );
    },
    delete: async (itemId, warehouseId) => {
        return axios.delete(
            `${constants.API_URL}/items`,
            withAuthHeaders({
                params: {
                    itemId,
                    warehouseId
                }
            })
        );
    },
    search: async ({ warehouse = {}, itemType = {}, name, code }) => {
        return cachios.get(
            `${constants.API_URL}/items/search`,
            withAuthHeaders({
                params: {
                    code,
                    name,
                    itemTypeId: itemType.id,
                    warehouseId: warehouse.id
                }
            })
        );
    },
    addUnit: async unit => {
        return axios.post(`${constants.API_URL}/items/units`, unit, withAuthHeaders());
    },
    getAllUnits: async () => {
        return cachios.get(`${constants.API_URL}/items/units`, withAuthHeaders());
    },
    getTypes: async () => {
        return cachios.get(`${constants.API_URL}/items/types`, withAuthHeaders());
    }
};
