import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { parseCurrency } from '../../../util/functions';

import {
    ItemListContainer,
    ItemListWrapper,
    SumContainer,
    ItemListContainerWrapper
} from './styles';
import { FormHeader } from '../styles';

import InvoiceItem from '../../InvoiceItem';

class ItemList extends Component {
    render() {
        const { store } = this.props;

        const { newSaleForm } = store.sales;

        const sum = `${parseCurrency(
            newSaleForm.values.items.reduce((total, item) => {
                return total + item.retailPrice * item.quantity;
            }, 0)
        )}€`;

        return (
            <ItemListWrapper>
                <FormHeader>Kaubad</FormHeader>
                <ItemListContainerWrapper>
                    <ItemListContainer>
                        {newSaleForm.values.items.map((item, i) => (
                            <InvoiceItem
                                onDelete={() => store.sales.removeItemFromInvoice(i)}
                                item={{ ...item, price: item.retailPrice }}
                                editable
                                key={i}
                            />
                        ))}
                    </ItemListContainer>
                </ItemListContainerWrapper>
                <SumContainer>
                    Kokku:
                    <span className="sum">{sum}</span>
                </SumContainer>
            </ItemListWrapper>
        );
    }
}

export default inject('store')(observer(ItemList));
