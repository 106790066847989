export default {
    routes: {
        login: '/login',
        main: '/',
        warehouse: '/warehouse',
        purchases: '/purchases',
        sales: '/sales',
        receipts: '/receipts',
        expenses: '/expenses',
        incomes: '/incomes',
        partners: '/partners',
        statistics: '/statistics',
        profile: '/profile',
        settings: '/settings',
        generalSettings: '/settings/general',
        bankSettings: '/settings/banks',
        profileSettings: '/settings/profile'
    },
    theme: {
        white: opacity => `rgba(255, 255, 255, ${opacity})`,
        black: opacity => `rgba(0, 0, 0, ${opacity})`,
        color1: '#1E3587',
        color2: '#2D41AE',
        greyFA: '#FAFAFA',
        greyF5: '#F5F5F5',
        greyF0: '#F0F0F0',
        greyD0: '#D0D0D0',
        greyA0: '#A0A0A0',
        grey77: '#777',
        grey33: '#333',
        success: '#6DD22F',
        pending: '#FFD339',
        danger: '#D12E2E',
        breakpoint: '1167px'
    },
    API_URL: process.env.REACT_APP_API_URL
};
