import styled from 'styled-components';

export const ModalHeader = styled.div`
    ${({ theme }) => `
        font-size: 1.625rem;
        padding: .5rem 0;
        font-weight: 600;
        color: ${theme.color1};
        border-bottom: 1px solid ${theme.greyF0};
    `}
`;

export const ModalBody = styled.div``;

export const ModalFooter = styled.div`
    ${({ theme, borderless }) => `
        text-align: right;
        padding-bottom: 1.625rem;
        ${borderless ? '' : `border-top: 1px solid ${theme.greyF0};`}
    `}
`;
