import common from './common';
import items from './items';
import partners from './partners';
import purchases from './purchases';
import sales from './sales';
import warehouse from './warehouse';
import receipts from './receipts';
import profile from './profile';
import expenses from './expenses';
import incomes from './incomes';

export default {
    common,
    items,
    partners,
    purchases,
    sales,
    warehouse,
    receipts,
    profile,
    expenses,
    incomes
};
