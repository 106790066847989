import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { SettingsContentContainer, SettingsTitle, SettingsFormContainer } from '../styles';
import { BankAccountItem } from './styles';

import Icon from '../../styled/Icon';
import Input from '../../styled/Input';
import Button from '../../styled/Button';

class BankSettings extends Component {
    componentDidMount() {
        const { ui } = this.props.store;
        ui.setActiveView(ui.routes.bankSettings);
    }

    render() {
        const { bankAccountForm, bankAccounts, deleteBankAccount } = this.props.store.common;

        return (
            <SettingsContentContainer>
                <SettingsTitle>Lisa konto</SettingsTitle>
                <SettingsFormContainer onSubmit={bankAccountForm.submit}>
                    <Input
                        required
                        name="bankName"
                        onChange={bankAccountForm.handleChange}
                        errors={bankAccountForm.errors.bankName}
                        value={bankAccountForm.values.bankName}
                        label="Panga nimetus"
                    />
                    <Input
                        required
                        name="accountNumber"
                        onChange={bankAccountForm.handleChange}
                        errors={bankAccountForm.errors.accountNumber}
                        value={bankAccountForm.values.accountNumber}
                        label="Konto number"
                    />
                    <Button value="Lisa konto" isLoading={bankAccountForm.isLoading} />
                </SettingsFormContainer>
                <SettingsTitle>Pangakontod</SettingsTitle>
                {bankAccounts.map((account, i) => (
                    <BankAccountItem key={i}>
                        <div className="bank-name">{account.bankName}</div>
                        <div className="account-number">{account.accountNumber}</div>
                        <Icon
                            type="trashcan"
                            size="1.25rem"
                            tooltip="Kustuta konto"
                            onClick={() => deleteBankAccount(account, i)}
                        />
                    </BankAccountItem>
                ))}
            </SettingsContentContainer>
        );
    }
}

export default inject('store')(observer(BankSettings));
