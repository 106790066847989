import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

import { SidebarItemContainer } from './styles';

import Icon from '../../styled/Icon';

class SidebarItem extends Component {
    render() {
        const { value, icon, target, store } = this.props;

        return (
            <Link to={target}>
                <SidebarItemContainer isActive={store.ui.isActiveView(target)}>
                    <Icon size="2rem" type={icon} />
                    {value}
                </SidebarItemContainer>
            </Link>
        );
    }
}

export default inject('store')(observer(SidebarItem));
