import styled, { keyframes } from 'styled-components';

export const spin = keyframes`
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
`;

export const ButtonContainer = styled.button`
    ${({ margin, width, height, size, theme, padding }) => `
        ${width && `width: ${width};`}
        background: transparent;
        border: none;
        font-size: 1rem;
        font-weight: bold;
        background: ${theme.color1};
        color: ${theme.white(1)};
        width: ${size || width || 'auto'};
        height: ${size || height || 'auto'};
        padding: ${padding || '.5rem .75rem'};
        border-radius: .15rem;
        cursor: pointer;
        position: relative;
        box-shadow: 4px 6px 10px ${theme.black(0.1)};
        margin: ${margin || '1rem 0 0 0'};

        :hover, :focus {
            outline: none;
            background: ${theme.color2};
        }
    `}
`;

export const ButtonText = styled.div`
    ${({ isLoading }) => `
        visibility: ${isLoading ? 'hidden' : 'visible'};
    `}
`;

export const LoaderContainer = styled.svg`
    overflow: visible;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;

export const LoaderCircle = styled.circle`
    animation: ${spin} 1s linear infinite;
    ${({ theme }) => `
        fill: none;
        stroke: ${theme.white(1)};
        stroke-width: 1px;
        stroke-dasharray: 5px 5px;
        transform-origin: 50% 50%;
    `}
`;
