import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react';

class ProtectedRoute extends Component {
    render() {
        const { show, redirect, ...restProps } = this.props;

        return show ? <Route {...restProps} /> : <Redirect to={redirect} />;
    }
}

export default observer(ProtectedRoute);
