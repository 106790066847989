import styled, { css } from 'styled-components';

export const liftedLabel = css`
    top: -0.4rem;
    font-size: 0.875rem;
`;

export const inputText = css`
    font-size: 1rem;
    font-weight: 600;
    color: #000;
`;

export const InputContainer = styled.div.attrs({
    className: 'input-container'
})`
    height: 2rem;
    width: 100%;
    position: relative;
    margin-top: 1.5rem;
    display: flex;
    align-items: center;

    ${({ theme, disabled }) => `
        border-bottom: 1px solid ${disabled ? theme.greyD0 : theme.greyA0};
        color: ${theme.grey33};

        ${disabled &&
            `
            * {
                cursor: not-allowed;
                color: ${theme.greyA0} !important;
            }
        `}
    `}
`;

export const InputWrapper = styled.div.attrs({
    className: 'input-wrapper'
})`
    margin-top: 1.5rem;
`;

export const InputStyled = styled.input`
    height: 100%;
    width: 100%;
    border: none;
    background: transparent;
    z-index: 1;
    outline: none;
    ${inputText}

    :focus ~ label div {
        ${liftedLabel}
    }
`;

export const LabelStyled = styled.label`
    ${({ lifted }) => `
        transition: all .2s;
        z-index: 0;
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        bottom: 0;
        right: 0;
        left: 0;
        height: 100%;

        ${lifted && liftedLabel}
    `}
`;

export const LabelContent = styled.div`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
`;

export const ErrorContainer = styled.div.attrs({
    className: 'input-error'
})`
    ${({ theme }) => `
        height: .75rem;
        color: ${theme.danger};
        font-size: .75rem;
    `}
`;
