import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { ModalHeader, ModalBody, ModalFooter } from '../styled/Modal/styles';
import { PartnerOverviewContainer } from './styles';

import Button from '../styled/Button';
import Input from '../styled/Input';

class PartnerOverview extends Component {
    render() {
        const { partner } = this.props;

        return (
            <PartnerOverviewContainer>
                <ModalHeader>{partner.name}</ModalHeader>
                <ModalBody>
                    <Input type="static" label="Nimi" value={partner.name} />
                    <Input type="static" label="Registrikood" value={partner.registryNr} />
                    <Input type="static" label="KMKR nr" value={partner.vatId} />
                    <Input type="static" label="Telefon" value={partner.phoneNr} />
                    <Input type="static" label="E-post" value={partner.email} />
                    <Input type="static" label="Aadress" value={partner.address} />
                    <Input type="static" label="Postikood" value={partner.postalCode} />
                    <Input type="static" label="Maakond" value={partner.county} />
                    <Input type="static" label="Riik" value={partner.country} />
                </ModalBody>
                <ModalFooter borderless>
                    <Button value="Kustuta partner" />
                </ModalFooter>
            </PartnerOverviewContainer>
        );
    }
}

export default inject('store')(observer(PartnerOverview));
