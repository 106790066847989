import React, { Component } from 'react';

import { StaticInputContainer, StaticInputStyled } from './styles';

import Label from '../Label';

class StaticInput extends Component {
    hasValue = () => {
        const { value } = this.props;
        if (typeof value === 'string') {
            return value.length > 0;
        }
        if (value instanceof Object) {
            return Object.keys(value).length > 0;
        }
        return value != null;
    };

    getValue = () => {
        const { value, parseValue } = this.props;
        if (value instanceof Object && parseValue) {
            return parseValue(value).label;
        }
        return value;
    };

    render() {
        const { label, onClick } = this.props;

        return (
            <StaticInputContainer>
                <StaticInputStyled onClick={onClick}>{this.getValue()}</StaticInputStyled>
                <Label value={label} lifted={this.hasValue()} />
            </StaticInputContainer>
        );
    }
}

export default StaticInput;
