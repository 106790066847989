import axios from 'axios';
import cachios from 'cachios';
import constants from '../util/constants';

import { withAuthHeaders } from './index';

export default {
    get: async ({ startDate, endDate, groupBy }) => {
        return axios.get(
            `${constants.API_URL}/statistics`,
            withAuthHeaders({
                params: {
                    startDate,
                    endDate,
                    groupBy
                }
            })
        );
    }
};
