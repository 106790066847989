import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { ModalHeader, ModalBody, ModalFooter } from '../styled/Modal/styles';
import { ErrorContainer } from '../styled/Error';
import { SaleFormModalContainer, ItemsContainer } from './styles';

import Button from '../styled/Button';
import SaleForm from './SaleForm';
import SaleItemForm from './SaleItemForm';
import SaleItemList from './SaleItemList';

class SaleFormModal extends Component {
    render() {
        const { newSaleForm } = this.props.store.sales;

        const { generalErrorMessage } = newSaleForm;
        return (
            <SaleFormModalContainer>
                <ModalHeader>Lisa uus müügiarve</ModalHeader>
                <ModalBody>
                    <SaleForm id="newSale" />
                    <ItemsContainer>
                        <SaleItemForm />
                        <SaleItemList />
                    </ItemsContainer>
                </ModalBody>
                <ModalFooter borderless>
                    {generalErrorMessage && (
                        <ErrorContainer margin=".75rem 0 0 0">{generalErrorMessage}</ErrorContainer>
                    )}
                    <Button form="newSale" value="Lisa arve" isLoading={newSaleForm.isLoading} />
                </ModalFooter>
            </SaleFormModalContainer>
        );
    }
}

export default inject('store')(observer(SaleFormModal));
