import styled from 'styled-components';

import Select from '../styled/Input/Select';
import Button from '../styled/Button';

export const ShoppingCartContainer = styled.div`
    ${({ theme }) => `
        position: relative;
        width: 2rem;
        margin: 0 auto;
        cursor: pointer;

        :before {
            content: '+';
            z-index: 1000;
            position: absolute;
            right: .125rem;
            top: 0;
            height: .75rem;
            font-size: .8rem;
            width: .75rem;
            font-weight: 600;
            text-align: center;
            background: ${theme.color1};
            color: ${theme.white(1)};
            border-radius: 50%;
            line-height: .75rem;
        }
        
        .icon {
            display: block;
            margin-top: .125rem;
        }
    `}
`;

export const SelectStyled = styled(Select)`
    ${({ theme }) => `
        margin: 0 0 0 .75rem;
        border-bottom: none;
        height: 1.5rem;
        width: 10rem;
        box-shadow: inset 0 -1px ${theme.greyF0};

        .react-select__control {
            min-height: 0;
            max-height: 1.5rem;
        }

        .react-select__single-value {
            color: ${theme.grey77};
            font-weight: 400;
        }

        .icon svg {
            fill: ${theme.grey77};
        }

    `}
`;

export const ManageWarehousesButton = styled(Button)`
    ${({ theme }) => `
        &, :focus, :hover {
            background: transparent;
            box-shadow: none;
        }

        :focus, :hover {
            text-decoration: underline;
        }

        margin-right: 1rem;
        font-weight: 700;
        color: ${theme.grey77};
    `}
`;

export const QuantityContainer = styled.span`
    ${({ theme }) => `
        sup {
            color: ${theme.grey77};
            font-size: .65rem;
        }
    `}
`;
