import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { ModalHeader, ModalBody, ModalFooter } from '../styled/Modal/styles';
import { ReceiptFormModalContainer } from './styles';

import Button from '../styled/Button';
import ReceiptForm from './ReceiptForm';

class ReceiptFormModal extends Component {
    render() {
        const { newReceiptForm } = this.props.store.receipts;
        const { sale } = this.props;

        return (
            <ReceiptFormModalContainer>
                <ModalHeader>Lisa uus laekumine</ModalHeader>
                <ModalBody>
                    <ReceiptForm id="newReceipt" sale={sale} />
                </ModalBody>
                <ModalFooter borderless>
                    <Button
                        form="newReceipt"
                        value="Lisa laekumine"
                        isLoading={newReceiptForm.isLoading}
                    />
                </ModalFooter>
            </ReceiptFormModalContainer>
        );
    }
}

export default inject('store')(observer(ReceiptFormModal));
