import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { ModalHeader, ModalBody, ModalFooter } from '../styled/Modal/styles';
import { ExpenseFormContainer } from './styles';

import Button from '../styled/Button';
import ExpenseForm from './ExpenseForm';

class ExpenseFormModal extends Component {
    render() {
        const { purchase, store } = this.props;
        const { newExpenseForm } = store.expenses;

        return (
            <ExpenseFormContainer>
                <ModalHeader>Lisa uus kulu</ModalHeader>
                <ModalBody>
                    <ExpenseForm id="newExpense" purchase={purchase} />
                </ModalBody>
                <ModalFooter borderless>
                    <Button
                        form="newExpense"
                        value="Lisa kulu"
                        isLoading={newExpenseForm.isLoading}
                    />
                </ModalFooter>
            </ExpenseFormContainer>
        );
    }
}

export default inject('store')(observer(ExpenseFormModal));
