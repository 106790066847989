import { observable, decorate, flow, action } from 'mobx';

import history from '../util/history';

import Store from './lib/Store';
import Form from './lib/Form';
import forms from '../util/forms';

class AuthStore extends Store {
    user;

    isVerifying = true;

    loginWithFacebook = flow(function*(accessToken) {
        const { ui } = this.stores;

        try {
            const { data } = yield this.api.auth.loginWithFacebook(accessToken);

            localStorage.setItem('access_token', data.token);
            this.user = data;

            ui.setActiveView(ui.routes.warehouse);
        } catch (err) {
            ui.setActiveView(ui.routes.login);
        }
    });

    loginWithGoogle = flow(function*(accessToken) {
        const { ui } = this.stores;

        try {
            const { data } = yield this.api.auth.loginWithGoogle(accessToken);

            localStorage.setItem('access_token', data.token);
            this.user = data;

            ui.setActiveView(ui.routes.warehouse);
        } catch (err) {
            ui.setActiveView(ui.routes.login);
        }
    });

    logout = () => {
        localStorage.removeItem('access_token');
        window.reload();
    };

    verifyToken = flow(function*() {
        const { ui } = this.stores;

        try {
            const { data } = yield this.api.auth.verifyToken();

            this.user = data;

            ui.setActiveView(history.location.pathname);
        } catch (err) {
            ui.setActiveView(ui.routes.login);
        } finally {
            this.isVerifying = false;
        }
    });

    editProfile = flow(function*(newProfile) {
        try {
            const profile = yield this.api.auth.editProfile(newProfile);

            this.user = { ...this.user, ...profile.data };
        } catch (err) {
            console.error(err);
        }
    });

    editProfileForm = new Form({
        fields: forms.profile,
        onSubmit: this.editProfile
    });
}

export default decorate(AuthStore, {
    isVerifying: observable,
    user: observable,
    editProfileForm: observable,
    editProfile: action.bound,
    loginWithFacebook: action.bound,
    loginWithGoogle: action.bound,
    verifyToken: action.bound
});
