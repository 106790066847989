import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import api from '../../api';

import { ReceiptFormContainer } from './styles';

import Input from '../styled/Input';

class ReceiptForm extends Component {
    state = {
        unpaidSales: []
    };

    async componentDidMount() {
        const { sale, store } = this.props;

        const unpaidSales = await api.sales.search({
            isPaid: false
        });

        this.setState(() => ({ unpaidSales: unpaidSales.data }));

        if (sale) {
            store.receipts.newReceiptForm.setValues({
                sum: sale.unpaidSum,
                sale
            });
        }
    }

    handleSaleSelect = (sale, name) => {
        const { newReceiptForm } = this.props.store.receipts;

        newReceiptForm.handleChange(sale, name);
        newReceiptForm.setValues({
            sum: sale.unpaidSum,
            sale
        });
    };

    render() {
        const { id, store } = this.props;
        const { unpaidSales } = this.state;

        const { newReceiptForm } = store.receipts;

        return (
            <ReceiptFormContainer id={id} onSubmit={newReceiptForm.submit}>
                <Input
                    required
                    type="select"
                    name="sale"
                    options={unpaidSales}
                    label="Müügiarve"
                    parseValue={sale => ({ value: sale, label: sale.invoiceNr })}
                    onChange={this.handleSaleSelect}
                    value={newReceiptForm.values.sale}
                    errors={newReceiptForm.errors.sale}
                />
                <Input
                    type="date"
                    required
                    label="Kuupäev"
                    onChange={newReceiptForm.handleChange}
                    value={newReceiptForm.values.date}
                    errors={newReceiptForm.errors.date}
                    name="date"
                />
                <Input
                    type="number"
                    required
                    label="Summa"
                    onChange={newReceiptForm.handleChange}
                    value={newReceiptForm.values.sum}
                    errors={newReceiptForm.errors.sum}
                    name="sum"
                    min="0"
                    max={newReceiptForm.values.sale ? newReceiptForm.values.sale.sum : ''}
                    step="0.01"
                />
                <Input
                    type="textarea"
                    label="Märkused"
                    onChange={newReceiptForm.handleChange}
                    value={newReceiptForm.values.description}
                    errors={newReceiptForm.errors.description}
                    name="description"
                />
            </ReceiptFormContainer>
        );
    }
}

export default inject('store')(observer(ReceiptForm));
