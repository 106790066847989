import styled from 'styled-components';
import 'react-day-picker/lib/style.css';

import { InputContainer, inputText } from '../styles';

export const DateInputContainer = styled(InputContainer).attrs({
    className: 'date-input__input-container'
})`
    ${({ theme, isFocused }) => `
        display: flex;
        align-items: center;
        z-index: ${isFocused ? 3 : 0};

        .icon { 
            fill: ${theme.greyA0};
            position: absolute;
            right: 0;
        }
    `}
`;

export const DateInputWrapper = styled.div`
    ${({ theme, isFocused }) => `
        height: 100%;
        width: 100%;
        position: relative;
        z-index: ${isFocused ? 3 : 2};

        .DayPickerInput, .DayPickerInput input {
            height: 100%;
            width: 100%;
            border: none;
            background: transparent;
            outline: none;
            cursor: pointer;
            ${inputText}

            ::placeholder {
                color: ${theme.grey33};
            }
        }
    `}
`;
