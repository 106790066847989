import styled from 'styled-components';

import { inputText, InputContainer } from '../styles';

export const AutoSuggestContainer = styled(InputContainer)`
    ${({ disabled, theme }) => `
        ${disabled &&
            `
            border-bottom: 1px solid ${theme.greyD0};
            color: ${theme.greyA0};
        `}
    `}
`;

export const AutoSuggestWrapper = styled.div`
    ${({ isFocused }) => `
        z-index: ${isFocused ? 999 : 2};
    `}

    width: 100%;
    height: 100%;

    .react-autosuggest__container {
        width: 100%;
        height: 100%;
        background: transparent;
    }

    .react-autosuggest__input {
        width: 100%;
        height: 100%;
        outline: none;
        background: transparent;
        border: none;
        ${inputText}
    }

    .react-autosuggest__suggestions-container {
        box-shadow: 4px 6px 10px rgba(0, 0, 0, 0.1);
    }
`;

export const SuggestionItemStyled = styled.div`
    ${({ theme }) => `
        width: 100%;
        background: white;
        padding: 0.6rem;
        font-size: inherit;
        box-sizing: border-box;
        cursor: pointer;

        :hover {
            background: ${theme.color2};
            color: ${theme.white(1)};
        }
    `}
`;
