import styled from 'styled-components';

export const SidebarWrapper = styled.div`
    height: 100%;
    flex-shrink: 0;
    width: 16rem;
    display: flex;
`;

export const SidebarContainer = styled.div`
    ${({ theme }) => `
        flex: 1;
        overflow: auto;
        color: ${theme.white(1)};
        padding: 2rem;
        flex-shrink: 0;
        background: linear-gradient(to bottom right, ${theme.color2}, ${theme.color1});
    `}
`;

export const SidebarHeader = styled.div`
    font-weight: bold;
    text-align: justify;
    font-size: 1.3rem;
    height: 1.3rem;

    :after {
        content: '';
        width: 100%;
        display: inline-block;
    }
`;

export const Divider = styled.div`
    ${({ theme }) => `
        width: 100%;
        height: 1px;
        margin: 1.5rem 0;
        background: ${theme.white(0.1)};
    `}
`;

export const SidebarItemContainer = styled.div`
    ${({ isActive, theme }) => `
        color: ${theme.white(0.9)};
        display: flex;
        align-items: center;
        cursor: pointer;
        margin: 1rem 0;
        text-shadow: 2px 4px 10px ${theme.black(0.1)};

        &, .icon {
            margin-right: .75rem;
            transition: all .2s;
            fill: ${isActive ? theme.white(0.85) : theme.white(0.25)};
        }

        ${
            isActive
                ? `
            box-shadow: inset -2px 0 0 ${theme.white(1)};
        `
                : `
            :hover {
                box-shadow: inset -2px 0 0 ${theme.white(0.25)};
            }
        `
        }
    `}
`;
