import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { FormContainer } from './styles';

import Input from '../styled/Input';

class PartnerForm extends Component {
    render() {
        const { id, store, form } = this.props;

        return (
            <FormContainer id={id} onSubmit={form.submit}>
                <Input
                    required
                    name="name"
                    errors={form.errors.name}
                    onChange={form.handleChange}
                    value={form.values.name}
                    label="Nimi"
                />
                <Input
                    required
                    name="partnerType"
                    type="select"
                    options={store.common.partnerTypes}
                    parseValue={value => ({ value, label: value.name })}
                    errors={form.errors.partnerType}
                    onChange={form.handleChange}
                    value={form.values.partnerType}
                    label="Partneri tüüp"
                />
                <Input
                    name="registryNr"
                    onChange={form.handleChange}
                    value={form.values.registryNr}
                    label="Registrikood"
                />
                <Input
                    name="vatId"
                    onChange={form.handleChange}
                    value={form.values.vatId}
                    label="Käibemaksukohustuslase nr"
                />
                <Input
                    name="phoneNr"
                    onChange={form.handleChange}
                    value={form.values.phoneNr}
                    label="Telefon"
                />
                <Input
                    type="email"
                    name="email"
                    onChange={form.handleChange}
                    value={form.values.email}
                    label="Email"
                />
                <Input
                    name="address"
                    onChange={form.handleChange}
                    value={form.values.address}
                    label="Aadress"
                />
                <Input
                    name="postalCode"
                    onChange={form.handleChange}
                    value={form.values.postalCode}
                    label="Postikood"
                />
                <Input
                    name="county"
                    onChange={form.handleChange}
                    value={form.values.county}
                    label="Maakond"
                />
                <Input
                    name="country"
                    onChange={form.handleChange}
                    value={form.values.country}
                    label="Riik"
                />
            </FormContainer>
        );
    }
}

export default inject('store')(observer(PartnerForm));
