import * as yup from 'yup';

export default {
    new: {
        partner: {
            value: null,
            schema: yup
                .object()
                .typeError('Palun vali tehingupartner.')
                .shape({ id: yup.number() })
                .notEmpty('Palun vali tehingupartner.')
                .required('Palun vali tehingupartner.')
        },
        invoiceNr: {
            value: '',
            schema: yup
                .string()
                .trim()
                .required('Palun sisesta arve number.')
        },
        dueDate: {
            value: new Date(),
            schema: yup.date().required('Palun sisesta maksetähtaeg.')
        },
        saleDate: {
            value: new Date(),
            schema: yup.date().required('Palun sisesta müügikuupäev.')
        },
        items: {
            value: [],
            schema: yup.array().required()
        },
        description: {
            value: '',
            schema: yup.string()
        }
    },
    goods: {
        id: {
            value: null,
            schema: yup.number().nullable()
        },
        itemType: {
            value: {
                id: 1,
                code: 'INVENTORY'
            }
        },
        name: {
            value: '',
            schema: yup
                .string()
                .trim()
                .required('Palun sisesta kauba nimetus.')
        },
        code: {
            value: '',
            schema: yup
                .string()
                .trim()
                .required('Palun sisesta kauba kood.')
        },
        quantity: {
            value: '',
            schema: yup
                .number()
                .typeError('Palun sisesta kauba kogus.')
                .min(0, 'Kogus peab olema 0 või suurem.')
                .required()
        },
        unit: {
            value: null,
            schema: yup
                .object()
                .typeError('Palun vali kauba ühik.')
                .shape({ id: yup.number() })
                .notEmpty('Palun vali kauba ühik.')
                .required('Palun vali kauba ühik.')
        },
        warehouse: {
            value: null,
            schema: yup
                .object()
                .typeError('Palun vali kauba ladu.')
                .shape({ id: yup.number() })
                .nullable()
        },
        retailPrice: {
            value: '',
            schema: yup
                .number()
                .typeError('Palun sisesta kauba müügihind.')
                .min(0, 'Hind peab olema 0 või suurem.')
                .required()
        }
    },
    service: {
        id: {
            value: null,
            schema: yup.number().nullable()
        },
        itemType: {
            value: {
                id: 2,
                code: 'SERVICE'
            }
        },
        name: {
            value: '',
            schema: yup
                .string()
                .trim()
                .required('Palun sisesta teenuse nimetus.')
        },
        quantity: {
            value: '',
            schema: yup
                .number()
                .typeError('Palun sisesta teenuse kogus.')
                .min(0, 'Kogus peab olema 0 või suurem.')
                .required()
        },
        unit: {
            value: null,
            schema: yup
                .object()
                .shape({ id: yup.number() })
                .nullable()
        },
        retailPrice: {
            value: '',
            schema: yup
                .number()
                .typeError('Palun sisesta teenuse müügihind.')
                .min(0, 'Hind peab olema 0 või suurem.')
                .required()
        }
    },
    expense: {
        id: {
            value: null,
            schema: yup.number().nullable()
        },
        itemType: {
            value: {
                id: 3,
                code: 'EXPENSE'
            }
        },
        name: {
            value: '',
            schema: yup
                .string()
                .trim()
                .required('Palun sisesta kuluartikli nimetus.')
        },
        quantity: {
            value: '',
            schema: yup
                .number()
                .typeError('Palun sisesta kuluartikli kogus.')
                .min(0, 'Kogus peab olema 0 või suurem.')
                .required()
        },
        unit: {
            value: null,
            schema: yup
                .object()
                .shape({ id: yup.number() })
                .nullable()
        },
        retailPrice: {
            value: '',
            schema: yup
                .number()
                .typeError('Palun sisesta kuluartikli müügihind.')
                .min(0, 'Hind peab olema 0 või suurem.')
                .required()
        }
    }
};
