import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { Page, pdfjs } from 'react-pdf';

import { ModalHeader, ModalBody, ModalFooter } from '../styled/Modal/styles';
import { DocumentStyled } from './styles';

import Button from '../styled/Button';

pdfjs.GlobalWorkerOptions.workerSrc = `
    //cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js
`;

class PDFView extends Component {
    downloadPDF = () => {
        const { filename, file } = this.props;

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(file);
            return;
        }

        const data = window.URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = data;
        link.download = filename;
        link.click();
    };

    render() {
        const { file } = this.props;

        return (
            <div>
                <ModalHeader>Lae arve alla</ModalHeader>
                <ModalBody>
                    <DocumentStyled file={file} onLoadSuccess={this.onLoadSuccess}>
                        <Page pageNumber={1} />
                    </DocumentStyled>
                </ModalBody>
                <ModalFooter>
                    <Button value="Lae alla" onClick={this.downloadPDF} />
                </ModalFooter>
            </div>
        );
    }
}

export default withRouter(inject('store')(observer(PDFView)));
