import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { parseCurrency, parseDate, compare } from '../../util/functions';

import {
    MainViewContainer,
    MainViewHeader,
    HeaderText,
    SearchInputContainer,
    SearchInput,
    MainViewFooter
} from '../styled/MainView';

import { IsPaidStyled } from '../PurchaseTable/styles';

import Icon from '../styled/Icon';
import Table from '../styled/Table';
import Button from '../styled/Button';
import Tooltip from '../styled/Tooltip';
import Confirmation from '../styled/Confirmation';

import SaleForm from '../SaleForm';
import SaleOverview from '../SaleOverview';
import ReceiptForm from '../ReceiptForm';

class Sales extends Component {
    state = {
        searchQuery: '',
        searchResults: []
    };

    componentDidMount() {
        const { ui, sales } = this.props.store;
        sales.fetchAllSales();
        ui.setActiveView(ui.routes.sales);
    }

    searchSales = async searchQuery => {
        const { app } = this.props.store;

        const sales = await app.api.sales.search({
            invoiceNr: searchQuery,
            partnerName: searchQuery
        });

        this.setState(() => ({ searchResults: sales.data, searchQuery }));
    };

    render() {
        const { store } = this.props;
        const { searchQuery, searchResults } = this.state;

        const DeleteConfirmation = ({ row }) => (
            <Confirmation
                message="Kas oled kindel, et soovid müügiarve jäädavalt kustutada?"
                description="Arve, sellega seotud laekumised ja tulud kustutatakse ja arves olevad kaubad pannakse lattu tagasi."
                onConfirm={() => store.sales.deleteSale(row.id)}
            />
        );

        const columns = [
            {
                width: '2rem',
                value: row => (
                    <Icon
                        onClick={() => store.ui.showModal(<SaleOverview sale={row} />)}
                        tooltip="Vaata arvet"
                        type="eye"
                        size="1.25rem"
                    />
                ),
                header: ''
            },
            { width: '10rem', value: row => row.invoiceNr, header: 'Arve nr', attr: 'invoiceNr' },
            {
                width: '',
                value: row => row.partner.name,
                header: 'Klient',
                bold: true,
                attr: 'partner.name'
            },
            {
                width: '7.5rem',
                value: row => parseDate(row.saleDate),
                header: 'Müügikuupäev',
                omitOnBreakpoint: true,
                attr: 'saleDate'
            },
            {
                width: '7.5rem',
                value: row => parseDate(row.dueDate),
                header: 'Maksetähtaeg',
                attr: 'dueDate'
            },
            { width: '6rem', value: row => parseCurrency(row.sum), header: 'Summa', attr: 'sum' },
            {
                width: '6rem',
                value: row => parseCurrency(row.paidSum),
                header: 'Makstud',
                omitOnBreakpoint: true,
                attr: 'paidSum'
            },
            {
                width: '6rem',
                value: row => (
                    <IsPaidStyled
                        onClick={() =>
                            !row.isPaid && store.ui.showModal(<ReceiptForm sale={row} />)
                        }
                        isPaid={row.isPaid}
                    >
                        <Tooltip disabled={row.isPaid} content="Maksa arve">
                            {parseCurrency(row.unpaidSum)}
                        </Tooltip>
                    </IsPaidStyled>
                ),
                header: 'Maksmata',
                attr: 'isPaid'
            },
            {
                width: '2rem',
                value: row => (
                    <Icon
                        onClick={() => store.ui.showModal(<DeleteConfirmation row={row} />)}
                        tooltip="Kustuta arve"
                        type="trashcan"
                        size="1.25rem"
                    />
                ),
                header: ''
            }
        ];

        return (
            <MainViewContainer>
                <MainViewHeader>
                    <HeaderText>Müügiarved</HeaderText>
                    <SearchInputContainer>
                        <SearchInput
                            placeholder="Otsi arvet"
                            type="text"
                            value={searchQuery}
                            onChange={query => this.searchSales(query)}
                        />
                        <Icon type="search" size="1.5rem" />
                    </SearchInputContainer>
                </MainViewHeader>
                <Table
                    columns={columns}
                    data={searchQuery ? searchResults : store.sales.sales}
                    sortFunction={(lhs, rhs) => compare(lhs, rhs, 'saleDate', -1)}
                />
                <MainViewFooter>
                    <Button value="+ Uus arve" onClick={() => store.ui.showModal(<SaleForm />)} />
                </MainViewFooter>
            </MainViewContainer>
        );
    }
}

export default inject('store')(observer(Sales));
