import styled from 'styled-components';

export const BankAccountItem = styled.div`
    ${({ theme }) => `
        display: flex;
        padding: .5rem 1rem;

        :nth-child(even) {
            background: ${theme.greyFA};
        }

        .account-number {
            margin-left: auto;
            margin-right: 0.5rem;
            font-weight: 600;
        }

        .icon {
            cursor: pointer;
            fill: ${theme.grey77};

            :hover {
                fill: ${theme.greyA0};
            }
        }
    `}
`;
