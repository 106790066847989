import React from 'react';
import { observable, action, decorate, flow } from 'mobx';

import forms from '../util/forms';
import currency from '../util/currency';

import Store from './lib/Store';
import Form from './lib/Form';

import PDFView from '../components/PDFView';

class SalesStore extends Store {
    sales = [];

    addItemToInvoice = newItem => {
        const item = Object.assign({}, newItem);

        this.newSaleForm.values.items.push(item);

        switch (item.itemType.code) {
            case 'INVENTORY':
                this.goodsForm.reset();
                break;
            case 'SERVICE':
                this.serviceForm.reset();
                break;
            case 'EXPENSE':
                this.expenseForm.reset();
                break;
            default:
                break;
        }
    };

    removeItemFromInvoice = index => {
        this.newSaleForm.values.items.splice(index, 1);
    };

    fetchAllSales = flow(function*() {
        try {
            const sales = yield this.api.sales.getAll();

            if (sales.request) this.sales = sales.data;
        } catch (err) {
            console.error(err);
        }
    });

    addSale = flow(function*(newSale) {
        try {
            const sale = yield this.api.sales.add(newSale);

            newSale = {
                ...newSale,
                ...sale.data
            };

            console.log(newSale);

            this.sales.push(newSale);

            newSale.items.forEach(item => {
                if (item.warehouse) {
                    const warehouseItem = this.stores.warehouse.items.find(
                        i => i.warehouse && i.warehouse.id === item.warehouse.id && i.id === item.id
                    );

                    if (warehouseItem) {
                        warehouseItem.quantity =
                            Number(warehouseItem.quantity) - Number(item.quantity);
                    } else {
                        this.stores.warehouse.items.push(item);
                    }
                }
            });

            const pdf = yield this.downloadSale(newSale.id);

            this.stores.ui.closeModal();

            this.stores.ui.showModal(
                <PDFView file={pdf} filename={`Arve ${newSale.invoiceNr}.pdf`} />
            );

            this.newSaleForm.reset();
            this.serviceForm.reset();
            this.goodsForm.reset();
        } catch (err) {
            this.newSaleForm.setGeneralErrorMessage(err.response.data.message);
        }
    });

    fetchItems = flow(function*(saleId) {
        try {
            const items = yield this.api.sales.getItems(saleId);

            const sale = this.sales.find(s => s.id === saleId);
            sale.items = items.data;
        } catch (err) {
            console.error(err);
        }
    });

    downloadSale = flow(function*(saleId, opts = {}) {
        try {
            const sale = this.sales.find(s => s.id === saleId);

            if (!sale.pdf) {
                const file = yield this.api.sales.downloadSale(saleId);
                sale.pdf = new Blob([file.data], { type: 'application/pdf' });
            }

            if (opts.preview) {
                this.stores.ui.showModal(
                    <PDFView file={sale.pdf} filename={`Arve ${sale.invoiceNr}.pdf`} />
                );
            }

            return sale.pdf;
        } catch (err) {
            console.error(err);
            return null;
        }
    });

    deleteSale = flow(function*(saleId) {
        try {
            const deletedSale = yield this.api.sales.delete(saleId);

            deletedSale.data.items.forEach(item => {
                const warehouseItem = this.stores.warehouse.items.find(i => i.id === item.itemId);
                warehouseItem.quantity += item.quantity;
            });

            this.stores.receipts.receipts = this.stores.receipts.receipts.filter(
                receipt => receipt.sale.id !== saleId
            );

            const index = this.sales.findIndex(sale => sale.id === saleId);
            if (index != null) this.sales.splice(index, 1);
            return true;
        } catch (err) {
            throw err;
        }
    });

    newSaleForm = new Form({
        fields: forms.sales.new,
        onSubmit: this.addSale
    });

    goodsForm = new Form({
        fields: forms.sales.goods,
        onSubmit: this.addItemToInvoice
    });

    serviceForm = new Form({
        fields: forms.sales.service,
        onSubmit: this.addItemToInvoice
    });

    expenseForm = new Form({
        fields: forms.sales.expense,
        onSubmit: this.addItemToInvoice
    });
}

export default decorate(SalesStore, {
    sales: observable,
    fetchAllSales: action.bound,
    addSale: action.bound,
    deleteSale: action.bound,
    addItemToInvoice: action,
    removeItemFromInvoice: action,
    fetchItems: action.bound,
    newSaleForm: observable,
    goodsForm: observable,
    serviceForm: observable,
    expenseForm: observable
});
