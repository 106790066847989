import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { FormContainer } from './styles';

import Input from '../styled/Input';

class UnitFormForm extends Component {
    render() {
        const { id, store } = this.props;

        const { newUnitForm } = store.common;

        return (
            <FormContainer id={id} onSubmit={newUnitForm.submit}>
                <Input
                    required
                    name="name"
                    onChange={newUnitForm.handleChange}
                    value={newUnitForm.values.name}
                    errors={newUnitForm.errors.name}
                    label="Nimi"
                />
                <Input
                    required
                    name="abbr"
                    onChange={newUnitForm.handleChange}
                    value={newUnitForm.values.abbr}
                    label="Lühend"
                    errors={newUnitForm.errors.abbr}
                />
            </FormContainer>
        );
    }
}

export default inject('store')(observer(UnitFormForm));
