import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { ModalHeader, ModalBody, ModalFooter } from '../styled/Modal/styles';
import { ReceiptOverviewContainer } from './styles';

import Input from '../styled/Input';
import { parseCurrency } from '../../util/functions';

class ReceiptOverview extends Component {
    render() {
        const { receipt } = this.props;

        const sum = parseCurrency(receipt.sum);

        return (
            <ReceiptOverviewContainer>
                <ModalHeader>Arve {receipt.sale.invoiceNr} laekumine</ModalHeader>
                <ModalBody>
                    <Input type="static" label="Müügiarve nr" value={receipt.sale.invoiceNr} />
                    <Input type="static" label="Klient" value={receipt.sale.partner.name} />
                    <Input type="static" label="Summa" value={sum} />
                    <Input type="static" label="Märkused" value={receipt.description} />
                </ModalBody>
                <ModalFooter borderless />
            </ReceiptOverviewContainer>
        );
    }
}

export default inject('store')(observer(ReceiptOverview));
