import React, { Component } from 'react';

import { AddButtonStyled } from './styles';

import Tooltip from '../Tooltip';

class AddButton extends Component {
    render() {
        const { tooltip, ...restProps } = this.props;

        return tooltip ? (
            <Tooltip content={tooltip}>
                <AddButtonStyled {...restProps} />
            </Tooltip>
        ) : (
            <AddButtonStyled {...restProps} />
        );
    }
}

export default AddButton;
