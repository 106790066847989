import styled from 'styled-components';

import Button from '../styled/Button';

export const SaleOverviewContainer = styled.div`
    width: 45rem;
`;

export const DeleteButton = styled(Button)`
    ${({ theme }) => `
        background: ${theme.danger};
    `}
`;

export const ItemsContainer = styled.div`
    ${({ theme }) => `
        margin-top: 1rem;
        height: 20rem;
        overflow: auto;
        border: 1px dashed ${theme.greyF0};
    `}
`;

export const ItemContainer = styled.div`
    ${({ theme }) => `
        display: flex;
        align-items: center;
        padding: .75rem;

        :nth-child(odd) {
            background: ${theme.greyF5};
        }

        .col-1 {
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-height: 2.5rem;
        }

        .col-1__row-1 {
            display: flex;

            .name {
                color: ${theme.black(1)};
                font-weight: 600;
            }

            .quantity {
                margin-left: .5rem;
                color: ${theme.grey33};
            }
        }

        .col-1__row-2 .code {
            color: ${theme.grey77};
            font-weight: 600;
            font-size: .8rem;
        }

        .col-2 {
            margin-left: auto;

            .overall-price {
                color: ${theme.color1};
                font-weight: 600;
            }
        }
    `}
`;

export const SumContainer = styled.div`
    ${({ theme }) => `
        border: dashed ${theme.greyF0};
        border-width: 0 1px 1px 1px;
        padding: .75rem;
        font-weight: 600;
        display: flex;
        justify-content: flex-end;

        .sum {
            margin-left: .25rem;
            color: ${theme.color1};
        }
    `}
`;
