import * as yup from 'yup';

const newItem = {
    code: {
        value: '',
        schema: yup
            .string()
            .trim()
            .required('Palun sisesta kauba kood.')
    },
    name: {
        value: '',
        schema: yup
            .string()
            .trim()
            .required('Palun sisesta kauba nimetus.')
    },
    partner: {
        value: null,
        schema: yup
            .object()
            .shape({
                id: yup.number()
            })
            .nullable()
    },
    description: {
        value: '',
        schema: yup.string().nullable()
    },
    warehouse: {
        value: null,
        schema: yup
            .object()
            .typeError('Palun vali kauba ladu.')
            .shape({ id: yup.number() })
            .notEmpty('Palun vali kauba ladu.')
            .required()
    },
    purchasePrice: {
        value: '',
        schema: yup
            .number()
            .min(0, 'Hind peab olema 0 või suurem.')
            .typeError('Hind peab olema number.')
            .nullable()
            .transform((cv, ov) => {
                return ov === '' ? null : cv;
            })
    },
    retailPrice: {
        value: '',
        schema: yup
            .number()
            .min(0, 'Hind peab olema 0 või suurem.')
            .typeError('Hind peab olema number.')
            .nullable()
            .transform((cv, ov) => {
                return ov === '' ? null : cv;
            })
    },
    quantity: {
        value: '',
        schema: yup
            .number()
            .typeError('Kogus peab olema number.')
            .min(0, 'Kogus peab olema 0 või suurem.')
            .required('Palun sisesta kauba kogus.')
    },
    unit: {
        value: null,
        schema: yup
            .object()
            .typeError('Palun vali kauba ühik.')
            .shape({ id: yup.number() })
            .notEmpty('Palun vali kauba ühik.')
            .required()
    },
    itemType: {
        value: {
            id: 1
        }
    }
};

const editItem = {
    ...newItem,
    id: {
        value: null,
        schema: yup.number().required()
    }
};

export default {
    new: newItem,
    edit: editItem
};
