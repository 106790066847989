import styled from 'styled-components';

export const TableWrapperWrapper = styled.div`
    ${({ height }) => `
        height: ${height || 'auto'};
        overflow: auto;
        display: flex;
        flex-direction: column;
        flex: 1;
    `}
`;

export const TableWrapper = styled.div`
    ${({ height, theme }) => `
        height: ${height || '100%'};
        overflow: auto;
        border: 1px dashed ${theme.greyF0};
        display: flex;
    `}
`;

export const TableContainer = styled.div`
    display: flex;
    flex: 1;
    flex-flow: column;
    position: relative;
`;

export const TableHeader = styled.div`
    ${({ theme }) => `
        display: flex;
        font-weight: 400;
        background: ${theme.greyFA};
        text-transform: uppercase;
        font-size: .85rem;
        min-height: 2rem;
    `}
`;

export const TableRow = styled.div`
    ${({ theme }) => `
        display: flex;
        min-height: 2.5rem;

        &.row-odd {
            background: ${theme.greyFA};
        }

        :not(:last-child) {
            border-bottom: 1px dashed ${theme.greyF5};
        }
    `}
`;

export const TableCell = styled.div`
    ${({ width, padding, bold, theme, flex, omitOnBreakpoint, onClick }) => `
        padding: ${padding || '.25rem 0'};
        display: flex;
        align-items: center;
        justify-content: center;

        :not(:last-child) {
            border-right: 1px dashed ${theme.greyF0};
        }
        
        .icon {
            cursor: pointer;
            fill: ${theme.greyA0};
        }
        
        ${
            onClick
                ? `
            &.header-cell {
                text-decoration: underline;
                cursor: pointer;
            }
        `
                : ''
        }
        

        ${
            width
                ? `
            width: ${width};
                `
                : `
                flex: ${flex || 1};
            `
        }
        ${bold ? 'font-weight: 600;' : ''}

        @media only screen and (max-width: ${theme.breakpoint}) {
            display: ${omitOnBreakpoint ? 'none' : 'auto'};
        }
    `}
`;
